import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { useDispatch, useSelector } from 'react-redux';
import CmtList from '../../../../@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import PeopleIcon from '@material-ui/icons/People';
import EuroIcon from '@material-ui/icons/Euro';
import MapIcon from '@material-ui/icons/LocationOn';
import CartaIcon from '@material-ui/icons/Payment';
import DescIcon from '@material-ui/icons/Description';
import useStyles from './index.style';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import { Button, Tooltip } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import Mappa from './mappa';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { deleteCustomer, getCustomers, setCurrentCustomer, getCustomerPartite } from '../../../../redux/actions/Customers';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TodayIcon from '@material-ui/icons/Today';

const CustomerDetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const { currentCustomer } = useSelector(({ customersReducer }) => customersReducer);
  const { currentPartite } = useSelector(({ customersReducer }) => customersReducer);
  const [fullWidth, setFullWidth] = React.useState(false);
  const [maxWidth, setMaxWidth] = React.useState('lg');
  const [annoInizio, setAnnoInizio] = React.useState(0);
  const [annoFine, setAnnoFine] = React.useState(0);
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const [cercato, setCercato] = React.useState(false);
  const [partite, setPartite] = useState([]);
  const dispatch = useDispatch();

  const {
    id,
    ragsoc,
    numpar,
    annpar,
    aperto,
    fatturato,
    email,
    status,
    phones,
    company,
    designation,
    profile_pic,
    starred,
    referente,
    ultimaFattura,
    dtUltimaVendita,
    partiva,
    codfisc,
    indirizzo,
    local,
    prov,
    cap,
    dataIns,
    dataUltMod,
    fatEle,
    codAge,
    pec,
    persoc,
    indicePA,
  } = currentCustomer;

  const posizione = aperto;

  const handleChangeInizio = event => {
    setAnnoInizio(event.target.value);
  };

  const handleChangeFine = event => {
    setAnnoFine(event.target.value);
  };

  useEffect(() => {
    console.log({ currentCustomer });
  }, [currentCustomer]);

  useEffect(() => {
    console.log({ currentPartite });
  }, [currentPartite]);

  useEffect(() => {
    if (id) {
      let json = fetch('https://laforgiaws.netkom.it/ws/getSaldi.php?codcf=' + id, {
        method: 'get',
      })
        .then(response => response.json())
        .then(risp => {
          console.log('ecco la risposta', risp);
          if (risp.response) {
            setPartite(risp.partite);
          }
        });
    }
  }, []);

  return (
    <Dialog maxWidth={maxWidth} fullScreen open={open} onClose={onCloseDialog} className={classes.dialogPaper + ' copClass'}>
      <Grid container justify="flex-end">
        <IconButton onClick={onCloseDialog}>
          <ClearIcon />
        </IconButton>
      </Grid>
      <Box className={classes.customerInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box className={classes.avatarView} mr={{ xs: 4, md: 6 }}>
            <CmtAvatar size={70} src={profile_pic} alt={ragsoc} />
          </Box>
          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot}>{ragsoc}</Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot}>Fatturato: {fatturato}</Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot}>Posizione aperta: {posizione ? posizione : 'NO'}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box style={{ margin: 10 }}>
        <h2>Partite aperte</h2>
        {partite.length > 0 && (
          <TableContainer component={Paper}>
            <Table className="tabella" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Registrazione</TableCell>
                  <TableCell align="left">Scadenza</TableCell>
                  <TableCell align="left">Anno/Numero</TableCell>
                  <TableCell align="left">Descrizione</TableCell>
                  <TableCell align="left">Aperto</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {partite.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell align="left">{moment(row.data_reg).format('DD/MM/YYYY')}</TableCell>
                    <TableCell align="left">{moment(row.data_sca).format('DD/MM/YYYY')}</TableCell>
                    <TableCell align="left">
                      {row.ANNPAR}/{row.NUMPAR}
                    </TableCell>
                    <TableCell align="left">{row.descr}</TableCell>
                    <TableCell align="left">{row.aperto}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      <br />
      <Box style={{ margin: 10 }}>
        <h2>Ricerca partite</h2>
        <Grid p={{ pt: 10 }} ml={{ pt: 20 }} container>
          <Grid item xs={3} className="contIns">
            <div className={'contenutoIns  f-data'}>
              <div className={'labelIns'}>Anno inizio</div>
              <DatePicker
                showYearPicker
                dateFormat={'yyyy'}
                dateFormat={'dd/MM/yyyy'}
                selected={startDate}
                onChange={date => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={'contenutoIns  f-data'}>
              <div className={'labelIns'}>Anno fine</div>
              <DatePicker
                showYearPicker
                dateFormat={'yyyy'}
                dateFormat={'dd/MM/yyyy'}
                selected={endDate}
                onChange={date => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              onClick={() => {
                setCercato(true);
                let sd = moment(startDate).format('YYYY-MM-DD');
                let ed = moment(endDate).format('YYYY-MM-DD');
                dispatch(getCustomerPartite(currentCustomer, sd, ed));
              }}>
              CERCA
            </Button>
          </Grid>
          <br />
          <br />
        </Grid>
      </Box>

      <Grid container className="cont-TabellaLAV">
        {typeof currentPartite != 'undefined' && currentPartite.length > 0 && (
          <TableContainer component={Paper}>
            <Table className="tabellaLavorazioni" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Anno</TableCell>
                  <TableCell>Numero</TableCell>
                  <TableCell>Rata</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Pag</TableCell>
                  <TableCell>Scadenza</TableCell>
                  <TableCell>Movimento</TableCell>
                  <TableCell>Dare</TableCell>
                  <TableCell>Avere</TableCell>
                  <TableCell>Saldo</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {typeof currentPartite != 'undefined' &&
                  currentPartite.map((riga, index) => {
                    const id = index;
                    return (
                      <TableRow key={id}>
                        <TableCell>{riga.annpar}</TableCell>
                        <TableCell>{riga.numpar}</TableCell>
                        <TableCell>{riga.prgpar}</TableCell>
                        <TableCell>{riga.datpar}</TableCell>
                        <TableCell>{riga.codpag}</TableCell>
                        <TableCell>{riga.datsca}</TableCell>
                        <TableCell>{riga.descr}</TableCell>
                        <TableCell>{riga.impdare}</TableCell>
                        <TableCell>{riga.impavere}</TableCell>
                        <TableCell>{riga.saldo}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {typeof currentPartite != 'undefined' && currentPartite.length == 0 && cercato && <h3>Nessuna partita trovata</h3>}
      </Grid>
    </Dialog>
  );
};

export default CustomerDetailView;

CustomerDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
