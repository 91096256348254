import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SamplePage from './Pages/SamplePage';
import Calendario from './Pages/Calendario';
import PartiteAperte from './Pages/Partite';
//prodotti
import Offerte from './Pages/Offerte';
import Offerta from './Pages/Offerta';
import Ordini from './Pages/Ordini';
import OrdiniFornitori from './Pages/OrdiniFornitori';
import OrdineFornitore from './Pages/OrdineFornitore';
import Ordine from './Pages/Ordine';
//interventi
import OfferteInt from './Pages/OfferteInt';
import OffertaInt from './Pages/OffertaInt';
import OrdiniInt from './Pages/OrdiniInt';
import OrdiniFornitoriInt from './Pages/OrdiniFornitoriInt';
import OrdineFornitoreInt from './Pages/OrdineFornitoreInt';
import OrdineInt from './Pages/OrdineInt';
import IstitutiVigilanza from './Pages/IstitutiVigilanza';
//parti in comune
import Users from './modules/Users';
import Fornitori from './modules/Fornitori';
import Clienti from './modules/Customers';
import Apps from './Apps';
import Ricerca from './modules/Ricerca';
import Dashboards from './Dashboards';
import Error404 from './Pages/404';
import Login from './Auth/Login';
import Register from './Auth/Register';
import ForgotPasswordPage from './Auth/ForgotPassword';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  // eslint-disable-next-line no-console
  //console.log({ authUser });
  const location = useLocation();

  if (location.pathname === '' || location.pathname === '/') {
    return <Redirect to={'/dashboard/crm'} />;
  } else if (authUser && location.pathname === '/signin') {
    return <Redirect to={'/dashboard/crm'} />;
  }

  return (
    <React.Fragment>
      <Switch>
        <RestrictedRoute path="/dashboard" component={Dashboards} />
        <RestrictedRoute path="/sample-page" component={SamplePage} />
        <RestrictedRoute path="/calendario" component={Calendario} />
        <RestrictedRoute path="/partite-aperte" component={PartiteAperte} />
        <RestrictedRoute path="/offerte" component={Offerte} />
        <RestrictedRoute path="/ordini" component={Ordini} />
        <RestrictedRoute path="/ordini-fornitori" component={OrdiniFornitori} />
        <RestrictedRoute path="/offerte-interventi" component={OfferteInt} />
        <RestrictedRoute path="/ordini-interventi" component={OrdiniInt} />
        <RestrictedRoute path="/ordini-fornitori-interventi" component={OrdiniFornitoriInt} />
        <RestrictedRoute path="/istituti-di-vigilanza" component={IstitutiVigilanza} />
        <RestrictedRoute path="/customers" component={Clienti} />
        <RestrictedRoute path="/users" component={Users} />
        <RestrictedRoute path="/fornitori" component={Fornitori} />
        <RestrictedRoute path="/ricerca-cliente" component={Ricerca} />
        <RestrictedRoute path="/apps" component={Apps} />
        <Route exact path={'/offerta/:oid'} component={Offerta} />
        <Route exact path={'/ordine/:oid'} component={Ordine} />
        <Route exact path={'/ordine-fornitore/:oid'} component={OrdineFornitore} />
        <Route exact path={'/offerta-interventi/:oid'} component={OffertaInt} />
        <Route exact path={'/ordine-interventi/:oid'} component={OrdineInt} />
        <Route exact path={'/ordine-fornitore-interventi/:oid'} component={OrdineFornitoreInt} />

        <Route path="/signin" component={Login} />
        <Route path="/signup" component={Register} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <Route component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
