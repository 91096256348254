import React from 'react';
import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/it';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { forEach } from 'lodash';
import { useHistory } from 'react-router-dom';

const breadcrumbs = [
  { label: <IntlMessages id={'sidebar.main'} />, link: '/' },
  { label: <IntlMessages id={'pages.samplePage'} />, isActive: true },
];

const localizer = momentLocalizer(moment);

const EventComponent = evento => {
  console.log('EventComp', evento);
  return (
    <React.Fragment>
      <h4>{evento.title}</h4>
      <p>{evento.event.tipo === '0' ? 'Intervento' : 'Ordine'}</p>
      <p>{evento.event.description}</p>
    </React.Fragment>
  );
};

const eventStyleGetter = (event, start, end, isSelected) => {
  console.log(event);
  var backgroundColor = '#' + event.hexColor;
  var style = {
    backgroundColor: backgroundColor,
    borderRadius: '0px',
    opacity: 0.8,
    color: 'black',
    border: '1px',
    display: 'block',
  };
  return {
    style: style,
  };
};

const EventAgenda = ({ event }) => {
  //console.log('EventAgena', event);
  return (
    <span>
      <em style={{ color: 'magenta' }}>{event.title}</em>
      <p>{event.desc}</p>
    </span>
  );
};

const Calendario = props => {
  const [events, setEvents] = React.useState([
    {
      title: 'My event',
      allDay: false,
      start: new Date(2022, 4, 10, 10, 0), // 10.00 AM
      end: new Date(2022, 4, 10, 14, 0), // 2.00 PM
    },
  ]);
  const [selectedEvent, setSelectedEvent] = React.useState(null);
  const history = useHistory();
  const handleSelected = event => {
    console.info('[handleSelected - event]', event);
    setSelectedEvent(event);
    if (event.tipo === '0') history.push('/offerta-interventi/' + event.oid);
    else history.push('/ordine-interventi/' + event.oid);
  };

  React.useEffect(() => {
    let json = fetch('https://laforgiaws.netkom.it/ws/getEvents.php', {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(risp => {
        if (risp.success) {
          const events = risp.eventi;
          console.log('ecco gli eventi', events);
          //set the javascript date of each event
          forEach(events, event => {
            event.start = new Date(event.start);
            event.end = new Date(event.end);
          });
          //console.log('ecco gli eventi dopo new date', events);
          setEvents(events);
        }
      });
  }, []);

  return (
    <PageContainer heading={<IntlMessages id="pages.samplePage" />} breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <Calendar
            onSelectEvent={handleSelected}
            localizer={localizer}
            events={events}
            culture="it-IT"
            startAccessor="start"
            endAccessor="end"
            style={{ height: 700 }}
            messages={{
              today: <h2>Oggi</h2>,
              previous: <ArrowBackIcon />,
              next: <ArrowForwardIcon />,
            }}
            components={{
              event: EventComponent,
              agenda: {
                event: EventAgenda,
              },
            }}
            eventPropGetter={eventStyleGetter}
          />
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default Calendario;
