import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';

const generaPdf = (state, currentUser) => {
  var doc = new jsPDF('p', 'mm', 'a4', true);
  var totalPagesExp = '{total_pages_count_string}';
  var img = new Image();
  var width = doc.internal.pageSize.getWidth();
  const docHeight = doc.internal.pageSize.height;
  //   const docWidth = doc.internal.pageSize.width;
  const totalPages = doc.internal.pages.length;
  img.src = '/images/logo_lf.png';
  doc.addImage(img, 'png', 5, 10, (width / 2) * 0.65, (width / 8) * 0.6);

  img.src = '/images/logo_bf.png';
  doc.addImage(img, 'png', (width / 10) * 4, 10, (width / 2) * 0.3, (width / 7) * 0.3);

  img.src = '/images/logo_gu.png';
  doc.addImage(img, 'png', (width / 10) * 6, 10, (width / 2) * 0.3, (width / 7) * 0.3);

  img.src = '/images/logo_fe.png';
  doc.addImage(img, 'png', (width / 10) * 8, 10, (width / 2) * 0.3, (width / 7) * 0.3);

  doc.setFont('helvetica');

  var indirizzo_consegna = prompt("Inserire l'indirizzo di consegna");
  var note = prompt('Inserire eventuali note al fornitore');
  var condizioni = prompt('Inserire eventuali condizioni di acquisto');
  var consegna = prompt('Inserire eventuali tempi di consegna');
  var pagamento = prompt('Inserire eventuali condizioni di pagamento');

  doc.setFontSize(9);
  doc
    .setFont(undefined, 'bold')
    .text('Soluzioni Integrate Arredi e Sicurezza', 5, 30)
    .setFont(undefined, 'normal');

  img.src = '/images/marker_icon.png';
  doc.addImage(img, 'png', 5, 32, 3, 3);
  doc.setFontSize(8);
  doc.text('Roma-Milano-Napoli', 9, 35);

  doc.text('Sede Legale: Via Petronio Arbitro, 4 - 00136 Roma', 5, 40);
  doc.text('Part. I.V.A. 09307651001 - REA 1155405', 5, 45);
  doc.text('Capitale interamente versato € 80.000,00', 5, 50);
  doc.text('Tel. 06.39637555-6 - Tel. 06.39730219 - Tel/Fax 06.39670207', 5, 55);
  img.src = '/images/email_icon2.png';
  doc.addImage(img, 'png', 5, 57, 3, 3);
  doc.text('info@laforgia.eu – laforgia@pec.laforgia.eu', 9, 60);
  img.src = '/images/internet_icon.png';
  doc.addImage(img, 'jpg', 5, 62, 3, 3);
  doc.text('www.laforgia.eu – www.laforgiacasseforti.eu', 9, 65);

  let y = 25;
  let rect_init_x = (width / 10) * 4;
  let rect_width = (width / 10) * 6 - 5;

  doc.rect(rect_init_x, y, rect_width, 20);

  doc.setFontSize(9);
  doc.text('Spett.le', rect_init_x + 1, y + 4);
  doc.setFontSize(12);
  const forn_indir = state.fornitori[0].fornitore + '\n' + state.fornitori[0].indirizzo_fornitore;
  var splitRagSoc = doc.splitTextToSize(forn_indir, 110);
  doc
    .setFont(undefined, 'bold')
    .text(splitRagSoc, rect_init_x + 1, y + 9)
    .setFont(undefined, 'normal');

  doc.rect(rect_init_x, y + 20, rect_width, 20);

  doc.setFontSize(9);
  doc.text('Indirizzo di consegna', rect_init_x + 1, y + 24);
  doc.setFontSize(12);
  var splitIndirizzo = doc.splitTextToSize(indirizzo_consegna, 110);
  doc
    .setFont(undefined, 'bold')
    .text(splitIndirizzo, rect_init_x + 1, y + 29)
    .setFont(undefined, 'normal');

  //first letter name
  let fln = (state.nomeOwner && state.nomeOwner[0]) || '';
  //first letter surname
  let fls = (state.cognomeOwner && state.cognomeOwner[0]) || '';
  //TODO: leggere da db lo user che ha creato l'ordine
  let stringNC = fln + fls;
  let num_pdf_off = state.vid ? state.vid + '/LF/' + stringNC : state.oid + '/23' + '/LF/' + stringNC;
  doc.autoTable({
    startY: 80,
    margin: { horizontal: 5 },
    theme: 'grid',
    bodyStyles: { lineColor: [0, 0, 0] },
    didParseCell: hookData => {
      if (hookData.column.index % 2 === 1) hookData.cell.styles.fontStyle = 'bold';
      //on the first row, set font size to 12
      if (hookData.column.index % 2 === 0) hookData.cell.styles.fontSize = 8;

      //hookData.cell.styles.valign = 'bottom';
      if (hookData.column.index === 1 && hookData.row.index === 1) {
        hookData.cell.colSpan = 7;
        hookData.cell.text = note;
        //hookData.cell.styles.fontStyle = 'bold';
      }
    },
    body: [
      ['Tipo Documento', 'ORDINE FORNITORE', 'NR.', num_pdf_off, 'Data', moment(state.data_offerta).format('DD/MM/YYYY')],
      ['Note / Riferimenti', '', '', '', '', ''],
      ['Condizioni di acquisto', condizioni, 'Tempi di consegna', consegna, 'Pagamento', pagamento],
    ],
  });

  let dopoTabella = doc.lastAutoTable.finalY;
  const columns = [
    { title: 'ART./COD./ID', field: 'id' },
    { title: 'DESCRIZIONE', field: 'note' },
    { title: 'U.M.', field: 'um' },
    { title: "Q.TA'", field: 'quantita' },
    {
      title: 'PREZZO',
      field: 'prezzo',
      cellStyle: {
        textAlign: 'bottom',
        valign: 'bottom',
      },
    },
    { title: 'TOTALE', field: 'totale' },
  ];
  const columnTitles = columns.map(columnDef => columnDef.title);
  const pdfData = state.fornitori.map(rowData =>
    columns.map(columnDef => {
      console.log(columnDef.field);
      if (columnDef.field === 'prezzo' || columnDef.field === 'totale')
        return parseFloat(rowData[columnDef.field]).toLocaleString('it-IT', {
          style: 'currency',
          currency: 'EUR',
        });

      return rowData[columnDef.field];
    }),
  );
  //console.log({ pdfData });
  let totale = state.fornitori.reduce((agg, row) => agg + row.totale, 0);
  pdfData.push(['', '', '', '', '', '']);
  let totale_format = parseFloat(totale).toLocaleString('it-IT', {
    style: 'currency',
    currency: 'EUR',
  });
  let totale_imposta = parseFloat((totale * state.iva) / 100).toLocaleString('it-IT', {
    style: 'currency',
    currency: 'EUR',
  });

  let totale_ivato = parseFloat(totale + (totale * state.iva) / 100).toLocaleString('it-IT', {
    style: 'currency',
    currency: 'EUR',
  });

  pdfData.push(['', '', '', '', '', totale_format]);
  pdfData.push(['', '', '%IVA', state.iva, '', totale_imposta]);
  pdfData.push(['', '', '', '', '', totale_ivato]);
  doc.autoTable({
    head: [columnTitles],
    body: pdfData,
    columnStyles: {
      0: { cellWidth: 26 },
      1: { cellWidth: 98 },
      2: { cellWidth: 13 },
      3: { cellWidth: 13 },
      4: { cellWidth: 25 },
      5: { cellWidth: 25 },
    },
    margin: { top: 20, left: 5, right: 5 },
    pageBreak: 'auto',
    startY: dopoTabella + 5,
    theme: 'grid',
    headStyles: { fillColor: [0, 123, 255] },
    alternateRowStyles: { fillColor: '#EBFFFF' },
    tableLineColor: [124, 95, 240],
    didParseCell: hookData => {
      //colspan if column 3 and last row
      hookData.cell.styles.fontStyle = 'bold';
      if (hookData.column.index === 2 && hookData.row.index === pdfData.length - 3) {
        hookData.cell.colSpan = 3;
        hookData.cell.text = 'TOTALE IMPONIBILE';
        //hookData.cell.styles.fontStyle = 'bold';
      }
      if (hookData.column.index === 4 && hookData.row.index === pdfData.length - 2) {
        hookData.cell.text = 'TOTALE IMPOSTA';
      }
      if (hookData.column.index === 2 && hookData.row.index === pdfData.length - 1) {
        hookData.cell.colSpan = 3;
        hookData.cell.text = 'TOTALE ORDINE FORNITORE';
      }
      if (hookData.column.index === 0 && hookData.row.index === pdfData.length - 3) {
        hookData.cell.rowSpan = 3;
        hookData.cell.colSpan = 2;
        hookData.cell.text = 'LA FORGIA SRL';
        //hookData.cell.styles.valign = 'middle';
      }
      if (hookData.column.index === 1 && hookData.row.index === pdfData.length - 3) {
        hookData.cell.rowSpan = 3;
        hookData.cell.text = '';
        //hookData.cell.styles.valign = 'middle';
      }
    },
    didDrawPage: function(data) {
      //qui ci va eventuale logo
      //addFooters(doc);
    },
  });
  addHeader(doc);
  addFooters(doc);

  state.vid = state.vid || state.oid + '/23';
  let pdfName = 'Ordine_fornitore_' + state.vid + '.pdf';
  doc.save(pdfName);
};

const addFooters = doc => {
  const pageCount = doc.internal.getNumberOfPages();

  doc.setFont('helvetica', 'italic');
  doc.setFontSize(8);
  for (var i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.text('Pagina ' + String(i) + ' di ' + String(pageCount), doc.internal.pageSize.width / 2, 287, {
      align: 'center',
    });
  }
};

const addHeader = doc => {
  const pageCount = doc.internal.getNumberOfPages();

  doc.setFont('helvetica', 'italic');
  doc.setFontSize(8);
  var img = new Image();
  var width = doc.internal.pageSize.getWidth();
  const docHeight = doc.internal.pageSize.height;
  const totalPages = doc.internal.pages.length;
  img.src = '/images/logo_lf.png';
  for (var i = 2; i <= pageCount; i++) {
    doc.setPage(i);
    img.src = '/images/logo_lf.png';
    doc.addImage(img, 'png', 5, 2, (width / 2) * 0.65, (width / 8) * 0.6);

    img.src = '/images/logo_bf.png';
    doc.addImage(img, 'png', (width / 10) * 4, 2, (width / 2) * 0.3, (width / 7) * 0.3);

    img.src = '/images/logo_gu.png';
    doc.addImage(img, 'png', (width / 10) * 6, 2, (width / 2) * 0.3, (width / 7) * 0.3);

    img.src = '/images/logo_fe.png';
    doc.addImage(img, 'png', (width / 10) * 8, 2, (width / 2) * 0.3, (width / 7) * 0.3);
  }
};

export default generaPdf;
