const today = new Date();
const currentYear = today.getFullYear();
const loginYear = 2021;

export const customersModule = {
  labelsList: [
    { id: 1, name: 'Banking', slug: 'banking', color: '#FF8C00' },
    { id: 2, name: 'Company', slug: 'company', color: '#00C4B4' },
    { id: 3, name: 'Payments', slug: 'payments', color: '#0F9AF7' },
  ],
  customersList: [
    {
      id: 1457690400,
      ragioneSociale: 'Google',
      profile_pic: 'https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg',
      email: 'paolo.rossi@example.com',
      referente: 'Sig. Paolo Rossi',
      emailUsage: 1.11, // GB
      phones: [{ phone: '(215)-659-7529', label: 'Home' }],
      designation: 'CEO',
      starred: false,
      company: 'TCS',
      labels: [2],
      status: 'active',
      suspendedBy: 'Admin',
      suspendedAt: new Date(),
      lastLoginAt: new Date(loginYear, 1, 1),
      createdAt: new Date(currentYear, 1, 1),
      updatedAt: new Date(currentYear, 1, 1),
      ultimaFattura: '3.2k € 17/02/2021',
    },
    {
      id: 1457690401,
      ragioneSociale: 'Amazon',
      profile_pic: 'https://i2.wp.com/www.casanapoli.net/wp-content/uploads/2020/02/Amazon.jpg?resize=768%2C432&ssl=1',
      email: 'paolo.rossi@example.com',
      referente: 'Sig. Paolo Rossi',
      emailUsage: 1.6, // GB
      phones: [{ phone: '(215)-659-7529', label: 'home' }],
      designation: 'CFO',
      starred: false,
      company: 'Infosys',
      labels: [2, 1],
      status: 'suspended',
      suspendedBy: 'Admin',
      suspendedAt: new Date(currentYear, 1, 1),
      lastLoginAt: new Date(loginYear, 2, 17),
      createdAt: new Date(currentYear, 1, 1),
      updatedAt: new Date(currentYear, 1, 1),
      ultimaFattura: '1.3k EUR 12/05/2021',
    },
    {
      id: 1457690402,
      ragioneSociale: 'Microsoft',
      profile_pic: 'https://authy.com/wp-content/uploads/new-microsoft-logo-SIZED-SQUARE-300x297.jpg',
      email: 'paolo.rossi@example.com',
      referente: 'Sig. Paolo Rossi',
      emailUsage: 1.8, // GB
      phones: [{ phone: '(215)-748-7855', label: 'home' }],
      designation: 'Designer',
      starred: false,
      company: 'Accenture',
      labels: [3],
      status: 'active',
      suspendedBy: 'Admin',
      suspendedAt: new Date(),
      lastLoginAt: new Date(loginYear, 6, 6),
      createdAt: new Date(currentYear, 1, 1),
      updatedAt: new Date(currentYear, 1, 1),
      ultimaFattura: '1.7k EUR 22/05/2021',
    },
    {
      id: 1457690403,
      ragioneSociale: 'Apple',
      profile_pic: 'https://it.cleanpng.com/png-jmvntu/',
      email: 'paolo.rossi@example.com',
      referente: 'Sig. Paolo Rossi',
      emailUsage: 1.7, // GB
      phones: [{ phone: '(215)-659-7529', label: 'home' }],
      designation: 'PHP Developer',
      starred: false,
      company: 'Pizza Hut',
      labels: [],
      status: 'active',
      suspendedBy: 'Admin',
      suspendedAt: new Date(),
      lastLoginAt: new Date(loginYear, 5, 20),
      createdAt: new Date(currentYear, 1, 1),
      updatedAt: new Date(currentYear, 1, 1),
      ultimaFattura: '3.3k EUR 12/10/2021',
    },
    {
      id: 1457690404,
      ragioneSociale: 'Netflix',
      profile_pic: 'https://via.placeholder.com/150',
      email: 'paolo.rossi@example.com',
      referente: 'Sig. Paolo Rossi',
      emailUsage: 2.4, // GB
      phones: [{ phone: '(215)-659-8965', label: 'home' }],
      designation: 'HR Manager',
      starred: true,
      company: 'Dominos',
      labels: [],
      status: 'suspended',
      suspendedBy: 'Admin',
      suspendedAt: new Date(currentYear, 1, 1),
      lastLoginAt: new Date(loginYear, 5, 30),
      createdAt: new Date(currentYear, 1, 1),
      updatedAt: new Date(currentYear, 1, 1),
      ultimaFattura: '4.3k EUR 30/05/2021',
    },
    {
      id: 1457690405,
      ragioneSociale: 'Steam',
      profile_pic: 'https://via.placeholder.com/150',
      email: 'paolo.rossi@example.com',
      referente: 'Sig. Paolo Rossi',
      emailUsage: 0.4, // GB
      phones: [{ phone: '(215)-876-5434', label: 'home' }],
      designation: 'Marketing Head',
      starred: false,
      company: 'Subway',
      labels: [],
      status: 'active',
      suspendedBy: 'Admin',
      suspendedAt: new Date(),
      lastLoginAt: new Date(loginYear, 6, 5),
      createdAt: new Date(currentYear, 1, 1),
      updatedAt: new Date(currentYear, 1, 1),
      ultimaFattura: '0.3k EUR 12/10/2021',
    },
  ],
};
