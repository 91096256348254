import React, { forwardRef, useEffect } from 'react';
import MaterialTable, { MTableBody, MTableEditField } from 'material-table';
import { TableCell, TableFooter, TableRow } from '@material-ui/core';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import axios from 'axios';
import { TextField } from '@material-ui/core';
import { Button, Box } from '@material-ui/core';
import MyDropzone from './myDropZone';
import { Icon } from '@material-ui/core';
import { MdPictureAsPdf, MdOutlineImage, MdInsertDriveFile, MdAttachFile } from 'react-icons/md';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default props => {
  const ordine_id = props.ordine_id;
  const fornitori = props.fornitori;
  const setDocVisible = doc => {
    props.setDocVisible(doc);
  };

  const [url, setUrl] = React.useState('cop');
  const [state, setState] = React.useState({
    myUrl: 'cop2',
    columns: [
      {
        title: 'Posizione',
        field: 'posizione',
        render: rowData => rowData.tableData.id + 1,
        width: '10%',
      },
      { title: 'Fornitore', field: 'fornitore', width: '10%' },
      {
        title: 'Note',
        field: 'note',
        width: '40%',
        editComponent: ({ value, onChange }) => (
          <TextField onChange={e => onChange(e.target.value)} value={value} multiline />
        ),
        render: rowData => <TextField value={rowData.note} multiline />,
      },
      {
        title: 'Prezzo',
        field: 'prezzo',
        type: 'numeric',
        width: '10%',
      },
      {
        title: 'Documento',
        field: 'documento',
        width: '10%',
        editComponent: ({ value, onChange }) => (
          <React.Fragment>
            <MyDropzone
              IDana={1}
              fileUploaded={url => {
                onChange(url);
                /*
              setState(prevState => {
                const myUrl = url;
                console.log({ ...prevState, myUrl });
                return { ...prevState, myUrl };
              });
              */
              }}
            />
            {value}
          </React.Fragment>
        ),
        render: rowData => (
          <Button variant="contained" color="primary" onClick={() => setDocVisible(rowData.documento)}>
            {renderDocIcon(rowData.documento)}
          </Button>
        ),
      },
    ],
    data: fornitori,
  });

  useEffect(() => {
    console.log('qui vediamo la modifica fornitori', props.fornitori);
    setState(prevState => {
      return { ...prevState, data: props.fornitori };
    });
  }, [props.fornitori]);

  useEffect(() => {
    console.log('useEffect', state.data);
    props.setFornitori(state.data);
  }, [state.data]);

  return (
    <MaterialTable
      icons={tableIcons}
      title="Fornitori"
      columns={state.columns}
      data={state.data}
      options={{
        draggable: false,
        tableLayout: 'fixed',
      }}
      components={{
        EditField: props => <MTableEditField {...props} multiline={props.columnDef.type === 'string' || undefined} />,
        Body: props => {
          let totalObj = {
            actualSum: 0,
          };
          props.renderData.forEach((rowData: any) => {
            totalObj.actualSum += rowData.prezzo;
          });
          return (
            <React.Fragment>
              <MTableBody {...props} />
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={5} />
                  <TableCell style={{ textAlign: 'right' }} colSpan={1}>
                    <h2>Totale: {totalObj.actualSum} &euro; + IVA</h2>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </React.Fragment>
          );
        },
      }}
      editable={{
        onRowAdd: newData =>
          new Promise(resolve => {
            console.log('newData', newData);
            setState(prevState => {
              // setState is async  so you need to wait for it to finish
              const data = [...prevState.data];
              data.push(newData);
              return { ...prevState, data };
            });
            //console.log({newData});
            resolve(true);
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            setState(prevState => {
              const data = [...prevState.data];
              data[data.indexOf(oldData)] = newData;
              return { ...prevState, data };
            });
            resolve(true);
          }),
        onRowDelete: oldData =>
          new Promise(resolve => {
            console.log('dentro onrowdelete', oldData);

            setState(prevState => {
              const data = [...prevState.data];
              data.splice(data.indexOf(oldData), 1);
              return { ...prevState, data };
            });
            resolve();
          }),
      }}
      localization={{
        pagination: {
          labelDisplayedRows: '{from}-{to} of {count}',
          labelRowsSelect: 'Fornitori',
        },
        toolbar: {
          searchPlaceholder: 'Filtra i fornitori',
          nRowsSelected: '{0} row(s) selected',
        },
        header: {
          actions: 'Azioni',
        },
        body: {
          editRow: {
            deleteText: 'Sei sicuro di voler eliminare questo fornitore?',
          },
          emptyDataSourceMessage: 'Non ci sono offerta ai fornitori da visualizzare',
          filterRow: {
            filterTooltip: 'Filtro',
          },
        },
      }}
    />
  );
};

const renderDocIcon = (doc: string) => {
  if (!doc) {
    return <MdAttachFile size={25} />;
  }
  if (doc.includes('.pdf')) {
    return <MdPictureAsPdf size={25} />;
  } else if (doc.includes('.doc') || doc.includes('.docx')) {
    return <MdAttachFile size={25} />;
  } else if (doc.includes('.xls') || doc.includes('.xlsx') || doc.includes('.csv')) {
    return <MdInsertDriveFile size={25} />;
  } else if (doc.includes('.ppt') || doc.includes('.pptx')) {
    return <MdAttachFile size={25} />;
  } else if (doc.includes('.jpg') || doc.includes('.jpeg') || doc.includes('.png')) {
    return <MdOutlineImage size={25} />;
  } else if (doc.includes('.zip') || doc.includes('.rar')) {
    return <MdAttachFile size={25} />;
  } else if (doc.includes('.txt')) {
    return <MdAttachFile size={25} />;
  } else {
    return <MdAttachFile size={25} />;
  }
};
