import React, { forwardRef, useEffect } from 'react';
import MaterialTable, { MTableBody, MTableEditField } from 'material-table';
import { TableCell, TableFooter, TableRow } from '@material-ui/core';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import axios from 'axios';
import { TextField } from '@material-ui/core';
import { Button, Box } from '@material-ui/core';
import MyDropzone from './myDropZone';
import { Icon } from '@material-ui/core';
import { MdPictureAsPdf, MdOutlineImage, MdInsertDriveFile, MdAttachFile } from 'react-icons/md';
import { Save, Delete } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory } from 'react-router';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import generaPdf from './generaPdfFor';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default props => {
  console.log('props di fornitori form', props);
  const iva = props.iva;
  const ordine_id = props.ordine_id;
  const fornitori = props.fornitori;
  const datiOrdine = props.datiOrdine;
  const [oid, setOid] = React.useState(props.oid);
  const [fornitoriSel, setFornitoriSel] = React.useState({
    fornitore: 0,
    fornitoriOpzioni: [],
    currentFornitore: '',
  });
  const setDocVisible = doc => {
    //TODO: open new window
    if (oid > 0 || 1) window.open(doc, '_blank');
    else props.setDocVisible(doc);
  };
  const history = useHistory();

  const [isLoading, setIsLoading] = React.useState(false);
  const [state, setState] = React.useState({
    columns: [{ title: 'Fornitore', field: 'fornitore' }],
    data: [],
  });

  const handleFornitore = fornitore => {
    console.log('handleFornitore', fornitore);
    const ragsoc = fornitoriSel.fornitoriOpzioni.find(f => f.uid === fornitore).ragsoc;
    setFornitoriSel({
      ...fornitoriSel,
      fornitore: ragsoc,
      currentFornitore: fornitoriSel.fornitoriOpzioni.find(f => f.uid === fornitore),
    });
  };

  useEffect(() => {
    console.log('settiamo props.oid', props.oid);
    console.log('settiamo oid', oid);
    setState({
      isLoading: false,
      columns: [
        /*
        {
          title: 'Ordine',
          width: '5%',
          render: rowData => {
            const button = (
              <IconButton
                color="inherit"
                onClick={() => {
                  //TODO: we should pass only id e not the whole object
                  console.log('passiamo il codcf del cliente', props.clienteID);
                  history.push({
                    pathname: '/ordine-fornitore-interventi/0',
                    state: {
                      fornitore: rowData.fornitore,
                      offID: props.oid,
                      cliente: props.cliente + ' [' + props.clienteID + ']',
                    },
                  });
                }}>
                <Save />
              </IconButton>
            );
            return button;
          },
        },
        */
        {
          title: 'Fornitore',
          field: 'fornitore',
          width: '20%',
          filtering: true,
          editComponent: ({ value, onChange }) => {
            return (
              <Select
                style={{ width: '100%' }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                label="FORNITORE"
                onChange={e => onChange(e.target.value)}>
                {fornitoriSel.fornitoriOpzioni &&
                  fornitoriSel.fornitoriOpzioni.map(key => {
                    return <MenuItem value={key.ragsoc}>{key.ragsoc}</MenuItem>;
                  })}
              </Select>
            );
          },
          render: rowData => <TextField value={rowData.fornitore} />,
          validate: rowData => (!rowData.fornitore ? 'Campo obbligatorio' : ''),
        },
        {
          title: 'Descrizione',
          field: 'note',
          width: '15%',
          filtering: true,
          editComponent: ({ value, onChange }) => {
            return <TextField onChange={e => onChange(e.target.value)} value={value} multiline />;
          },
          render: rowData => <TextField value={rowData.note} multiline />,
        },

        {
          title: 'U.M.',
          field: 'um',
          width: '5%',
          initialEditValue: '',
          filtering: false,
          //validate: rowData => (!rowData.quantita ? 'La quant è obbl' : ''),
        },
        {
          title: 'Qta',
          field: 'quantita',
          type: 'numeric',
          width: '5%',
          initialEditValue: 0,
          filtering: false,
          //validate: rowData => (!rowData.quantita ? 'La quant è obbl' : ''),
        },
        {
          title: 'Prezzo unitario',
          field: 'prezzo',
          type: 'numeric',
          width: '10%',
          initialEditValue: 0,
          filtering: false,
          render: rowData => {
            return parseFloat(rowData.prezzo).toLocaleString('it-IT', {
              style: 'currency',
              currency: 'EUR',
            });
          },
        },
        {
          title: 'Prezzo fornitore',
          type: 'numeric',
          width: '10%',
          filtering: false,
          render: rowData => {
            rowData.totale = rowData.quantita * rowData.prezzo;
            return parseFloat(rowData.quantita * rowData.prezzo).toLocaleString('it-IT', {
              style: 'currency',
              currency: 'EUR',
            });
          },
        },
        {
          title: 'Ricarico',
          field: 'ricarico',
          type: 'numeric',
          width: '7%',
          initialEditValue: 0,
          filtering: false,
        },
        {
          title: 'Prezzo al cliente',
          type: 'numeric',
          width: '7%',
          filtering: false,
          render: rowData => {
            let pCli = parseFloat(rowData.quantita * rowData.prezzo * (1 + rowData.ricarico / 100)).toLocaleString('it-IT', {
              style: 'currency',
              currency: 'EUR',
            });
            return pCli;
          },
        },
        {
          title: 'Documento',
          field: 'documento',
          filtering: false,
          width: '15%',
          editComponent: props2 => {
            return (
              <React.Fragment>
                <MyDropzone
                  IDana={props.oid}
                  load={() => setIsLoading(true)}
                  loaded={() => setState({ ...state, isLoading: false })}
                  name={props2.rowData.documento && props2.rowData.documento.split('/').pop()}
                  fileUploaded={url => {
                    console.log('props', props);
                    console.log('fileUploaded', url);
                    //console.log('onChange', onChange);
                    //we keep only the filename
                    //const filename = url.split('/').pop();
                    props2.onChange(url);
                    //setState({ ...state, isLoading: false });
                  }}
                />
              </React.Fragment>
            );
          },
          render: rowData =>
            rowData.documento && (
              <React.Fragment>
                <Button variant="contained" color="primary" onClick={() => setDocVisible(rowData.documento)}>
                  {renderDocIcon(rowData.documento)}
                  {rowData.documento &&
                    rowData.documento
                      .split('/')
                      .pop()
                      .substr(0, 5)}
                </Button>
                <Button
                  style={{ marginLeft: 10 }}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    //confirm
                    if (!window.confirm('Vuoi cancellare il documento?')) return;
                    //console.log('rowData', rowData);
                    //remove documento from state.data
                    let data = state.data;
                    //console.log('state', state);
                    data.forEach((item, index) => {
                      console.log('item', item);
                      if (item.id === rowData.id) {
                        item.documento = '';
                        deleteFile(item.id, item.oid);
                      }
                    });
                    setState({ ...state, data });
                    //reload page
                    //window.location.reload();
                  }}>
                  <DeleteOutline color="primary" style={{ color: 'red' }} />
                </Button>
              </React.Fragment>
            ),
        },
      ],
      data: fornitori,
    });
  }, [props.oid, oid, fornitoriSel.fornitoriOpzioni]);

  useEffect(() => {
    console.log('useEffect', state.data);
    props.setFornitori(state.data);
  }, [state.data]);

  useEffect(() => {
    console.log('settiamo oid', props.oid);
    setOid(props.oid);
  }, [props.oid]);

  useEffect(() => {
    try {
      let json = fetch('https://laforgiaws.netkom.it/ws/getFornitori.php', {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(risp => {
          setFornitoriSel({ ...fornitoriSel, fornitoriOpzioni: risp });
        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  const myOid = props.oid;
  return (
    <div>
      <MaterialTable
        isLoading={isLoading}
        icons={tableIcons}
        title="Ordini Fornitori"
        columns={state.columns}
        data={state.data}
        options={{
          filtering: true,
          draggable: false,
          paging: false,
          pageSize: 10,
          pageSizeOptions: [10, 15, 20], // rows selection options
          exportButton: {
            csv: true,
            pdf: true,
          },
          exportPdf: (columns, data) => {
            console.log('exportPdf', data, columns);
            console.log('state ordine', datiOrdine);
            datiOrdine.fornitori = data;
            generaPdf(datiOrdine);
          },
          tableLayout: 'fixed',
        }}
        components={{
          EditField: props => <MTableEditField {...props} multiline={props.columnDef.type === 'string' || undefined} />,
          Body: props => {
            let totalObj = {
              actualSum: 0,
            };
            props.renderData.forEach((rowData: any) => {
              console.log('total_ rowData', rowData);
              if (!rowData.prezzo) rowData.prezzo = 0;
              totalObj.actualSum += parseFloat(rowData.prezzo) * parseFloat(rowData.quantita);
              console.log('total_ totalObj', totalObj);
            });
            return (
              <React.Fragment>
                <MTableBody {...props} />
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={7} />
                    <TableCell style={{ textAlign: 'right' }} colSpan={3}>
                      <h2>
                        Totale imponibile:&nbsp;
                        {parseFloat(totalObj.actualSum).toLocaleString('it-IT', {
                          style: 'currency',
                          currency: 'EUR',
                        })}
                      </h2>
                    </TableCell>
                  </TableRow>

                  {iva && (
                    <TableRow>
                      <TableCell colSpan={7} />
                      <TableCell style={{ textAlign: 'right' }} colSpan={3}>
                        <h2>
                          Totale imposta:&nbsp;
                          {parseFloat(totalObj.actualSum * (iva / 100)).toLocaleString('it-IT', {
                            style: 'currency',
                            currency: 'EUR',
                          })}
                        </h2>
                      </TableCell>
                    </TableRow>
                  )}

                  <TableRow>
                    <TableCell colSpan={7} />
                    <TableCell style={{ textAlign: 'right' }} colSpan={3}>
                      <h2>
                        Totale ordine fornitori:&nbsp;
                        {parseFloat(totalObj.actualSum * (1 + iva / 100)).toLocaleString('it-IT', {
                          style: 'currency',
                          currency: 'EUR',
                        })}
                      </h2>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </React.Fragment>
            );
          },
        }}
        /*
        actions={[
          {
            icon: tableIcons.Delete,
            tooltip: 'Delete User',
            onClick: (event, rowData) => alert('You want to delete ' + rowData.name),
          },
          {
            icon: tableIcons.Add,
            tooltip: 'Add User',
            isFreeAction: true,
            onClick: event => alert('You want to add a new row'),
          },
        ]}
        */
        editable={{
          onRowAdd: newData =>
            new Promise(resolve => {
              console.log('newData', newData);
              setState(prevState => {
                // setState is async  so you need to wait for it to finish
                const data = [...prevState.data];
                data.push(newData);
                return { ...prevState, data };
              });
              //console.log({newData});
              resolve(true);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setState(prevState => {
                const data = [...prevState.data];
                data[data.indexOf(oldData)] = newData;
                return { ...prevState, data };
              });
              resolve(true);
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              console.log('dentro onrowdelete', oldData);

              setState(prevState => {
                const data = [...prevState.data];
                data.splice(data.indexOf(oldData), 1);
                return { ...prevState, data };
              });
              resolve();
            }),
        }}
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to} of {count}',
            labelRowsSelect: 'Fornitori',
          },
          toolbar: {
            searchPlaceholder: 'Filtra i fornitori',
            nRowsSelected: '{0} row(s) selected',
          },
          header: {
            actions: 'Azioni',
          },
          body: {
            editRow: {
              deleteText: 'Sei sicuro di voler eliminare questo fornitore?',
            },
            emptyDataSourceMessage: 'Non ci sono offerta ai fornitori da visualizzare',
            filterRow: {
              filterTooltip: 'Filtro',
            },
          },
        }}
      />
    </div>
  );
};

const renderDocIcon = (doc: string) => {
  if (!doc) {
    return <MdAttachFile size={25} />;
  }
  if (doc.includes('.pdf')) {
    return <MdPictureAsPdf size={25} />;
  } else if (doc.includes('.doc') || doc.includes('.docx')) {
    return <MdAttachFile size={25} />;
  } else if (doc.includes('.xls') || doc.includes('.xlsx') || doc.includes('.csv')) {
    return <MdInsertDriveFile size={25} />;
  } else if (doc.includes('.ppt') || doc.includes('.pptx')) {
    return <MdAttachFile size={25} />;
  } else if (doc.includes('.jpg') || doc.includes('.jpeg') || doc.includes('.png')) {
    return <MdOutlineImage size={25} />;
  } else if (doc.includes('.zip') || doc.includes('.rar')) {
    return <MdAttachFile size={25} />;
  } else if (doc.includes('.txt')) {
    return <MdAttachFile size={25} />;
  } else {
    return <MdAttachFile size={25} />;
  }
};

const deleteFile = async (id, oid) => {
  const response = await fetch('https://laforgiaws.netkom.it/ws/delFileFornitoreInt.php', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id,
      oid,
    }),
  });
  const json = await response.json();
  if (!response.ok) throw Error(json.message);
  //update the state of the component

  return json;
};
