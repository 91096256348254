import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import { setAuthUser, setForgetPassMailSent, updateLoadUser, wrongLogin } from '../../../redux/actions/Auth';
import React from 'react';
import cogoToast from 'cogo-toast';

const BasicAuth = {
  onRegister: ({ name, email, password }) => {
    return dispatch => {
      dispatch(fetchStart());

      setTimeout(() => {
        dispatch(fetchSuccess());
        const user = { name: name, email: email, password: password };
        localStorage.setItem('user', JSON.stringify(user));
        dispatch(setAuthUser(user));
      }, 300);
    };
  },

  onLogin: ({ email, password }) => {
    return dispatch => {
      try {
        // eslint-disable-next-line no-console
        console.log('copmod onLogin');
        dispatch(fetchStart());

        let data = { username: email, password: password };
        fetch('https://laforgiaws.netkom.it/ws/login.php', {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
          .then(response => response.json())
          .then(res => {
            if (!res.success) throw 'Non autorizzato';
            // eslint-disable-next-line no-console
            console.log('ecco la risposta login', res);
            const user = res;
            dispatch(fetchSuccess());
            localStorage.setItem('user', JSON.stringify(user));
            dispatch(setAuthUser(user));
          })
          .catch(err => {
            dispatch(fetchSuccess());
            dispatch(wrongLogin(true));
            cogoToast.error(
              <div>
                <b>Attenzione!</b>
                <div>Username o password errati</div>
              </div>,
            );
          });

        /*
        setTimeout(() => {
          const user = { name: 'Admin', email: email, password: password };
          dispatch(fetchSuccess());
          localStorage.setItem('user', JSON.stringify(user));
          dispatch(setAuthUser(user));
        }, 300);
        */
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  onLogout: () => {
    return dispatch => {
      dispatch(fetchStart());

      setTimeout(() => {
        dispatch(fetchSuccess());
        localStorage.removeItem('user');
        dispatch(setAuthUser(null));
      }, 300);
    };
  },

  getAuthUser: (loaded = false) => {
    return dispatch => {
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));

      setTimeout(() => {
        dispatch(fetchSuccess());
        dispatch(setAuthUser(JSON.parse(localStorage.getItem('user'))));
      }, 300);
    };
  },

  onForgotPassword: () => {
    return dispatch => {
      dispatch(fetchStart());

      setTimeout(() => {
        dispatch(setForgetPassMailSent(true));
        dispatch(fetchSuccess());
      }, 300);
    };
  },
  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  },
};

export default BasicAuth;
