import React, { useEffect, useState, forwardRef } from 'react';
import { Box, Grid } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TextField, Input } from '@material-ui/core';
import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import MaterialTable, { MTableBody, MTableEditField } from 'material-table';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import IconButton from '@material-ui/core/IconButton';
import {
  MdArrowBack,
  MdDownload,
  MdFileDownload,
  MdDeleteForever,
  MdSave,
  MdArchive,
  MdUnarchive,
  MdStars,
  MdAccessAlarm,
  MdCancel,
} from 'react-icons/md';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import AddBox from '@material-ui/icons/AddBox';
import ViewColumn from '@material-ui/icons/ViewColumn';
import moment from 'moment';
import cogoToast from 'cogo-toast';
import Button from '@material-ui/core/Button';
import { ArrowRight } from '@material-ui/icons/';
import { useHistory } from 'react-router';
//right arrow material icon

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles(theme => ({
  orderLg2: {
    [theme.breakpoints.up('lg')]: {
      order: 2,
    },
  },
  orderLg1: {
    [theme.breakpoints.up('lg')]: {
      order: 1,
    },
  },
}));

const breadcrumbs = [
  { label: 'Home', link: '/dashboard/crm' },
  { label: 'Crm', isActive: true },
];

const CrmDashboard = () => {
  const classes = useStyles();
  const [dati, setDati] = useState({
    clientiTot: 1,
    partiteApe: 0,
    utenti: 0,
    ordini: 0,
  });
  const [state, setState] = useState({
    cliente: { id: '0', name: 'Seleziona', stato: '' },
    clienteC: { id: '0', name: 'Seleziona', stato: '' },
    prodotto: '',
    prodotti: [],
    columns: [
      {
        title: 'Data ordine',
        field: 'data',
        render: rowData => moment(rowData.data).format('DD/MM/YYYY'),
        width: '7%',
      },
      {
        title: 'Num. Ordine',
        render: rowData => (rowData.vid ? rowData.vid : rowData.oid + '/23'),
        width: '5%',
      },
      {
        title: 'Fornitore',
        field: 'fornitore',
        width: '10%',
      },
      {
        title: 'Cliente',
        field: 'ragsoc',
        width: '10%',
      },
      /*
      {
        title: 'Titolo',
        field: 'titolo',
        width: '10%',
      },
      */
      {
        title: 'Descrizione',
        field: 'note',
        width: '18%',
      },
      {
        title: 'Qtà',
        field: 'quantita',
        width: '3%',
      },
      {
        title: 'Prezzo',
        field: 'prezzo',
        width: '4%',
        render: rowData => {
          let pCli = parseFloat(rowData.prezzo).toLocaleString('it-IT', {
            style: 'currency',
            currency: 'EUR',
          });
          return pCli;
        },
      },
      {
        title: 'Totale',
        field: 'totale',
        width: '4%',
        render: rowData => {
          let pCli = parseFloat(rowData.totale).toLocaleString('it-IT', {
            style: 'currency',
            currency: 'EUR',
          });
          return pCli;
        },
      },
      {
        title: 'Ricarico',
        field: 'ricarico',
        width: '4%',
        numeric: true,
        render: rowData => {
          return rowData.ricarico + '%';
        },
      },
      {
        title: 'Prezzo cliente',
        type: 'numeric',
        width: '5%',
        render: rowData => {
          let pCli = parseFloat(rowData.quantita * rowData.prezzo * (1 + rowData.ricarico / 100)).toLocaleString('it-IT', {
            style: 'currency',
            currency: 'EUR',
          });
          return pCli;
        },
      },
      {
        title: 'Ordine',
        width: '5%',
        render: rowData => {
          return (
            <IconButton variant="contained" color="secondary" onClick={() => mostraPopup(rowData)}>
              <ArrowRight style={{ fontSize: '40px' }} />
            </IconButton>
          );
        },
      },
    ],
    dataInizio: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
    dataFine: new Date(),
  });
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [valueAuto, setValueAuto] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');

  const [openC, setOpenC] = React.useState(false);
  const [optionsC, setOptionsC] = React.useState([]);
  const [valueAutoC, setValueAutoC] = React.useState(null);
  const [inputValueC, setInputValueC] = React.useState('');

  const history = useHistory();

  const loading = false;
  const loadingC = false;

  const mostraPopup = row => {
    //setSelected(row);
    //setBloccoVisible(true);
    history.push({
      pathname: '/ordine/' + row.oid,
      state: { altro: 'altro' },
    });
  };

  React.useEffect(() => {
    if (inputValue.length > 2)
      (async () => {
        const res = await axios.get('https://laforgiaws.netkom.it/ws/getFornitori.php?searchTerm=' + inputValue);
        const aziende = res.data;
        console.log('compiliamo i campi azienda', aziende);
        setOptions(
          aziende.map(key => {
            return {
              uid: key.uid,
              ragsoc: key.ragsoc,
              email: key.EMAIL,
            };
          }),
        );
      })();
    if (inputValue.length <= 2) {
      setOptions([]);
    }
  }, [inputValue]);

  React.useEffect(() => {
    if (inputValueC.length > 2)
      (async () => {
        const res = await axios.get('https://laforgiaws.netkom.it/ws/getNomiAnagrafiche.php?searchTerm=' + inputValueC);
        const aziende = res.data;
        console.log('compiliamo i campi azienda', aziende);
        setOptionsC(
          aziende.map(key => {
            return {
              id: key.CODCF,
              ragsoc: key.RAGSOC,
              email: key.EMAIL,
            };
          }),
        );
      })();
    if (inputValueC.length <= 2) {
      setOptionsC([]);
    }
  }, [inputValueC]);

  useEffect(() => {
    let json = fetch('https://laforgiaws.netkom.it/ws/cercaOrdiniFornitori.php', {
      method: 'get',
    })
      .then(response => response.json())
      .then(risp => {
        console.log('ecco la risposta', risp);
        if (risp.response) {
          setState(prevState => ({
            ...prevState,
            prodotti: risp.articoli,
          }));
        }
      });
  }, []);

  useEffect(() => {
    console.log(state);
  }, [state]);

  useEffect(() => {
    console.log(dati);
  }, [dati]);

  return (
    <Box>
      <GridContainer>
        <br />
        <Grid item xs={12}>
          <h2 style={{ color: '#012060' }}>ORDINI FORNITORI</h2>
        </Grid>
        <br />
        <Grid item xs={4}>
          <Autocomplete
            autoComplete
            id="ricerca-cliente"
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            value={state.cliente}
            onChange={(event, newValue) => {
              console.log('occhio newValue', newValue);
              if (newValue) {
                setState({
                  ...state,
                  cliente: newValue,
                });
              }
            }}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            getOptionSelected={(option, value) => option.ragsoc === value.ragsoc}
            getOptionLabel={option => option.ragsoc}
            options={options}
            loading={loading}
            //copmod se sono tante le filtriamo lato server in questo modo
            //filterOptions={x => x}
            renderInput={params => (
              <TextField
                {...params}
                label="Fornitore"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            autoComplete
            id="ricerca-cliente2"
            open={openC}
            onOpen={() => {
              setOpenC(true);
            }}
            onClose={() => {
              setOpenC(false);
            }}
            value={state.clienteC}
            onChange={(event, newValue) => {
              console.log('occhio newValue', newValue);
              if (newValue) {
                setState({
                  ...state,
                  clienteC: newValue,
                });
              }
            }}
            onInputChange={(event, newInputValue) => {
              setInputValueC(newInputValue);
            }}
            getOptionSelected={(option, value) => option.ragsoc === value.ragsoc}
            getOptionLabel={option => option.ragsoc}
            options={optionsC}
            loading={loadingC}
            //copmod se sono tante le filtriamo lato server in questo modo
            //filterOptions={x => x}
            renderInput={params => (
              <TextField
                {...params}
                label="Cliente"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            value={state.prodotto || ''}
            variant="outlined"
            onChange={e => setState({ ...state, prodotto: e.target.value })}
            id="prodotto"
            name="prodotto"
            label="Inserire il prodotto"
            className="ricercaProdotto"
          />
        </Grid>
        <Grid item xs={2}>
          <div className={'contenutoIns  f-data'}>
            <div className={'labelIns'}>Data inizio</div>
            <DatePicker
              dateFormat={'dd/MM/yyyy'}
              selected={state.dataInizio}
              onChange={date => setState({ ...state, dataInizio: date })}
            />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className={'contenutoIns  f-data'}>
            <div className={'labelIns'}>Data fine</div>
            <DatePicker
              dateFormat={'dd/MM/yyyy'}
              selected={state.dataFine}
              onChange={date => setState({ ...state, dataFine: date })}
            />
          </div>
        </Grid>
        <Grid item xs={4}>
          <IconButton
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => {
              console.log('cliccato', state);
              //check if all fields are filled
              /*
              if (state.cliente.id == '0' && state.prodotto == '' && state.clienteC.id == '0') {
                cogoToast.error(
                  <div>
                    <b>Attenzione!</b>
                    <div>Devi inserire almeno uno dei campi</div>
                  </div>,
                );
                return;
              }
              */
              const data = {
                ...state,
                cliente: state.cliente.ragsoc,
                clienteC: state.clienteC.id,
                prodotto: state.prodotto.trim(),
              };
              console.log('data', data);
              let json = fetch('https://laforgiaws.netkom.it/ws/cercaOrdiniFornitori.php', {
                method: 'post',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
              })
                .then(response => response.json())
                .then(risp => {
                  console.log('ecco la risposta cercaProdotti', risp);
                  if (risp.response) {
                    //setProdotti(risp.articoli);
                    setState(prevState => ({
                      ...prevState,
                      prodotti: risp.articoli,
                    }));
                  }
                });
            }}>
            <MdArchive />
            CERCA
          </IconButton>
        </Grid>
        <Grid item xs={4}>
          <IconButton
            variant="contained"
            color="primary"
            type="submit"
            style={{ color: 'red' }}
            onClick={() => {
              console.log('cliccato', state);
              setState({
                ...state,
                prodotti: [],
                cliente: { id: '0', name: 'Seleziona', stato: '' },
                clienteC: { id: '0', name: 'Seleziona', stato: '' },
                prodotto: '',
                dataInizio: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
                dataFine: new Date(),
              });
            }}>
            <MdDeleteForever />
            ANNULLA
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <MaterialTable
            style={{
              backgroundColor: '#cceef6',
              borderLeft: '3px solid #00ccff',
            }}
            icons={tableIcons}
            title="Articoli"
            columns={state.columns}
            data={state.prodotti}
            options={{
              draggable: false,
              tableLayout: 'fixed',
              pageSize: 10,
              headerStyle: {
                backgroundColor: '#012060', // Set the header background color to blue
                color: '#FFF', // Set the header text color to white
              },
            }}
            components={{
              EditField: props => <MTableEditField {...props} multiline={props.columnDef.type === 'string' || undefined} />,
            }}
            localization={{
              pagination: {
                labelDisplayedRows: '{from}-{to} of {count}',
                labelRowsSelect: 'Clienti',
              },
              toolbar: {
                searchPlaceholder: 'Filtra  gli articoli',
                nRowsSelected: '{0} row(s) selected',
              },
              header: {
                actions: 'Azioni',
              },
              body: {
                editRow: {
                  deleteText: 'Sei sicuro di voler eliminare questo articolo?',
                },
                emptyDataSourceMessage: 'Non ci sono articoli',
                filterRow: {
                  filterTooltip: 'Filtro',
                },
              },
            }}
          />
        </Grid>
        {/*
        <Grid item xs={12}>
          <br />
          <h3>Clienti totali: {dati.clientiTot}</h3>
          Ultimi clienti inseriti o altri criteri?
          <br />
          <br />
          <h3>Clienti con partite aperte: {dati.partiteApe}</h3>
          Mettere i 5 crediti maggiori?
          <br />
          <br />
          <h3>Utenti totali: {dati.utenti}</h3>
          <br />
          <h3>Offerte: {dati.offerte}</h3>
          Cosa serve far vedere per offerte?
          <br />
        </Grid>
          */}
      </GridContainer>
    </Box>
  );
};

export default CrmDashboard;
