import React, { useEffect, useState } from 'react';
import { Paper, Table, TableCell, TableContainer, TableRow } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import CustomerListRow from './UserListRow';
import CustomerTableHead from './UserTableHead';
import CustomerTableToolbar from './UserTableToolbar';
import { getComparator, stableSort } from '../../../@jumbo/utils/tableHelper';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCustomer, getCustomers, setCurrentCustomer, getCustomerPartite } from '../../../redux/actions/Customers';
import AddEditCustomer from './AddEditUser/';
import ConfirmDialog from '../../../@jumbo/components/Common/ConfirmDialog';
import { useDebounce } from '../../../@jumbo/utils/commonHelper';
import useStyles from './index.style';
import CustomerDetailView from './UserDetailView';
import CustomerPartiteView from './PartiteDetailView';
import NoRecordFound from './NoRecordFound';
import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions/Common';

const CustomersModule = () => {
  const classes = useStyles();
  const { customers } = useSelector(({ customersReducer }) => {
    return customersReducer;
  });
  const [orderBy, setOrderBy] = React.useState('name');
  const [order, setOrder] = React.useState('asc');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openPartiteDialog, setOpenPartiteDialog] = useState(false);
  const [openCustomerDialog, setOpenCustomerDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({ name: '' });
  const [customersFetched, setCustomersFetched] = useState(false);
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getCustomers(filterOptions, debouncedSearchTerm, () => {
        setFilterApplied(!!filterOptions.length || !!debouncedSearchTerm);
        setCustomersFetched(true);
      }),
    );
  }, [dispatch, filterOptions, debouncedSearchTerm]);

  const handleCloseCustomerDialog = () => {
    setOpenCustomerDialog(false);
    dispatch(setCurrentCustomer(null));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = customers.map(n => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleRowClick = (event, row) => {
    console.log('Dati row', row);
    handleCustomerView(row);
    /*
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
    */
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCustomerView = customer => {
    console.log('View customer', customer);
    dispatch(setCurrentCustomer(customer));
    setOpenViewDialog(true);
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    dispatch(setCurrentCustomer(null));
  };

  const handleClosePartiteDialog = () => {
    setOpenPartiteDialog(false);
    dispatch(setCurrentCustomer(null));
  };

  const handleCustomerEdit = customer => {
    //TODO: not yet implemented
    console.log('Edit customer', customer);
    if (customer.status === 'Cliente') {
      dispatch(fetchError('Funzione non ancora implementata su gestionale'));
    } else {
      dispatch(setCurrentCustomer(customer));
      setOpenCustomerDialog(true);
    }
  };

  const handleCustomerPartite = customer => {
    //dispatch({ type: SET_CUSTOMER_DETAILS, payload: customer });
    dispatch(getCustomerPartite(customer));
    setOpenPartiteDialog(true);
  };

  const handleCustomerDelete = customer => {
    if (customer.status === 'Cliente') {
      dispatch(fetchError('Eliminazione non ancora implementata su gestionale'));
    } else {
      console.log('Delete customer', customer);
      setSelectedCustomer(customer);
      setOpenConfirmDialog(true);
    }
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    dispatch(deleteCustomer(selectedCustomer.id));
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <CustomerTableToolbar
          selected={selected}
          setSelected={setSelected}
          onCustomerAdd={setOpenCustomerDialog}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
        <TableContainer className={classes.container}>
          <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" aria-label="sticky enhanced table">
            <CustomerTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={customers.length}
            />
            <TableBody>
              {!!customers.length ? (
                stableSort(customers, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <CustomerListRow
                      key={index}
                      row={row}
                      onRowClick={handleRowClick}
                      onCustomerEdit={handleCustomerEdit}
                      onCustomerPartite={handleCustomerPartite}
                      onCustomerDelete={handleCustomerDelete}
                      onCustomerView={handleCustomerView}
                      isSelected={isSelected}
                    />
                  ))
              ) : (
                <TableRow style={{ height: 53 * 6 }}>
                  <TableCell colSpan={7} rowSpan={10}>
                    {isFilterApplied ? (
                      <NoRecordFound>There are no records found with your filter.</NoRecordFound>
                    ) : (
                      <NoRecordFound>
                        {customersFetched ? 'There are no records found.' : 'Loading customers...'}
                      </NoRecordFound>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage="Clienti per pagina"
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={customers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Paper>

      {openCustomerDialog && <AddEditCustomer open={openCustomerDialog} onCloseDialog={handleCloseCustomerDialog} />}
      {openViewDialog && <CustomerDetailView open={openViewDialog} onCloseDialog={handleCloseViewDialog} />}
      {openPartiteDialog && <CustomerPartiteView open={openPartiteDialog} onCloseDialog={handleClosePartiteDialog} />}

      <ConfirmDialog
        open={openConfirmDialog}
        title={`Conferma cancellazione ${selectedCustomer.ragsoc}`}
        content={'Confermi di voler cancellare questo cliente?'}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        btnLabels={{ confirm: 'Conferma', cancel: 'Annulla' }}
      />
    </div>
  );
};

export default CustomersModule;
