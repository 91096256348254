import React, { useState, useEffect, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import { ArrowRight } from '@material-ui/icons/';
import PartitePopup from './partitePopup';
import { deleteCustomer, getCustomers, setCurrentCustomer, getCustomerPartite } from 'redux/actions/Customers';
import MaterialTable, { MTableBody, MTableEditField } from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const breadcrumbs = [
  { label: <IntlMessages id={'sidebar.main'} />, link: '/' },
  { label: <IntlMessages id={'pages.partiteAperte'} />, isActive: true },
];

const PartiteAperte = () => {
  const [documenti, setDocumenti] = useState([]);
  const [bloccoVisible, setBloccoVisible] = useState(false);
  const [selected, setSelected] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    console.log('ecco i docs', documenti);
  }, [documenti]);

  useEffect(() => {
    getDocs();
  }, []);

  const onClose = () => {
    setBloccoVisible(false);
    setSelected(false);
  };

  const mostraPopup = row => {
    console.log('row', row);
    getAnagrafica(row.CODCF);
    //dispatch(getCustomerPartite({ searchTerm: row.RAGSOC }));
    setSelected(row);
    setBloccoVisible(true);
  };

  const getDocs = () => {
    const data = {};
    let json = fetch('https://laforgiaws.netkom.it/ws/getSaldiAnag.php', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(risp => {
        console.log('ecco la risposta', risp);
        if (risp.response) {
          setDocumenti(risp.clienti);
        }
      });
  };

  const getAnagrafica = id => {
    let json = fetch('https://laforgiaws.netkom.it/ws/getAnagrafiche.php?searchCode=' + id, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(risp => {
        console.log('ecco la risposta singola anagarfica', risp);
        if (risp.length == 1) {
          dispatch(setCurrentCustomer(risp[0]));
        }
      });
  };

  return (
    <PageContainer heading={<IntlMessages id="pages.partiteAperte" />} breadcrumbs={breadcrumbs}>
      <MaterialTable
        icons={tableIcons}
        title="Clienti che hanno partite aperte"
        columns={[
          {
            title: 'Cliente',
            field: 'RAGSOC',
            cellStyle: {
              padding: '10px 30px',
            },
          },
          {
            title: 'Saldo',
            field: 'saldo',
            type: 'numeric',
            cellStyle: {
              padding: '10px 30px',
            },
            render: rowData => (
              <div style={{ textAlign: 'right' }}>
                {parseFloat(rowData.saldo).toLocaleString('it-IT', {
                  style: 'currency',
                  currency: 'EUR',
                })}{' '}
                €
              </div>
            ),
          },
        ]}
        data={documenti}
        options={{
          draggable: false,
          headerStyle: {
            backgroundColor: '#01579b',
            color: '#FFF',
            padding: '10px 30px',
          },
          pageSize: 10,
          search: true,
          actionsColumnIndex: -1,
          toolbarButtonAlignment: 'left', // here is the option to change toolbar buttons' alignment
        }}
        actions={[
          rowData => {
            let active = 1;
            return {
              padding: '10px 30px',
              icon: () => (
                <div style={{ padding: '0px 30px' }}>
                  <ChevronRight />
                </div>
              ),
              tooltip: 'Vai al dettaglio partite',
              onClick: (event, rowData) => {
                //console.log('rowData', rowData);
                mostraPopup(rowData);
              },
              iconProps: { color: active ? 'primary' : 'secondary' },
            };
          },
        ]}
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to} of {count}',
            labelRowsSelect: 'Clienti',
          },
          toolbar: {
            searchPlaceholder: 'Filtra i clienti',
            nRowsSelected: '{0} row(s) selected',
          },
          header: {
            actions: 'Dettaglio',
          },
          body: {
            emptyDataSourceMessage: 'Non ci sono clienti',
            filterRow: {
              filterTooltip: 'Filtro',
            },
          },
        }}
      />
      <Divider />
      <div style={{ marginTop: 24 }}>
        <PartitePopup open={bloccoVisible} onClose={onClose} valori={selected} />
      </div>
    </PageContainer>
  );
};

export default PartiteAperte;
