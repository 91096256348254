import {
  ADD_FORNITORE,
  DELETE_BULK_FORNITORI,
  DELETE_FORNITORE,
  EDIT_FORNITORE,
  GET_FORNITORI,
  SET_FORNITORE_DETAILS,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  users: [],
  currentUser: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FORNITORI: {
      console.log('GET_FORNITORI', action.payload);
      return {
        ...state,
        users: action.payload,
      };
    }
    case SET_FORNITORE_DETAILS: {
      return {
        ...state,
        currentUser: action.payload,
      };
    }
    case ADD_FORNITORE: {
      console.log('AGGIUNGIAMO IL FORNITORE', action.payload);
      return {
        ...state,
        users: [action.payload, ...state.users],
      };
    }
    case EDIT_FORNITORE: {
      console.log('EDITIAMO LO USER', action);
      console.log('STATO FORNITORI', state.users);
      return {
        ...state,
        users: state.users.map(user => (user.uid === action.payload.uid ? action.payload : user)),
      };
    }
    case DELETE_FORNITORE: {
      console.log('CANCELLIAMO LO USER', action.payload);
      console.log('DAGLI  USERS', state.users);
      return {
        ...state,
        users: state.users.filter(user => user.uid !== action.payload),
      };
    }
    case DELETE_BULK_FORNITORI: {
      return {
        ...state,
        users: state.users.filter(user => !action.payload.includes(user.id)),
      };
    }
    default:
      return state;
  }
};
