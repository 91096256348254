import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-hook-form';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from 'styled-components';
import cogoToast from 'cogo-toast';
import { DefaultEditor } from 'react-simple-wysiwyg';
import Moment from 'moment';
import axios from 'axios';
import { TextField, Input } from '@material-ui/core';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Box from '@material-ui/core/Box';
import EmailIcon from '@material-ui/icons/Email';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TodayIcon from '@material-ui/icons/Today';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import ArticoliForm from './articoliForm';
import FornitoriTable from './fornitoriTable';
import allegatiOfferta from './allegatiOfferta';
import moment from 'moment';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import DocumentoPopup from './docPopup';
import { useHistory } from 'react-router';
import {
  MdArrowBack,
  MdDownload,
  MdFileDownload,
  MdDeleteForever,
  MdSave,
  MdArchive,
  MdUnarchive,
  MdStars,
  MdAccessAlarm,
  MdCancel,
} from 'react-icons/md';
import AllegatiOfferta from './allegatiOfferta';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import MyDropzone from './myDropZone';
//import TestPdf from './testPdf';
//import ReactPDF from '@react-pdf/renderer';
//import ReactDOM from 'react-dom';
//import { PDFViewer } from '@react-pdf/renderer';
//import { CSVLink, CSVDownload } from 'react-csv';

const breadcrumbs = [
  { label: <IntlMessages id={'sidebar.main'} />, link: '/' },
  {
    label: <IntlMessages id={'pages.ordini-fornitori'} />,
    link: '/ordini-fornitori-interventi',
  },
  { label: <IntlMessages id={'pages.ordine-fornitore'} />, isActive: true },
];

const Errore = styled.p`
  color: red;
  fontweight: 'bold';
`;

const schema = yup.object().shape({
  name: yup.string().required('Il campo è obbligatorio'),
});

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    width: '100%',
  },
  paperCop: {
    padding: theme.spacing(2),
    color: 'red',
    backgroundColor: '#e6ffff',
  },
  editAsset: {
    flexGrow: 1,
  },
  editFormItem: {},
  myForm: {
    width: '100%',
  },
}));

export default function SimpleDialog(props) {
  const [errore, setErrore] = useState('');
  const [bloccoInvio, setBloccoInvio] = useState(true);
  const [istitutoOpzioni, setIstitutoOpzioni] = useState([]);
  const [state, setState] = useState({
    oid: 0,
    archiviato: '0',
    articoli: [],
    cap: '',
    cliente: props.location.state.cliente || '',
    codfisc: '',
    commissione: '',
    consegna: '',
    data: new Date(),
    data_offerta: new Date(),
    destinazione: '',
    email: '',
    figlio: '4',
    finitura: '',
    fornitori: [],
    fornitoriOpzioni: [],
    fornitore: '0',
    currentFornitore: false,
    currentFornitoreOfferta: props.location.state.fornitore,
    allegati: [],
    indir: '',
    local: '',
    note: '',
    num_commissione: '0',
    num_offerta: '0',
    ordine_da: '',
    pagamento: '',
    parent: props.location.state.offID || 0,
    partiva: '',
    porto: '',
    posa_in_opera: '',
    prov: '',
    ragsoc: '',
    serie: '',
    telefono: '',
    tipo: '0',
    garanzia: '1',
    durata: '',
    ist_vigilanza: '0',
  });

  const [currentDoc, setCurrentDoc] = useState(false);
  const [docVisible, setDocVisible] = useState(false);
  const { authUser } = useSelector(({ auth }) => auth);

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [valueAuto, setValueAuto] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const history = useHistory();
  //const loading = open && options.length === 0;
  const loading = false;

  const user = useSelector(state => {
    //console.log('user', state.auth);
    return state.auth;
  });

  const classes = useStyles();

  const getArticles = articles => {
    console.log('getArticles', articles);
    //setArticoli(oldArray => [...oldArray, article]);
    setState({ ...state, articoli: articles });
  };

  const getFornitori = fornitori => {
    setState({ ...state, fornitori: fornitori });
  };

  const getAllegati = allegati => {
    console.log('getAllegati', allegati);
    setState({ ...state, allegati: allegati });
  };

  const manageDoc = doc => {
    console.log('manageDoc', doc);
    setDocVisible(true);
    setCurrentDoc(doc);
  };

  const handleClose = () => {};

  const handleFornitore = fornitore => {
    console.log('handleFornitore', fornitore);
    setState({
      ...state,
      fornitore: fornitore,
      currentFornitore: state.fornitoriOpzioni.find(f => f.uid === fornitore),
    });
  };

  const handleGaranzia = garanzia => {
    console.log('handleGaranzia', garanzia);
    setState({ ...state, garanzia: garanzia });
  };

  const onCloseDoc = () => {
    setDocVisible(false);
  };

  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(schema),
  });
  const dispatch = useDispatch();

  const salvaSegnal = data => {
    data.interventi = 1;
    console.log('ecco i dati', data);
    if (!state.currentFornitore && !state.ragsoc) {
      cogoToast.error(
        <div>
          <b>ERRORE</b>
          <div>Il fornitore è obbligatorio</div>
        </div>,
      );
      return;
    }
    if (!state.cliente) {
      cogoToast.error(
        <div>
          <b>ERRORE</b>
          <div>Il cliente è obbligatorio</div>
        </div>,
      );
      return;
    }
    let json = fetch('https://laforgiaws.netkom.it/ws/SalvaOrdineFornitore.php', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(risp => {
        console.log('ecco la risposta salvaDoc', risp);
        if (risp.success) {
          history.push('/ordini-fornitori-interventi');
          cogoToast.info(
            <div>
              <b>Salvataggio OK</b>
              <div>Ordine Salvato</div>
            </div>,
          );
        }
      });
  };

  const archiviaOrdine = data => {
    console.log('ecco i dati', data);
    let json = fetch('https://laforgiaws.netkom.it/ws/ArchiviaOrdineFornitore.php', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(risp => {
        console.log('ecco la risposta salvaDoc', risp);
        if (risp.success) {
          history.push('/ordini-fornitori-interventi');
          cogoToast.info(
            <div>
              <b>Salvataggio OK</b>
              <div>Ordine archiviato</div>
            </div>,
          );
        }
      });
  };

  const eliminaSegnal = data => {
    console.log('ecco i dati elimina', data);
    let json = fetch('https://laforgiaws.netkom.it/ws/eliminaOrdineFornitore.php?oid=' + data.oid, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(risp => {
        console.log('ecco la risposta eliminaDoc', risp);
        if (risp.success) {
          history.push('/ordini-fornitori-interventi');
          cogoToast.info(
            <div>
              <b>Eliminazione</b>
              <div>Ordine eliminato</div>
            </div>,
          );
        }
      });
  };

  const onAddArticle = () => {
    console.log('onAddArticle');
  };

  const caricaOrdine = valori => {
    console.log('occhio caricaOrdine', valori);
    setState({ ...state, ...valori });
  };

  const handleIstituto = istituto => {
    console.log('handleIstituto', istituto);
    setState({
      ...state,
      ist_vigilanza: istituto,
    });
  };

  const downloadPdf2 = currentUser => {
    var doc = new jsPDF();
    var totalPagesExp = '{total_pages_count_string}';
    var img = new Image();
    var width = doc.internal.pageSize.getWidth();
    const docHeight = doc.internal.pageSize.height;
    console.log('ecco la larghezza', width);
    console.log("ecco l'altezza", docHeight);
    //   const docWidth = doc.internal.pageSize.width;
    const totalPages = doc.internal.pages.length;
    console.log('ecco il numero di pagine', totalPages);
    img.src = '/images/logo-laf-trasp.png';
    doc.addImage(img, 'jpg', 10, 10, (width / 2) * 0.7, (width / 8) * 0.7);

    doc.setFont('helvetica');
    doc.setFontSize(12);
    let y = 15;
    doc.text('Spett.le', width / 2 + 5, y + 5);
    doc.text(state.ragsoc, width / 2 + 5, y + 10);
    doc.text(state.indir, width / 2 + 5, y + 15);
    let locale = state.local ? state.local + ' ' + state.prov : state.prov;
    doc.text(locale, width / 2 + 5, y + 20);

    doc.text('Alla cortese attenzione', width / 2 + 5, y + 30);
    doc.text('del Sig. ' + state.ordine_da, width / 2 + 5, y + 35);
    //first letter name
    let fln = (state.nomeOwner && state.nomeOwner[0]) || '';
    //first letter surname
    let fls = (state.cognomeOwner && state.cognomeOwner[0]) || '';
    //TODO: leggere da db lo user che ha creato l'ordine
    let stringNC = fln + fls;

    doc.setFontSize(12);
    doc.text('Roma,  ' + moment(state.data_offerta).format('DD/MM/YYYY'), 10, 70);
    doc
      .setFont(undefined, 'bold')
      .text('Ordine n. ' + state.oid + '/' + moment(state.data_offerta).format('YY') + '/LF/' + stringNC, 10, 75)
      .setFont(undefined, 'normal');
    const columns = [
      { title: 'Titolo', field: 'titolo' },
      { title: 'Descrizione', field: 'descrizione' },
      { title: 'Q.tà', field: 'quantita' },
      { title: 'Prezzo', field: 'prezzo' },
      { title: 'Totale', field: 'totale' },
    ];
    const columnTitles = columns.map(columnDef => columnDef.title);
    const pdfData = state.articoli.map(rowData =>
      columns.map(columnDef => {
        console.log(columnDef.field);
        if (columnDef.field === 'prezzo' || columnDef.field === 'totale')
          return parseFloat(rowData[columnDef.field]).toLocaleString('it-IT', {
            style: 'currency',
            currency: 'EUR',
          });

        return rowData[columnDef.field];
      }),
    );
    //console.log({ pdfData });
    let totale = state.articoli.reduce((agg, row) => agg + row.totale, 0);
    pdfData.push(['', '', '', '', '']);
    let iva_string = state.iva_esclusa == '0' || 1 ? ' + IVA' : '';
    totale = parseFloat(totale).toLocaleString('it-IT', {
      style: 'currency',
      currency: 'EUR',
    });
    pdfData.push(['', '', '', 'Totale', totale + iva_string]);
    doc.autoTable({
      head: [columnTitles],
      body: pdfData,
      pageBreak: 'auto',
      showFoot: 'everyPage',
      startY: 100,
      margin: { bottom: 50 },
      didDrawPage: function(data) {
        //qui ci va eventuale logo

        addFooters(doc);
      },
      margin: { bottom: 50 },
    });

    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    let dopoTabella = doc.lastAutoTable.finalY;
    console.log('ecco la posizione dopo la tabella', dopoTabella);
    //lunghezza pagina
    let lp = doc.internal.pageSize.height;
    console.log('ecco la lunghezza della pagina', lp);
    //numero pagina
    let np = doc.internal.getNumberOfPages();
    console.log('ecco il numero di pagine', np);
    //current page
    let cp = doc.internal.getCurrentPageInfo().pageNumber;
    console.log('ecco la pagina corrente', cp);

    if (dopoTabella > 200) {
      doc.addPage();
      dopoTabella = 10;
    }

    doc.setFontSize(12);
    doc.text('Cordiali saluti', 10, dopoTabella + 90);
    doc.setFontSize(15);
    doc
      .setFont(undefined, 'bold')
      .text('La Forgia Srl', 10, dopoTabella + 95)
      .setFont(undefined, 'normal');

    doc.setFontSize(13);
    doc.text('PER ACCETTAZIONE', width / 2 + 30, dopoTabella + 105);
    let pdfName = 'Ordine_' + state.oid + '.pdf';
    if (cp < doc.internal.getCurrentPageInfo().pageNumber) addFooters(doc);
    doc.save(pdfName);
  };

  const addFooters = doc => {
    const pageCount = doc.internal.getNumberOfPages();
    var width = doc.internal.pageSize.getWidth();
    const docHeight = doc.internal.pageSize.height;
    let dopoTabella = doc.autoTableEndPosY();
    //let y = dopoTabella + 120;
    doc.setFont('helvetica', 'italic');
    doc.setFontSize(8);
    for (var i = 1; i <= pageCount; i++) {
      doc.setTextColor(0, 0, 0);
      doc.setPage(i);
      //FOOTER
      doc.setLineWidth(1.0);
      doc.setDrawColor(0, 131, 255);
      doc.line(10, docHeight - 35, width - 10, docHeight - 35);

      doc.setFontSize(10);
      doc
        .setFont(undefined, 'bold')
        .text('Soluzioni Integrate Arredi e Sicurezza', width / 2 - 30, docHeight - 30)
        .setFont(undefined, 'normal');

      doc.setFontSize(10);
      doc.text('La Forgia Srl', width / 2 - 10, docHeight - 25);
      doc.text(
        'Part. I.V.A. 09307651001 - REA 1155405 – Capitale interamente versato € 80.000,00',
        width / 2 - 70,
        docHeight - 20,
      );
      doc.text(
        '06/39730219   06/39637555/6 – tel./fax06/39670207	     Roma-Milano-Napoli    info@laforgia.eu – laforgia@pec.laforgia.eu',
        width / 2 - 100,
        docHeight - 15,
      );
      //set font  color
      doc.setTextColor(0, 131, 255);
      doc.text('www.laforgia.eu – www.laforgiacasseforti.eu', doc.internal.pageSize.width / 2, docHeight - 10, {
        align: 'center',
      });
      doc.setTextColor(0, 0, 0);
      doc.text('Pagina ' + String(i) + ' di ' + String(pageCount), doc.internal.pageSize.width / 2, docHeight - 2, {
        align: 'center',
      });
    }
  };

  React.useEffect(() => {
    if (inputValue.length > 2)
      (async () => {
        const res = await axios.get('https://laforgiaws.netkom.it/ws/getNomiAnagrafiche.php?searchTerm=' + inputValue);
        const aziende = res.data;
        console.log('compiliamo i campi azienda', aziende);
        setOptions(
          aziende.map(key => {
            return {
              name: key.RAGSOC,
              stato: key.CLFR == 'C' ? 'Cliente' : 'Contatto',
              id: key.CODCF,
              partiva: key.PARTIVA,
              codfisc: key.CODFISC,
              indir: key.INDIR,
              cap: key.CAP,
              local: key.LOCAL,
              prov: key.PROV,
              telefono: key.TEL,
              email: key.EMAIL,
            };
          }),
        );
      })();
    if (inputValue.length <= 2) {
      setOptions([]);
    }
  }, [inputValue]);

  useEffect(() => {
    window.scrollTo(0, 0);
    try {
      //take istituto api
      let json = fetch('https://laforgiaws.netkom.it/ws/getInstitutiVigilanza.php', {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(risp => {
          if (risp.success) {
            console.log('qui abbiamo i dati istituti', risp.instituti);
            //we change id to value and name to label
            const istituti = risp.instituti.map(ist => {
              return {
                value: ist.id,
                label: ist.nome,
              };
            });
            setIstitutoOpzioni(istituti);
          }
        });
    } catch (error) {
      console.log('errore', error);
    }
    try {
      let json = fetch('https://laforgiaws.netkom.it/ws/getFornitori.php', {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(risp => {
          console.log('occhio ecco la risposta fornitori', risp);
          risp.unshift({ uid: 0, ragsoc: 'Seleziona un fornitore' });
          setState({ ...state, fornitoriOpzioni: risp });
          try {
            //TODO: qui prendiamo ragsoc e codclid e li mettiamo nei campi invece di prenderli da props.location.state
            if (!props.match.params.oid || props.match.params.oid == 0) {
              if (!props.location.state.offID) return;
              console.log('qui abbiamo offID', props.location.state.offID);
              let json = fetch(
                'https://laforgiaws.netkom.it/ws/getOffertaInterventi.php?oid=' + props.location.state.offID,
                {
                  method: 'get',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                },
              )
                .then(response => response.json())
                .then(risp => {
                  console.log('ecco la risposta salvaDoc', risp);
                  if (risp.success) {
                    console.log('qui abbiamo i dati extra di interventi', risp.offerta);
                    const dati = (({
                      ric_intervento,
                      note_intervento,
                      allegato_intervento,
                      ddt,
                      note_ddt,
                      allegato_ddt,
                      ist_vigilanza,
                      note_vigilanza,
                    }) => ({
                      ric_intervento,
                      note_intervento,
                      allegato_intervento,
                      ddt,
                      note_ddt,
                      allegato_ddt,
                      ist_vigilanza,
                      note_vigilanza,
                    }))(risp.offerta);
                    setState(prevState => ({
                      ...prevState,
                      ...dati,
                    }));
                  }
                });
            } else {
              //qui prendiamo i dati ordine fornitore
              let json = fetch('https://laforgiaws.netkom.it/ws/getOrdineFornitore.php?oid=' + props.match.params.oid, {
                method: 'get',
                headers: {
                  'Content-Type': 'application/json',
                },
              })
                .then(response => response.json())
                .then(risp => {
                  console.log('ecco la risposta salvaDoc', risp);
                  if (risp.success) {
                    //FIXME:se arriva prima questo di riga fornitori (375) allora form vuoto
                    console.log('occhio qui abbiamo i dati ordine', risp.ordine);
                    setState(risp.ordine);
                  }
                });
            }
          } catch (e) {
            console.log(e);
          }
        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  React.useEffect(() => {
    console.log('occhio qui abbiamo cf', state.currentFornitore);
    if (state.currentFornitore) {
      console.log('occhio facciamo qualcosa con currentFornitore', state.currentFornitore);
      setState(prevState => {
        return {
          ...prevState,
          ragsoc: state.currentFornitore.ragsoc,
          codfisc: state.currentFornitore.codfiscale,
          partiva: state.currentFornitore.piva,
          indir: state.currentFornitore.indirizzo,
          cap: state.currentFornitore.cap,
          local: state.currentFornitore.localita,
          prov: state.currentFornitore.prov,
          telefono: state.currentFornitore.telefono,
          email: state.currentFornitore.email,
        };
      });
    }
  }, [state.currentFornitore]);

  React.useEffect(() => {
    if (props.location.state && props.location.state.fornitore) {
      console.log('facciamo qualcosa con location.state.fornitore', props.location.state.fornitore);
      console.log('abbiamo i fornitori', state.fornitoriOpzioni);
      if (props.location.state.fornitore) {
        console.log('occhio popoliamo settiamo currentFornitoreOfferta', props.location.state.fornitore);
        setState({
          ...state,
          currentFornitoreOfferta: props.location.state.fornitore,
        });
      }
    }
  }, [props.location.state]);

  React.useEffect(() => {
    if (state.currentFornitoreOfferta && state.fornitoriOpzioni.length > 0) {
      const currentFornitore = state.fornitoriOpzioni.find(f => f.ragsoc == state.currentFornitoreOfferta);
      console.log('occhio popoliamo settiamo currentFornitore', currentFornitore);
      setState({ ...state, currentFornitore });
    }
  }, [state.fornitoriOpzioni, state.currentFornitoreOfferta]);

  return (
    <PageContainer heading={<IntlMessages id="pages.ordine-fornitore" />} breadcrumbs={breadcrumbs}>
      {/*
      <Button
        variant="contained"
        color="primary"
        onClick={() =>
          ReactDOM.render(
            <PDFViewer>
              <TestPdf />
            </PDFViewer>,
            document.getElementById('root'),
          )
        }>
        primary
      </Button>
      */}
      <div className={classes.editAsset}>
        <div className={classes.root}>
          <Grid container spacing={10}>
            <Grid item xs={9}>
              <MdArrowBack size={30} onClick={() => history.push('/ordini-fornitori-interventi')} />
            </Grid>
            {state.oid != 0 && (
              <Grid item xs={3} alignItems="center" justifyContent="center">
                <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                  <MdDownload size={30} onClick={() => downloadPdf2(authUser)} />
                  <h3>Scarica il pdf</h3>
                </span>
              </Grid>
            )}
            <Grid item xs={8}>
              {state.oid != 0 && (
                <h2>
                  ORDINE {state.oid} del {moment(state.data_ordine).format('DD/MM/YYYY')}
                  {state.archiviato == 1 ? '- ARCHIVIATO' : ''}
                </h2>
              )}
            </Grid>
            <Grid item xs={4} />
            {/*oid {state.oid} cliente {state.cliente} valueAuto {state.valueAuto} iniz {props.location.state.cliente}*/}
            <Grid item xs={12}>
              {(state.cliente == '' || (!props.location.state.cliente && state.oid === 0)) && (
                <Autocomplete
                  autoComplete
                  id="ricerca-cliente"
                  open={open}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  value={valueAuto}
                  onChange={(event, newValue) => {
                    console.log('occhio newValue', newValue);
                    setValueAuto(newValue);
                    if (newValue) {
                      setState({
                        ...state,
                        cliente: newValue.name + ' [' + newValue.id + ']',
                      });
                    }
                  }}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  getOptionSelected={(option, value) => option.name === value.name}
                  getOptionLabel={option => option.name + ' - ' + option.stato}
                  options={options}
                  loading={loading}
                  //copmod se sono tante le filtriamo lato server in questo modo
                  //filterOptions={x => x}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Cerca un cliente"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              )}
              {(props.location.state.cliente || state.oid > 0) && (
                <TextField
                  fullWidth
                  value={state.cliente.split('[')[0]}
                  variant="outlined"
                  onChange={e => setState({ ...state, cliente: e.target.value })}
                  id="cliente"
                  name="cliente"
                  label="Cliente"
                  disabled
                  className="segnalAnag"
                />
              )}
            </Grid>
            {state.oid === 0 && !state.currentFornitore && (
              <Grid item xs={4}>
                <InputLabel id="fornitore-select-label">Fornitore</InputLabel>
                <Select
                  style={{ width: '100%' }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={state.fornitore}
                  label="FORNITORE"
                  onChange={e => handleFornitore(e.target.value)}>
                  {state.fornitoriOpzioni &&
                    state.fornitoriOpzioni.map(key => {
                      return <MenuItem value={key.uid}>{key.ragsoc}</MenuItem>;
                    })}
                </Select>
              </Grid>
            )}
            {(state.oid != 0 || state.currentFornitore) && (
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  value={state.ragsoc}
                  variant="outlined"
                  //onChange={e => setState({ ...state, num_offerta: e.target.value })}
                  id="ragsoc"
                  name="ragsoc"
                  label="Fornitore"
                  className="segnalForn"
                />
              </Grid>
            )}
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={state.parent || ''}
                variant="outlined"
                onChange={e => setState({ ...state, parent: e.target.value })}
                id="numofferta"
                name="numofferta"
                label="Offerta di riferimento"
                className="segnalAnag"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={state.oid == 0 ? 'nuovo' : state.oid}
                variant="outlined"
                //onChange={e => setState({ ...state, num_offerta: e.target.value })}
                id="numordine"
                name="numordine"
                label="Numero ordine"
                disabled={true}
                className="segnalAnag"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={state.codfisc || ''}
                onChange={e => setState({ ...state, codfisc: e.target.value })}
                variant="outlined"
                id="codfisc"
                name="codfisc"
                label="Inserire il codice fiscale"
                className="segnalAnag"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={state.indir || ''}
                variant="outlined"
                onChange={e => setState({ ...state, indir: e.target.value })}
                id="indir"
                name="indir"
                label="Inserire indirizzo"
                className="segnalAnag"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={state.local || ''}
                variant="outlined"
                onChange={e => setState({ ...state, local: e.target.value })}
                id="local"
                name="local"
                label="Inserire la località"
                className="segnalAnag"
              />
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Data ordine"
                  inputFormat="dd/MM/yyyy"
                  value={state.data_offerta}
                  onChange={date => setState({ ...state, data_offerta: date })}
                  renderInput={params => <TextField {...params} />}
                />
              </LocalizationProvider>
              {/* <DatePicker dateFormat={'dd/MM/yyyy'} selected={dataOfferta} onChange={date => setDataOfferta(date)} /> */}
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={state.prov || ''}
                variant="outlined"
                onChange={e => setState({ ...state, prov: e.target.value })}
                id="prov"
                name="prov"
                label="Inserire la provincia"
                className="segnalAnag"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={state.cap || ''}
                variant="outlined"
                onChange={e => setState({ ...state, cap: e.target.value })}
                id="cap"
                name="cap"
                label="Inserire il cap"
                className="segnalAnag"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={state.email || ''}
                variant="outlined"
                onChange={e => setState({ ...state, email: e.target.value })}
                id="email"
                name="email"
                label="Inserire email"
                className="segnalAnag"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={state.telefono || ''}
                variant="outlined"
                onChange={e => setState({ ...state, telefono: e.target.value })}
                id="telefono"
                name="telefono"
                label="Inserire telefono"
                className="segnalAnag"
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel id="demo-simple-select-label">Garanzia di legge</InputLabel>
              <Select
                style={{ width: '100%' }}
                labelId="garanzia-legge"
                id="garanzia-legge"
                value={state.garanzia}
                label="GARANZIA LEGGE"
                onChange={e => handleGaranzia(e.target.value)}>
                <MenuItem value={0}>NO</MenuItem>
                <MenuItem value={1}>SI</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                value={state.consegna}
                variant="outlined"
                onChange={e => setState({ ...state, consegna: e.target.value })}
                id="consegna"
                name="consegna"
                label="Inserire la consegna"
                className="segnalFatturato"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={state.durata}
                variant="outlined"
                onChange={e => setState({ ...state, durata: e.target.value })}
                id="durata"
                name="durata"
                label="Durata in mesi"
                className="segnalDurata"
              />
            </Grid>
            <Grid item xs={4}>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={4}
                placeholder="Richiesta di intervento"
                style={{ width: '100%', fontSize: 17 }}
                value={state.ric_intervento}
                onChange={e => {
                  console.log(e.target.value);
                  setState({ ...state, ric_intervento: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={4}
                placeholder="Note intervento"
                style={{ width: '100%', fontSize: 17 }}
                value={state.note_intervento}
                onChange={e => {
                  console.log(e.target.value);
                  setState({ ...state, note_intervento: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              {state.allegato_intervento && (
                <a href={state.allegato_intervento} target="_blank" rel="noreferrer">
                  File caricato: {state.allegato_intervento.split('/').pop()}
                </a>
              )}
              <MyDropzone
                IDana={1}
                fileUploaded={url => {
                  setState(prevState => {
                    return { ...prevState, allegato_intervento: url };
                  });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel id="ist-select-label">Istituto di vigilanza</InputLabel>
              <Select
                style={{ width: '100%' }}
                labelId="ist-select-label-tipo"
                id="ist-select-tipo"
                value={state.ist_vigilanza}
                label="Istituto di vigilanza"
                onChange={e => handleIstituto(e.target.value)}>
                {istitutoOpzioni &&
                  istitutoOpzioni.map(key => {
                    return <MenuItem value={key.value}>{key.label}</MenuItem>;
                  })}
              </Select>
            </Grid>
            <Grid item xs={8}>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={4}
                placeholder="Note vigilanza"
                style={{ width: '100%', fontSize: 17 }}
                value={state.note_vigilanza}
                onChange={e => {
                  console.log(e.target.value);
                  setState({ ...state, note_vigilanza: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={4}
                placeholder="Note"
                style={{ width: '100%', fontSize: 17 }}
                value={state.note}
                onChange={e => {
                  console.log(e.target.value);
                  setState({ ...state, note: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={4}
                placeholder="Destinazione merce"
                style={{ width: '100%', fontSize: 17 }}
                value={state.destinazione}
                onChange={e => {
                  console.log(e.target.value);
                  setState({ ...state, destinazione: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={4}
                value={state.pagamento}
                placeholder="Pagamento"
                style={{ width: '100%', fontSize: 17 }}
                onChange={e => {
                  console.log(e.target.value);
                  setState({ ...state, pagamento: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ArticoliForm
                onAddArticle={onAddArticle}
                dati={state}
                articoli={state.articoli}
                getArticles={getArticles}
                ordine_id={1}
              />
            </Grid>
            <Grid item xs={2} align="right">
              <IconButton
                style={{ color: 'red' }}
                variant="contained"
                onClick={() => {
                  history.push('/ordini-fornitori-interventi');
                }}>
                <MdCancel />
                Annulla
              </IconButton>
            </Grid>
            <Grid item xs={2} align="right">
              {state.oid != 0 && (
                <IconButton
                  style={{ color: 'red' }}
                  variant="contained"
                  onClick={() => {
                    if (window.confirm('Cancellare?')) {
                      const data = {
                        oid: state.oid,
                        IDuser: localStorage.getItem('user_id'),
                      };
                      eliminaSegnal(data);
                    }
                  }}>
                  <MdDeleteForever />
                  Elimina
                </IconButton>
              )}
            </Grid>
            <Grid item xs={2} align="center">
              {state.archiviato == 0 && (
                <IconButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={() => {
                    salvaSegnal(state);
                  }}>
                  <MdSave />
                  Salva
                </IconButton>
              )}
            </Grid>
            <Grid item xs={4} align="center" />
            {state.archiviato == 0 && state.oid != 0 && (
              <Grid item xs={2} align="center">
                <IconButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={() => {
                    const data = {
                      oid: state.oid,
                      archiviato: 1,
                    };
                    archiviaOrdine(data);
                  }}>
                  <MdArchive />
                  Archivia
                </IconButton>
              </Grid>
            )}
          </Grid>
        </div>
      </div>
      <DocumentoPopup openPopup={docVisible} onClose={onCloseDoc} file={currentDoc} />
    </PageContainer>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  openPopup: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
