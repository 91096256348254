import React, { useState, useEffect } from 'react';
import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import { ArrowRight } from '@material-ui/icons/';
import moment from 'moment';

const Versioni = props => {
  const [ordini, setOrdini] = useState([]);
  const [bloccoVisible, setBloccoVisible] = useState(false);
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    console.log('ecco i docs', ordini);
  }, [ordini]);

  useEffect(() => {
    getDocs();
  }, [props.oid]);

  const onClose = () => {
    setBloccoVisible(false);
    setSelected(false);
  };

  const mostraPopup = row => {
    //TODO: chiudere il popup attivo
    //props.closeParent();
    //setSelected(row);
    props.caricaOrdine(row);
    //setBloccoVisible(true);
  };

  const getDocs = () => {
    const data = { parent: props.oid };
    console.log('data di versioni', data);
    let json = fetch('https://laforgiaws.netkom.it/ws/getVersioniOfferta.php', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(risp => {
        console.log('ecco la risposta versioni', risp);
        if (risp.response) {
          setOrdini(risp.ordini);
        }
      });
  };

  return (
    <Grid container>
      <div style={{ marginTop: 24, marginLeft: '1%' }}>
        <Grid item xs={12}>
          <h3>Revisioni</h3>
          <br />
          {ordini.length > 0 && (
            <TableContainer component={Paper}>
              <Table className="tabella" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left" width="10%">
                      Data creazione
                    </TableCell>
                    <TableCell align="left" width="10%">
                      Data offerta
                    </TableCell>
                    <TableCell align="left" width="5%">
                      Num offerta
                    </TableCell>
                    <TableCell align="left" width="40%">
                      Descrizione
                    </TableCell>
                    <TableCell align="left" width="10%">
                      Num. articoli
                    </TableCell>
                    <TableCell align="left" width="20%">
                      Pagamento
                    </TableCell>
                    <TableCell align="left" width="5%">
                      Dettaglio
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ordini.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="left">{moment(row.data).format('DD/MM/YYYY')}</TableCell>
                      <TableCell align="left">{moment(row.data_offerta).format('DD/MM/YYYY')}</TableCell>
                      <TableCell align="left">{row.vid}</TableCell>
                      <TableCell align="left">{row.articoli[0] ? row.articoli[0].titolo : ''}</TableCell>
                      <TableCell align="left">{row.articoli.length}</TableCell>
                      <TableCell align="left">{row.pagamento}</TableCell>
                      <TableCell align="left">
                        <IconButton variant="contained" color="#000" onClick={() => mostraPopup(row)}>
                          <ArrowRight />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </div>
    </Grid>
  );
};

export default Versioni;
