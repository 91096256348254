import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-hook-form';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from 'styled-components';
import cogoToast from 'cogo-toast';
import { DefaultEditor } from 'react-simple-wysiwyg';
import Moment from 'moment';
import axios from 'axios';
import { TextField, Input } from '@material-ui/core';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Box from '@material-ui/core/Box';
import EmailIcon from '@material-ui/icons/Email';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import DatePicker, { registerLocale } from 'react-datepicker';
import it from 'date-fns/locale/it'; // the locale you want
import 'react-datepicker/dist/react-datepicker.css';
import TodayIcon from '@material-ui/icons/Today';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import ArticoliForm from './articoliForm';
import FornitoriTable from './fornitoriTable';
import allegatiOfferta from './allegatiOfferta';
import moment from 'moment';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import VersioniOfferta from './versioniOfferta';
import DocumentoPopup from './docPopup';
import { useHistory } from 'react-router';
import {
  MdArrowBack,
  MdDownload,
  MdFileDownload,
  MdDeleteForever,
  MdSave,
  MdArchive,
  MdUnarchive,
  MdStars,
  MdAccessAlarm,
  MdCancel,
} from 'react-icons/md';
import AllegatiOfferta from './allegatiOfferta';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { add, forEach } from 'lodash';
import MyDropzone from './myDropZone';
import { isEqual } from 'lodash';
import generaPdf from './generaPdf';
import istituti from '../IstitutiVigilanza/istituti';
//import TestPdf from './testPdf';
//import ReactPDF from '@react-pdf/renderer';
//import ReactDOM from 'react-dom';
//import { PDFViewer } from '@react-pdf/renderer';
//import { CSVLink, CSVDownload } from 'react-csv';

registerLocale('it', it); // register it with the name you want

const breadcrumbs = [
  { label: <IntlMessages id={'sidebar.main'} />, link: '/' },
  { label: 'Preventivi', link: '/offerte-interventi' },
  { label: 'Preventivo', isActive: true },
];

const Errore = styled.p`
  color: red;
  fontweight: 'bold';
`;

const schema = yup.object().shape({
  name: yup.string().required('Il campo è obbligatorio'),
});

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    width: '100%',
  },
  paperCop: {
    padding: theme.spacing(2),
    color: 'red',
    backgroundColor: '#e6ffff',
  },
  editAsset: {
    flexGrow: 1,
  },
  editFormItem: {},
  myForm: {
    width: '100%',
  },
}));

const statoOrdine = [
  { value: '0', label: 'Seleziona' },
  { value: '1', label: 'In fatturazione' },
  { value: '2', label: 'Chiuso' },
  { value: '5', label: 'Attesa foglio di lavoro' },
  { value: '6', label: 'Annullato' },
];

const tipologiaOpzioni = [
  { value: '0', label: 'Seleziona una tipologia' },
  { value: '1', label: 'Intervento' },
  { value: '2', label: 'Manutenzione' },
];

export default function SimpleDialog(props) {
  const [errore, setErrore] = useState('');
  const [bloccoInvio, setBloccoInvio] = useState(true);
  const [istitutoOpzioni, setIstitutoOpzioni] = useState([]);
  const defaultVal = {
    oid: 0,
    archiviato: '0',
    articoli: [],
    cap: '',
    codfisc: '',
    commissione: '',
    consegna: '',
    data: new Date(),
    data_offerta: new Date(),
    data_inizio: null,
    data_fine: null,
    data_manutenzione: null,
    destinazione: '',
    email: '',
    figlio: '4',
    finitura: '',
    fornitori: [],
    allegati: [],
    indir: '',
    local: '',
    note: '',
    num_commissione: '0',
    num_offerta: '0',
    ordine_da: '',
    pagamento: '',
    iva: '0',
    parent: '0',
    partiva: '',
    porto: '',
    posa_in_opera: '',
    prov: '',
    ragsoc: '',
    codcf: '',
    serie: '',
    telefono: '',
    tipo: '0',
    tipologia: '0',
    stato_ordine: '0',
    ist_vigilanza: '0',
    foglio_lavoro: '',
    costo_ft: '',
  };
  const [state, setState] = useState(defaultVal);
  const [currentDoc, setCurrentDoc] = useState(false);
  const [docVisible, setDocVisible] = useState(false);
  const { authUser } = useSelector(({ auth }) => auth);

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [valueAuto, setValueAuto] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const history = useHistory();
  //const loading = open && options.length === 0;
  const loading = false;

  const user = useSelector(state => {
    //console.log('user', state.auth);
    return state.auth;
  });

  const classes = useStyles();

  const getArticles = articles => {
    console.log('getArticles', articles);
    //setArticoli(oldArray => [...oldArray, article]);
    setState({ ...state, articoli: articles });
  };

  const getFornitori = fornitori => {
    setState({ ...state, fornitori: fornitori });
  };

  const getAllegati = allegati => {
    console.log('getAllegati', allegati);
    setState({ ...state, allegati: allegati });
  };

  const manageDoc = doc => {
    console.log('manageDoc', doc);
    setDocVisible(true);
    setCurrentDoc(doc);
  };

  const handleClose = () => {};

  const onCloseDoc = () => {
    setDocVisible(false);
  };

  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(schema),
  });
  const dispatch = useDispatch();

  const salvaSegnal = data => {
    data.uid = authUser.userID;
    //console.log('ecco i dati', data);
    if (!state.ragsoc) {
      cogoToast.error(
        <div>
          <b>ERRORE</b>
          <div>Il cliente è obbligatorio</div>
        </div>,
      );
      return;
    }
    if (data.tipo == 1) {
      let json = fetch('https://laforgiaws.netkom.it/ws/SalvaOrdineInterventi.php', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(risp => {
          console.log('ecco la risposta salvaDoc', risp);
          if (risp.success) {
            //TODO: qui il redirect a ordini
            history.push('/ordini-interventi');
            cogoToast.info(
              <div>
                <b>Salvataggio OK</b>
                <div>Ordine Salvato</div>
              </div>,
            );
          }
        });
    } else {
      let json = fetch('https://laforgiaws.netkom.it/ws/SalvaOffertaInterventi.php', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(risp => {
          console.log('ecco la risposta salvaDoc', risp);
          if (risp.success) {
            history.push('/offerte-interventi');
            cogoToast.info(
              <div>
                <b>Salvataggio OK</b>
                <div>Preventivo salvato</div>
              </div>,
            );
          }
        });
    }
  };

  const archiviaOfferta = data => {
    console.log('ecco i dati', data);
    let json = fetch('https://laforgiaws.netkom.it/ws/ArchiviaOffertaInterventi.php', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(risp => {
        console.log('ecco la risposta salvaDoc', risp);
        if (risp.success) {
          history.push('/offerte-interventi');
          cogoToast.info(
            <div>
              <b>Salvataggio OK</b>
              <div>Ordine archiviato</div>
            </div>,
          );
        }
      });
  };

  const ripristinaOfferta = data => {
    console.log('ecco i dati', data);
    let json = fetch('https://laforgiaws.netkom.it/ws/RipristinaOffertaInterventi.php', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(risp => {
        console.log('ecco la risposta salvaDoc', risp);
        if (risp.success) {
          //props.getDocs();
          history.push('/offerte-interventi');
          //props.getDocs();
          cogoToast.info(
            <div>
              <b>Ripristino OK</b>
              <div>Offerta Ripristinata</div>
            </div>,
          );
        }
      });
  };

  const versionaOfferta = data => {
    console.log('ecco i dati da versionare', data);
    let json = fetch('https://laforgiaws.netkom.it/ws/VersionaOffertaInterventi.php', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(risp => {
        console.log('ecco la risposta versiona offerta', risp);
        if (risp.success) {
          //props.getDocs();
          history.push('/offerte-interventi');
          cogoToast.info(
            <div>
              <b>Versione OK</b>
              <div>Offerta attivata</div>
            </div>,
          );
        }
      });
  };

  const eliminaSegnal = data => {
    console.log('ecco i dati elimina', data);
    let json = fetch('https://laforgiaws.netkom.it/ws/eliminaOrdineInterventi.php?oid=' + data.oid, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(risp => {
        console.log('ecco la risposta eliminaDoc', risp);
        if (risp.success) {
          history.push('/offerte-interventi');
          cogoToast.info(
            <div>
              <b>Eliminazione</b>
              <div>Ordine eliminato</div>
            </div>,
          );
        }
      });
  };

  const onAddArticle = () => {
    console.log('onAddArticle');
  };

  const caricaOrdine = valori => {
    if (valori.data_inizio == '1970-01-01 01:00:00') valori.data_inizio = null;
    else valori.data_inizio = moment(valori.data_inizio, 'YYYY-MM-DD hh:mm').toDate();
    if (valori.data_fine == '1970-01-01 01:00:00') valori.data_fine = null;
    else valori.data_fine = moment(valori.data_fine, 'YYYY-MM-DD hh:mm').toDate();
    if (valori.data_manutenzione == '1970-01-01') valori.data_manutenzione = null;
    else valori.data_manutenzione = moment(valori.data_manutenzione, 'YYYY-MM-DD').toDate();
    setState({ ...state, ...valori });
  };

  const handleStatoOrdine = stato => {
    console.log('handleStatto', stato);
    setState({
      ...state,
      stato_ordine: stato,
    });
  };

  const handleTipologia = tipologia => {
    console.log('handleTipologia', tipologia);
    setState({
      ...state,
      tipologia: tipologia,
      //currentTipologia: state.tipologiaOpzioni.find(f => f.value === tipologia),
    });
  };

  const handleIstituto = istituto => {
    console.log('handleIstituto', istituto);
    setState({
      ...state,
      ist_vigilanza: istituto,
      istituto_nome: istitutoOpzioni.find(f => f.value === istituto).label,
    });
  };

  useEffect(() => {
    try {
      //take istituto api
      let json = fetch('https://laforgiaws.netkom.it/ws/getInstitutiVigilanza.php', {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(risp => {
          if (risp.success) {
            console.log('qui abbiamo i dati istituti', risp.instituti);
            //we change id to value and name to label
            const istituti = risp.instituti.map(ist => {
              return {
                value: ist.id,
                label: ist.nome,
              };
            });
            setIstitutoOpzioni(istituti);
          }
        });
      //qui prendiamo i dati dalla offerta
      if (!props.match.params.oid || props.match.params.oid == 0) return;
      json = fetch('https://laforgiaws.netkom.it/ws/getOffertaInterventi.php?oid=' + props.match.params.oid, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(risp => {
          console.log('ecco la risposta salvaDoc', risp);
          if (risp.success) {
            if (risp.offerta.data_inizio == '1970-01-01 01:00:00') risp.offerta.data_inizio = null;
            else risp.offerta.data_inizio = moment(risp.offerta.data_inizio, 'YYYY-MM-DD hh:mm').toDate();
            if (risp.offerta.data_fine == '1970-01-01 01:00:00') risp.offerta.data_fine = null;
            else risp.offerta.data_fine = moment(risp.offerta.data_fine, 'YYYY-MM-DD hh:mm').toDate();
            if (risp.offerta.data_manutenzione == '1970-01-01') risp.offerta.data_manutenzione = null;
            else risp.offerta.data_manutenzione = moment(risp.offerta.data_manutenzione, 'YYYY-MM-DD').toDate();
            /*
          risp.offerta.data_inizio = new Date();
          risp.offerta.data_fine = new Date();
          risp.offerta.data_manutenzione = new Date();
          */
            setState(prevState => {
              return {
                ...prevState,
                ...risp.offerta,
              };
            });
          }
        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  React.useEffect(() => {
    if (inputValue.length > 2)
      (async () => {
        const res = await axios.get('https://laforgiaws.netkom.it/ws/getNomiAnagrafiche.php?searchTerm=' + inputValue);
        const aziende = res.data;
        console.log('compiliamo i campi azienda', aziende);
        setOptions(
          aziende.map(key => {
            return {
              name: key.RAGSOC,
              stato: key.CLFR == 'C' ? 'Cliente' : 'Contatto',
              id: key.CODCF,
              partiva: key.PARTIVA,
              codfisc: key.CODFISC,
              indir: key.INDIR,
              cap: key.CAP,
              local: key.LOCAL,
              prov: key.PROV,
              telefono: key.TEL,
              email: key.EMAIL,
            };
          }),
        );
      })();
    if (inputValue.length <= 2) {
      setOptions([]);
    }
  }, [inputValue]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  React.useEffect(() => {
    if (state.ist_vigilanza == 0) return;
    if (istitutoOpzioni.length == 0) return;
    if (istitutoOpzioni.find(f => f.value === state.ist_vigilanza) == undefined) return;
    console.log('qui cop', istitutoOpzioni, state.ist_vigilanza);
    console.log('qui cop vif', state.ist_vigilanza);
    setState(prevState => {
      return {
        ...prevState,
        istituto_nome: istitutoOpzioni.find(f => f.value === state.ist_vigilanza).label,
      };
    });
  }, [istitutoOpzioni, state.ist_vigilanza]);

  React.useEffect(() => {
    if (valueAuto != null) {
      console.log('facciamo qualcosa con value', valueAuto);
      setState(prevState => {
        return {
          ...prevState,
          ragsoc: valueAuto.name,
          codcf: valueAuto.id,
          codfisc: valueAuto.codfisc,
          partiva: valueAuto.partiva,
          indir: valueAuto.indir,
          cap: valueAuto.cap,
          local: valueAuto.local,
          prov: valueAuto.prov,
          telefono: valueAuto.telefono,
          email: valueAuto.email,
        };
      });
    }
  }, [valueAuto]);

  return (
    <PageContainer heading={'Intervento o Manutenzione'} breadcrumbs={breadcrumbs}>
      {/*
      <Button
        variant="contained"
        color="primary"
        onClick={() =>
          ReactDOM.render(
            <PDFViewer>
              <TestPdf />
            </PDFViewer>,
            document.getElementById('root'),
          )
        }>
        primary
      </Button>
      */}
      <div className={classes.editAsset}>
        <div style={{ backgroundColor: '#FFE4E4' }}>
          <div className={classes.root}>
            <Grid container spacing={10}>
              <Grid item xs={9}>
                <MdArrowBack size={30} onClick={() => history.push('/offerte-interventi')} />
                {/*state.oid != 0 && (
                <VersioniOfferta oid={state.oid} closeParent={() => handleClose()} caricaOrdine={caricaOrdine} />
              )*/}
              </Grid>
              {state.oid != 0 && (
                <Grid item xs={3} alignItems="center" justifyContent="center">
                  <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <MdDownload size={30} onClick={() => generaPdf(state, authUser)} />
                    <h3>Scarica il pdf</h3>
                  </span>
                </Grid>
              )}
              <Grid item xs={6}>
                {state.oid != 0 && (
                  <h2>
                    OFFERTA {state.ragsoc} {state.figlio && 0 ? '- Revisionata da ver. ' + state.figlio : ''}{' '}
                    {state.archiviato == 1 ? '- ARCHIVIATA' : ''}
                  </h2>
                )}
                {!state.oid && (
                  <Autocomplete
                    autoComplete
                    id="ricerca-cliente"
                    open={open}
                    onOpen={() => {
                      setOpen(true);
                    }}
                    onClose={() => {
                      setOpen(false);
                    }}
                    value={valueAuto}
                    onChange={(event, newValue) => {
                      setValueAuto(newValue);
                    }}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    getOptionSelected={(option, value) => option.name === value.name}
                    getOptionLabel={option => option.name + ' - ' + option.stato}
                    options={options}
                    loading={loading}
                    //copmod se sono tante le filtriamo lato server in questo modo
                    //filterOptions={x => x}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Cerca un cliente"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={3}>
                <InputLabel id="stato-select-label">Stato</InputLabel>
                <Select
                  style={{ width: '100%' }}
                  labelId="simple-select-label-stato"
                  id="simple-select-stato"
                  value={state.stato_ordine}
                  label="Stato"
                  onChange={e => handleStatoOrdine(e.target.value)}>
                  {statoOrdine &&
                    statoOrdine.map(key => {
                      return <MenuItem value={key.value}>{key.label}</MenuItem>;
                    })}
                </Select>
              </Grid>
              <Grid item xs={3}>
                <InputLabel id="tipologia-select-label">Tipologia</InputLabel>
                <Select
                  style={{ width: '100%' }}
                  labelId="simple-select-label-tipo"
                  id="simple-select-tipo"
                  value={state.tipologia}
                  label="Tipologia"
                  onChange={e => handleTipologia(e.target.value)}>
                  {tipologiaOpzioni &&
                    tipologiaOpzioni.map(key => {
                      return <MenuItem value={key.value}>{key.label}</MenuItem>;
                    })}
                </Select>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  value={state.partiva}
                  variant="outlined"
                  onChange={e => setState({ ...state, partiva: e.target.value })}
                  id="partiva"
                  name="partiva"
                  label="Inserire la partita iva"
                  className="segnalAnag"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  value={state.codfisc}
                  variant="outlined"
                  onChange={e => setState({ ...state, codfisc: e.target.value })}
                  id="codfisc"
                  name="codfisc"
                  label="Inserire il codice fiscale"
                  className="segnalAnag"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  value={state.vid ? state.vid : state.oid ? state.oid + '/23' : ''}
                  variant="outlined"
                  //onChange={e => setState({ ...state, num_offerta: e.target.value })}
                  id="numofferta"
                  name="numofferta"
                  label="Numero offerta"
                  disabled={true}
                  className="segnalAnag"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  value={state.indir}
                  variant="outlined"
                  onChange={e => setState({ ...state, indir: e.target.value })}
                  id="indir"
                  name="indir"
                  label="Inserire indirizzo"
                  className="segnalAnag"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  value={state.local}
                  variant="outlined"
                  onChange={e => setState({ ...state, local: e.target.value })}
                  id="local"
                  name="local"
                  label="Inserire la località"
                  className="segnalAnag"
                />
              </Grid>
              <Grid item xs={4}>
                Data/ora inizio intervento
                <DatePicker
                  locale={'it'}
                  name="startDate"
                  selected={state.data_inizio}
                  onChange={date => setState({ ...state, data_inizio: date })}
                  renderInput={params => <TextField {...params} />}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  dateFormat="MMMM d, yyyy H:mm"
                />
                {/* <DatePicker dateFormat={'dd/MM/yyyy'} selected={dataOfferta} onChange={date => setDataOfferta(date)} /> */}
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  value={state.prov}
                  variant="outlined"
                  onChange={e => setState({ ...state, prov: e.target.value })}
                  id="prov"
                  name="prov"
                  label="Inserire la provincia"
                  className="segnalAnag"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  value={state.cap}
                  variant="outlined"
                  onChange={e => setState({ ...state, cap: e.target.value })}
                  id="cap"
                  name="cap"
                  label="Inserire il cap"
                  className="segnalAnag"
                />
              </Grid>
              <Grid item xs={4}>
                Data/ora fine intervento
                <DatePicker
                  locale={'it'}
                  name="endDate"
                  selected={state.data_fine}
                  onChange={date => setState({ ...state, data_fine: date })}
                  renderInput={params => <TextField {...params} />}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  dateFormat="MMMM d, yyyy H:mm"
                />
                {/* <DatePicker dateFormat={'dd/MM/yyyy'} selected={dataOfferta} onChange={date => setDataOfferta(date)} /> */}
              </Grid>

              <Grid item xs={4}>
                <TextField
                  fullWidth
                  value={state.email}
                  variant="outlined"
                  onChange={e => setState({ ...state, email: e.target.value })}
                  id="email"
                  name="email"
                  label="Inserire email"
                  className="segnalAnag"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  value={state.telefono}
                  variant="outlined"
                  onChange={e => setState({ ...state, telefono: e.target.value })}
                  id="telefono"
                  name="telefono"
                  label="Inserire telefono"
                  className="segnalAnag"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  value={state.ordine_da}
                  variant="outlined"
                  onChange={e => setState({ ...state, ordine_da: e.target.value })}
                  id="ordineDa"
                  name="ordineDa"
                  label="Ordine da"
                  className="segnalAnag"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  value={state.foglio_lavoro}
                  variant="outlined"
                  onChange={e => setState({ ...state, foglio_lavoro: e.target.value })}
                  id="fl"
                  name="foglio_lavoro"
                  label="Foglio di Lavoro Num."
                  className="segnalFatturato"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  value={state.costo_ft}
                  variant="outlined"
                  onChange={e => setState({ ...state, costo_ft: e.target.value })}
                  id="costo_ft"
                  name="costo_ft"
                  label="Costo Fornitore / Tecnico"
                  className="segnalFatturato"
                />
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  value={state.consegna}
                  variant="outlined"
                  onChange={e => setState({ ...state, consegna: e.target.value })}
                  id="consegna"
                  name="consegna"
                  label="Tempi di consegna"
                  className="segnalFatturato"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  value={state.porto}
                  variant="outlined"
                  onChange={e => setState({ ...state, porto: e.target.value })}
                  id="porto"
                  name="Porto"
                  label="Validità offerta"
                  className="segnalFatturato"
                />
              </Grid>
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Scadenza manutenzione"
                    inputFormat="dd/MM/yyyy"
                    value={state.data_manutenzione}
                    onChange={date => setState({ ...state, data_manutenzione: date })}
                    renderInput={params => <TextField {...params} />}
                  />
                </LocalizationProvider>
                {/* <DatePicker dateFormat={'dd/MM/yyyy'} selected={dataOfferta} onChange={date => setDataOfferta(date)} /> */}
              </Grid>

              <Grid item xs={4}>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={4}
                  placeholder="Richiesta di intervento"
                  style={{ width: '100%', fontSize: 17 }}
                  value={state.ric_intervento}
                  onChange={e => {
                    console.log(e.target.value);
                    setState({ ...state, ric_intervento: e.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={4}
                  placeholder="Note intervento"
                  style={{ width: '100%', fontSize: 17 }}
                  value={state.note_intervento}
                  onChange={e => {
                    console.log(e.target.value);
                    setState({ ...state, note_intervento: e.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                {state.allegato_intervento && (
                  <a href={state.allegato_intervento} target="_blank" rel="noreferrer">
                    File caricato: {state.allegato_intervento.split('/').pop()}
                  </a>
                )}
                <MyDropzone
                  IDana={1}
                  fileUploaded={url => {
                    setState(prevState => {
                      return { ...prevState, allegato_intervento: url };
                    });
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel id="ist-select-label">Istituto di vigilanza</InputLabel>
                <Select
                  style={{ width: '100%' }}
                  labelId="ist-select-label-tipo"
                  id="ist-select-tipo"
                  value={state.ist_vigilanza}
                  label="Istituto di vigilanza"
                  onChange={e => handleIstituto(e.target.value)}>
                  {istitutoOpzioni &&
                    istitutoOpzioni.map(key => {
                      return <MenuItem value={key.value}>{key.label}</MenuItem>;
                    })}
                </Select>
                {/*
              <TextareaAutosize
                aria-label="minimum height"
                minRows={4}
                placeholder="Istituto vigilanza (eventuale)"
                style={{ width: '100%', fontSize: 17 }}
                value={state.ist_vigilanza}
                onChange={e => {
                  console.log(e.target.value);
                  setState({ ...state, ist_vigilanza: e.target.value });
                }}
              />
              */}
              </Grid>
              <Grid item xs={8}>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={4}
                  placeholder="Note vigilanza"
                  style={{ width: '100%', fontSize: 17 }}
                  value={state.note_vigilanza}
                  onChange={e => {
                    console.log(e.target.value);
                    setState({ ...state, note_vigilanza: e.target.value });
                  }}
                />
              </Grid>
              {/*
            <Grid item xs={4}>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={4}
                placeholder="DDT"
                style={{ width: '100%', fontSize: 17 }}
                value={state.ddt}
                onChange={e => {
                  console.log(e.target.value);
                  setState({ ...state, ddt: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={4}
                placeholder="Note ddt"
                style={{ width: '100%', fontSize: 17 }}
                value={state.note_ddt}
                onChange={e => {
                  console.log(e.target.value);
                  setState({ ...state, note_ddt: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              {state.allegato_ddt && (
                <a href={state.allegato_ddt} target="_blank" rel="noreferrer">
                  File caricato: {state.allegato_ddt.split('/').pop()}
                </a>
              )}
              <MyDropzone
                IDana={1}
                fileUploaded={url => {
                  setState(prevState => {
                    return { ...prevState, allegato_ddt: url };
                  });
                }}
              />
            </Grid>
            */}
              <Grid item xs={12}>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={4}
                  placeholder="Condizioni di vendita"
                  style={{ width: '100%', fontSize: 17 }}
                  value={state.note}
                  onChange={e => {
                    console.log(e.target.value);
                    setState({ ...state, note: e.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={4}
                  placeholder="Luogo di intervento/manutenzione"
                  style={{ width: '100%', fontSize: 17 }}
                  value={state.destinazione}
                  onChange={e => {
                    console.log(e.target.value);
                    setState({ ...state, destinazione: e.target.value });
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={4}
                  value={state.pagamento}
                  placeholder="Pagamento"
                  style={{ width: '100%', fontSize: 17 }}
                  onChange={e => {
                    console.log(e.target.value);
                    setState({ ...state, pagamento: e.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={state.iva}
                  style={{ width: '100%', fontSize: 17 }}
                  variant="outlined"
                  onChange={e => setState({ ...state, iva: e.target.value })}
                  id="iva"
                  name="iva"
                  label="Inserire iva"
                  className="segnalAnag"
                />
              </Grid>
              <Grid item xs={12}>
                <ArticoliForm
                  onAddArticle={onAddArticle}
                  dati={state}
                  articoli={state.articoli}
                  getArticles={getArticles}
                  ordine_id={1}
                />
              </Grid>
              <Grid item xs={12}>
                <FornitoriTable
                  datiOrdine={state}
                  oid={state.oid}
                  iva={state.iva}
                  cliente={state.ragsoc || ''}
                  clienteID={state.codcf}
                  fornitori={state.fornitori || []}
                  setFornitori={getFornitori}
                  setDocVisible={manageDoc}
                  doc={currentDoc}
                />
              </Grid>
              <Grid item xs={12}>
                <AllegatiOfferta
                  oid={state.oid}
                  allegati={state.allegati || []}
                  setAllegati={getAllegati}
                  setDocVisible={manageDoc}
                  doc={currentDoc}
                />
              </Grid>
              <Grid item xs={2} align="right">
                <IconButton
                  style={{ color: 'red' }}
                  variant="contained"
                  onClick={() => {
                    history.push('/offerte-interventi');
                  }}>
                  <MdCancel />
                  Annulla
                </IconButton>
              </Grid>
              <Grid item xs={2} align="right">
                {state.parent == 0 && state.oid != 0 && (
                  <IconButton
                    style={{ color: 'red' }}
                    variant="contained"
                    onClick={() => {
                      if (window.confirm('Cancellare?')) {
                        const data = {
                          oid: state.oid,
                          IDuser: localStorage.getItem('user_id'),
                        };
                        eliminaSegnal(data);
                      }
                    }}>
                    <MdDeleteForever />
                    Elimina
                  </IconButton>
                )}
              </Grid>
              <Grid item xs={2} align="center">
                {state.archiviato == 0 && state.parent == 0 && (
                  <IconButton
                    disabled={isEqual(defaultVal, state)}
                    variant="contained"
                    color="secondary"
                    type="submit"
                    onClick={() => {
                      salvaSegnal(state);
                    }}>
                    <MdSave />
                    Salva
                  </IconButton>
                )}
                {state.archiviato == 1 && state.parent == 0 && (
                  <IconButton
                    variant="contained"
                    color="secondary"
                    type="submit"
                    onClick={() => {
                      const data = {
                        oid: state.oid,
                        archiviato: 1,
                      };
                      ripristinaOfferta(data);
                    }}>
                    <MdUnarchive />
                    Ripristina
                  </IconButton>
                )}
                {state.parent != 0 && (
                  <IconButton
                    variant="contained"
                    color="secondary"
                    type="submit"
                    onClick={() => {
                      const data = {
                        oid: state.oid,
                      };
                      versionaOfferta(data);
                    }}>
                    <MdAccessAlarm />
                    Attiva versione
                  </IconButton>
                )}
              </Grid>
              {/*
            <Grid item xs={4} align="center">
              {state.archiviato == 0 && state.parent == 0 && (
                <IconButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={() => {
                    const data = {
                      ...state,
                      oid: '',
                      tipo: 1,
                      parent: state.oid,
                    };
                    salvaSegnal(data);
                  }}>
                  <MdStars />
                  Completa
                </IconButton>
              )}
            </Grid>
            */}
              {state.archiviato == 0 && state.parent == 0 && state.oid != 0 && (
                <Grid item xs={2} align="center">
                  <IconButton
                    variant="contained"
                    color="secondary"
                    type="submit"
                    onClick={() => {
                      const data = {
                        oid: state.oid,
                        archiviato: 1,
                      };
                      archiviaOfferta(data);
                    }}>
                    <MdArchive />
                    Archivia
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </div>
        </div>
      </div>
      <DocumentoPopup openPopup={docVisible} onClose={onCloseDoc} file={currentDoc} />
    </PageContainer>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  openPopup: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
