import React, { useEffect, useState } from 'react';
import { API_URL } from '../../../@jumbo/constants/AppConstants';
import { useSelector, useDispatch } from 'react-redux';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-hook-form';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from 'styled-components';
import cogoToast from 'cogo-toast';
import { DefaultEditor } from 'react-simple-wysiwyg';
import Moment from 'moment';
import axios from 'axios';
import { TextField, Input } from '@material-ui/core';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Box from '@material-ui/core/Box';
import EmailIcon from '@material-ui/icons/Email';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TodayIcon from '@material-ui/icons/Today';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import ArticoliForm from './articoliForm';
import FornitoriTable from './fornitoriTable';
import moment from 'moment';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import VersioniOrdine from './versioniOrdine';
import DocumentoPopup from './docPopup';
import { useHistory } from 'react-router';
import { MdArrowBack, MdDownload, MdFileDownload, MdDeleteForever, MdSave, MdArchive, MdUnarchive } from 'react-icons/md';

import AllegatiOrdine from './allegatiOrdine';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Checkbox from '@mui/material/Checkbox';
import generaPdf from './generaPdf';
import ConfirmDialog from '../../../@jumbo/components/Common/ConfirmDialog';

const breadcrumbs = [
  { label: <IntlMessages id={'sidebar.main'} />, link: '/' },
  { label: <IntlMessages id={'pages.ordini'} />, link: '/ordini' },
  { label: <IntlMessages id={'pages.ordine'} />, isActive: true },
];

const Errore = styled.p`
  color: red;
  fontweight: 'bold';
`;

const schema = yup.object().shape({
  name: yup.string().required('Il campo è obbligatorio'),
});

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    width: '100%',
  },
  paperCop: {
    padding: theme.spacing(2),
    color: 'red',
    backgroundColor: '#e6ffff',
  },
  editAsset: {
    flexGrow: 1,
  },
  editFormItem: {},
  myForm: {
    width: '100%',
  },
}));

export default function SimpleDialog(props) {
  const [errore, setErrore] = useState('');
  const [bloccoInvio, setBloccoInvio] = useState(true);

  const [state, setState] = useState({
    oid: 0,
    archiviato: '0',
    articoli: [],
    cap: '',
    codfisc: '',
    commissione: '',
    consegna: '',
    data: new Date(),
    data_offerta: '',
    destinazione: '',
    email: '',
    figlio: '4',
    finitura: '',
    fornitori: [],
    allegati: [],
    indir: '',
    local: '',
    note: '',
    num_commissione: '0',
    num_offerta: '0',
    ordine_da: '',
    pagamento: '',
    iva: '0',
    parent: '0',
    partiva: '',
    porto: '',
    posa_in_opera: '',
    prov: '',
    ragsoc: '',
    serie: '',
    telefono: '',
    tipo: '0',
  });

  const [currentDoc, setCurrentDoc] = useState(false);
  const [docVisible, setDocVisible] = useState(false);
  const { authUser } = useSelector(({ auth }) => auth);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [valueAuto, setValueAuto] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const history = useHistory();
  //const loading = open && options.length === 0;
  const loading = false;

  const user = useSelector(state => {
    //console.log('user', state.auth);
    return state.auth;
  });

  const classes = useStyles();

  const getArticles = articles => {
    console.log('getArticles', articles);
    //setArticoli(oldArray => [...oldArray, article]);
    setState({ ...state, articoli: articles });
  };

  const getFornitori = fornitori => {
    console.log('getFornitori', fornitori);
    setState({ ...state, fornitori: fornitori });
  };

  const getAllegati = allegati => {
    console.log('getAllegati', allegati);
    setState({ ...state, allegati: allegati });
  };

  const manageDoc = doc => {
    console.log('manageDoc', doc);
    setDocVisible(true);
    setCurrentDoc(doc);
  };

  const handleClose = () => {};

  const onCloseDoc = () => {
    setDocVisible(false);
  };

  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(schema),
  });
  const dispatch = useDispatch();

  const salvaSegnal = data => {
    console.log('ecco i dati', data);
    if (!state.ragsoc) {
      cogoToast.error(
        <div>
          <b>ERRORE</b>
          <div>Il cliente è obbligatorio</div>
        </div>,
      );
      return;
    }

    if (data.tipo == 1) {
      let json = fetch(API_URL + '/SalvaOrdine.php', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(risp => {
          console.log('ecco la risposta salvaDoc', risp);
          if (risp.success) {
            //TODO: qui il redirect a ordini
            history.push('/ordini');
            cogoToast.info(
              <div>
                <b>Salvataggio OK</b>
                <div>Ordine Salvato</div>
              </div>,
            );
          }
        });
    } else {
      let json = fetch(API_URL + '/SalvaOrdine.php', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(risp => {
          console.log('ecco la risposta salvaDoc', risp);
          if (risp.success) {
            history.push('/ordini');
            cogoToast.info(
              <div>
                <b>Salvataggio OK</b>
                <div>Ordine Salvato</div>
              </div>,
            );
          }
        });
    }
  };

  const archiviaOrdine = data => {
    console.log('ecco i dati', data);
    let json = fetch(API_URL + '/ArchiviaOrdine.php', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(risp => {
        console.log('ecco la risposta salvaDoc', risp);
        if (risp.success) {
          history.push('/ordini');
          cogoToast.info(
            <div>
              <b>Salvataggio OK</b>
              <div>Ordine archiviato</div>
            </div>,
          );
        }
      });
  };

  const ripristinaOrdine = data => {
    console.log('ecco i dati', data);
    let json = fetch(API_URL + '/RipristinaOrdine.php', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(risp => {
        console.log('ecco la risposta salvaDoc', risp);
        if (risp.success) {
          //props.getDocs();
          history.push('/ordini');
          //props.getDocs();
          cogoToast.info(
            <div>
              <b>Ripristino OK</b>
              <div>Ordine Ripristinata</div>
            </div>,
          );
        }
      });
  };

  const versionaOrdine = data => {
    console.log('ecco i dati da versionare', data);
    let json = fetch(API_URL + '/VersionaOrdine.php', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(risp => {
        console.log('ecco la risposta versiona ordine', risp);
        if (risp.success) {
          //props.getDocs();
          cogoToast.info(
            <div>
              <b>Versione OK</b>
              <div>Ordine attivata</div>
            </div>,
          );
        }
      });
  };

  const eliminaSegnal = data => {
    console.log('ecco i dati elimina', data);
    let json = fetch(API_URL + '/eliminaOrdine.php?oid=' + data.oid, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(risp => {
        console.log('ecco la risposta eliminaDoc', risp);
        if (risp.success) {
          history.push('/ordini');
          cogoToast.info(
            <div>
              <b>Eliminazione</b>
              <div>Ordine eliminato</div>
            </div>,
          );
        }
      });
  };

  const onAddArticle = () => {
    console.log('onAddArticle');
  };

  const caricaOrdine = valori => {
    console.log(valori);
    setState({ ...state, ...valori });
  };

  useEffect(() => {
    try {
      //qui prendiamo i dati dalla ordine
      if (!props.match.params.oid || props.match.params.oid == 0) return;
      let json = fetch(API_URL + '/getOrdine.php?oid=' + props.match.params.oid, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(risp => {
          console.log('ecco la risposta salvaDoc', risp);
          if (risp.success) {
            console.log('qui abbiamo i dati ordine', risp.ordine);
            setState(risp.ordine);
          }
        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  React.useEffect(() => {
    if (inputValue.length > 2)
      (async () => {
        const res = await axios.get(API_URL + '/getNomiAnagrafiche.php?searchTerm=' + inputValue);
        const aziende = res.data;
        console.log('compiliamo i campi azienda', aziende);
        setOptions(
          aziende.map(key => {
            return {
              name: key.RAGSOC,
              id: key.CODCF,
              partiva: key.PARTIVA,
              codfisc: key.CODFISC,
              indir: key.INDIR,
              cap: key.CAP,
              local: key.LOCAL,
              prov: key.PROV,
              telefono: key.TEL,
              email: key.EMAIL,
            };
          }),
        );
      })();
    if (inputValue.length <= 2) {
      setOptions([]);
    }
  }, [inputValue]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  React.useEffect(() => {
    if (valueAuto != null) {
      console.log('facciamo qualcosa con value', valueAuto);
      setState(prevState => {
        return {
          ...prevState,
          ragsoc: valueAuto.name,
          codfisc: valueAuto.codfisc,
          partiva: valueAuto.partiva,
          indir: valueAuto.indir,
          cap: valueAuto.cap,
          local: valueAuto.local,
          prov: valueAuto.prov,
          telefono: valueAuto.telefono,
          email: valueAuto.email,
        };
      });
    }
  }, [valueAuto]);

  return (
    <PageContainer heading={<IntlMessages id="pages.ordine" />} breadcrumbs={breadcrumbs}>
      {/*
      <Button
        variant="contained"
        color="primary"
        onClick={() =>
          ReactDOM.render(
            <PDFViewer>
              <TestPdf />
            </PDFViewer>,
            document.getElementById('root'),
          )
        }>
        primary
      </Button>
      */}
      <div className={classes.editAsset}>
        <div style={{ backgroundColor: '#cceef6' }}>
          <div className={classes.root}>
            <Grid container spacing={10}>
              <Grid item xs={9}>
                <MdArrowBack size={30} onClick={() => history.push('/ordini')} />
              </Grid>
              <Grid item xs={3} alignItems="center" justifyContent="center">
                <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                  <MdDownload size={30} onClick={() => generaPdf(state, authUser)} />
                  <h3>Scarica il pdf</h3>
                </span>
              </Grid>
              <Grid item xs={12}>
                <VersioniOrdine oid={state.oid} closeParent={() => handleClose()} caricaOrdine={caricaOrdine} />
              </Grid>
              <Grid item xs={8}>
                {state.oid && (
                  <h2>
                    CONFERMA ORDINE {state.ragsoc} {state.figlio ? '- Ver. ' + state.figlio : ''}{' '}
                    {state.archiviato == 1 ? '- Archiviata' : ''}
                  </h2>
                )}
                <Autocomplete
                  autoComplete
                  id="ricerca-cliente"
                  open={open}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  value={valueAuto}
                  onChange={(event, newValue) => {
                    setValueAuto(newValue);
                  }}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  getOptionSelected={(option, value) => option.name === value.name}
                  getOptionLabel={option => option.name}
                  options={options}
                  loading={loading}
                  //copmod se sono tante le filtriamo lato server in questo modo
                  //filterOptions={x => x}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Cerca un cliente"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  value={state.partiva}
                  variant="outlined"
                  onChange={e => setState({ ...state, partiva: e.target.value })}
                  id="partiva"
                  name="partiva"
                  label="Inserire la partita iva"
                  className="segnalAnag"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  value={state.codfisc}
                  variant="outlined"
                  onChange={e => setState({ ...state, codfisc: e.target.value })}
                  id="codfisc"
                  name="codfisc"
                  label="Inserire il codice fiscale"
                  className="segnalAnag"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  value={state.vid ? state.vid : state.oid + '/23'}
                  variant="outlined"
                  //onChange={e => setState({ ...state, num_ordine: e.target.value })}
                  id="numordine"
                  name="numordine"
                  label="Numero ordine"
                  disabled={true}
                  className="segnalAnag"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  value={state.indir}
                  variant="outlined"
                  onChange={e => setState({ ...state, indir: e.target.value })}
                  id="indir"
                  name="indir"
                  label="Inserire indirizzo"
                  className="segnalAnag"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  value={state.local}
                  variant="outlined"
                  onChange={e => setState({ ...state, local: e.target.value })}
                  id="local"
                  name="local"
                  label="Inserire la località"
                  className="segnalAnag"
                />
              </Grid>
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Data ordine"
                    inputFormat="dd/MM/yyyy"
                    value={state.data_offerta}
                    onChange={date => setState({ ...state, data_offerta: date })}
                    renderInput={params => <TextField {...params} />}
                  />
                </LocalizationProvider>
                {/* <DatePicker dateFormat={'dd/MM/yyyy'} selected={dataOrdine} onChange={date => setDataOrdine(date)} /> */}
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  value={state.prov}
                  variant="outlined"
                  onChange={e => setState({ ...state, prov: e.target.value })}
                  id="prov"
                  name="prov"
                  label="Inserire la provincia"
                  className="segnalAnag"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  value={state.cap}
                  variant="outlined"
                  onChange={e => setState({ ...state, cap: e.target.value })}
                  id="cap"
                  name="cap"
                  label="Inserire il cap"
                  className="segnalAnag"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  value={state.email}
                  variant="outlined"
                  onChange={e => setState({ ...state, email: e.target.value })}
                  id="email"
                  name="email"
                  label="Inserire email"
                  className="segnalAnag"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  value={state.telefono}
                  variant="outlined"
                  onChange={e => setState({ ...state, telefono: e.target.value })}
                  id="telefono"
                  name="telefono"
                  label="Inserire telefono"
                  className="segnalAnag"
                />
              </Grid>
              {/*
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={state.ordine_da}
                variant="outlined"
                onChange={e => setState({ ...state, ordine_da: e.target.value })}
                id="ordineDa"
                name="ordineDa"
                label="Ordine da"
                className="segnalAnag"
              />
            </Grid>
            */}
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  value={state.consegna}
                  variant="outlined"
                  onChange={e => setState({ ...state, consegna: e.target.value })}
                  id="consegna"
                  name="consegna"
                  label="Tempi di consegna"
                  className="segnalFatturato"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  value={state.porto}
                  variant="outlined"
                  onChange={e => setState({ ...state, porto: e.target.value })}
                  id="porto"
                  name="Porto"
                  label="Validità offerta"
                  className="segnalFatturato"
                />
              </Grid>
              {/*
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={state.posa_in_opera}
                variant="outlined"
                onChange={e => setState({ ...state, posa_in_opera: e.target.value })}
                id="posainopera"
                name="posainopera"
                label="Inserire la posa in opera"
                className="segnalFatturato"
              />
            </Grid>
            */}
              <Grid item xs={12}>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={4}
                  placeholder="Condizioni di vendita"
                  style={{ width: '100%', fontSize: 17 }}
                  value={state.condizioni}
                  onChange={e => {
                    console.log(e.target.value);
                    setState({ ...state, condizioni: e.target.value });
                  }}
                />
              </Grid>
              {/*
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={state.finitura}
                variant="outlined"
                onChange={e => setState({ ...state, finitura: e.target.value })}
                id="finitura"
                name="finitura"
                label="Inserire la finitura"
                className="segnalFatturato"
              />
            </Grid>
            */}
              <Grid item xs={12}>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={4}
                  placeholder="Indirizzo di consegna"
                  style={{ width: '100%', fontSize: 17 }}
                  value={state.destinazione}
                  onChange={e => {
                    console.log(e.target.value);
                    setState({ ...state, destinazione: e.target.value });
                  }}
                />
              </Grid>
              {/*
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={state.serie}
                variant="outlined"
                onChange={e => setState({ ...state, serie: e.target.value })}
                id="serie"
                name="serie"
                label="Inserire la serie"
                className="segnalFatturato"
              />
            </Grid>
            */}
              <Grid item xs={12}>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={4}
                  value={state.pagamento}
                  placeholder="Pagamento"
                  style={{ width: '100%', fontSize: 17 }}
                  onChange={e => {
                    console.log(e.target.value);
                    setState({ ...state, pagamento: e.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={4}
                  value={state.note}
                  placeholder="Note"
                  style={{ width: '100%', fontSize: 17 }}
                  onChange={e => {
                    setState({ ...state, note: e.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={state.iva}
                  style={{ width: '100%', fontSize: 17 }}
                  variant="outlined"
                  onChange={e => setState({ ...state, iva: e.target.value })}
                  id="iva"
                  name="iva"
                  label="Inserire iva"
                  className="segnalAnag"
                />
              </Grid>
              {/*
            <Grid item xs={12}>
              <span>NON MOSTRARE TOTALE ARTICOLI</span>
              <Checkbox
                checked={state.totale_escluso == '1' ? true : false || false}
                onChange={e => {
                  setState({
                    ...state,
                    totale_escluso: state.totale_escluso == '0' ? '1' : '0',
                  });
                }}
              />
            </Grid>
              */}
              <Grid item xs={12}>
                <ArticoliForm
                  iva={state.iva}
                  onAddArticle={onAddArticle}
                  dati={state}
                  articoli={state.articoli}
                  getArticles={getArticles}
                  ordine_id={1}
                />
              </Grid>
              <Grid item xs={12}>
                <FornitoriTable
                  datiOrdine={state}
                  oid={state.oid}
                  iva={state.iva}
                  cliente={state.ragsoc || ''}
                  clienteID={state.codcf}
                  fornitori={state.fornitori || []}
                  setFornitori={getFornitori}
                  setDocVisible={manageDoc}
                  doc={currentDoc}
                />
              </Grid>
              <Grid item xs={12}>
                <AllegatiOrdine
                  oid={state.oid}
                  allegati={state.allegati || []}
                  setAllegati={getAllegati}
                  setDocVisible={manageDoc}
                  doc={currentDoc}
                />
              </Grid>

              <Grid item xs={2} align="right">
                {state.oid != 0 && (
                  <IconButton
                    style={{ color: 'red' }}
                    variant="contain"
                    onClick={() => {
                      if (window.confirm('Cancellare?')) {
                        const data = {
                          oid: state.oid,
                          IDuser: localStorage.getItem('user_id'),
                        };
                        eliminaSegnal(data);
                      }
                    }}>
                    <MdDeleteForever />
                    Elimina
                  </IconButton>
                )}
              </Grid>
              <Grid item xs={2} align="center">
                {state.archiviato == 0 && (
                  <IconButton
                    variant="contained"
                    color="secondary"
                    type="submit"
                    onClick={() => {
                      setOpenConfirmDialog(true);
                    }}>
                    <MdSave />
                    Salva
                  </IconButton>
                )}
              </Grid>
              <Grid item xs={2} align="center">
                {state.archiviato == 1 && (
                  <IconButton
                    variant="contained"
                    color="secondary"
                    type="submit"
                    onClick={() => {
                      const data = {
                        oid: state.oid,
                        archiviato: 0,
                      };
                      ripristinaOrdine(data);
                    }}>
                    <MdUnarchive />
                    Ripristina
                  </IconButton>
                )}
                {state.archiviato == 0 && state.oid != 0 && (
                  <IconButton
                    variant="contained"
                    color="secondary"
                    type="submit"
                    onClick={() => {
                      const data = {
                        oid: state.oid,
                        archiviato: 1,
                      };
                      archiviaOrdine(data);
                    }}>
                    <MdArchive />
                    Archivia
                  </IconButton>
                )}
              </Grid>
              <Grid item xs={4} align="center" />
            </Grid>
          </div>
        </div>
      </div>
      <DocumentoPopup openPopup={docVisible} onClose={onCloseDoc} file={currentDoc} />
      <ConfirmDialog
        open={openConfirmDialog}
        title={'Salvataggio'}
        content={'Hai fatto modifiche?'}
        onClose={() => {
          console.log('senza modifiche');
          history.push('/ordini');
        }}
        onConfirm={() => salvaSegnal(state)}
        btnLabels={{ confirm: 'Si', cancel: 'No' }}
      />
    </PageContainer>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  openPopup: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
