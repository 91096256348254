import React from 'react';
import FileViewer from 'react-file-viewer';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const file = './Case-Study-Shell.pdf';
const type = 'pdf';

const onError = e => {
  console.log(e, 'error in file-viewer');
};

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default function DocViewer(props) {
  const classes = useStyles();
  const { onClose, selectedValue, openPopup, file } = props;
  console.log('file', file);
  let type = '';
  if (file) type = file.split('.').pop();
  console.log('type', type);
  const handleClose = () => {
    onClose(selectedValue);
  };
  return (
    <Dialog
      maxWidth={'lg'}
      fullWidth={true}
      fullScreen={false}
      onClose={handleClose}
      aria-labelledby="edit-asset-dialog"
      open={openPopup}>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
      <div className="App">
        <h2>Estensione {type}</h2>
        <FileViewer fileType={type} filePath={file} onError={onError} />
      </div>
    </Dialog>
  );
}
