import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '../../../../@jumbo/components/Common/formElements/AppTextInput';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';
import CmtList from '../../../../@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import AppSelectBox from '../../../../@jumbo/components/Common/formElements/AppSelectBox';
import { emailNotValid, requiredMessage } from '../../../../@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import { isValidEmail } from '../../../../@jumbo/utils/commonHelper';
import { addNewCustomer, updateCustomer } from '../../../../redux/actions/Customers';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { set } from 'lodash';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
}));

function PhoneNumberInput({ onChange, value, ...other }) {
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    if (!phoneNumber && value) {
      setTimeout(() => {
        setPhoneNumber(value);
      }, 300);
    }
  }, [phoneNumber, value]);

  const onNumberChange = number => {
    setPhoneNumber(number.formattedValue);
    onChange(number.formattedValue);
  };

  return <NumberFormat {...other} onValueChange={onNumberChange} value={phoneNumber} />;
}

const ruoli = [
  { title: 'Admin', slug: '1' },
  { title: 'Utente', slug: '1' },
  { title: 'Altro', slug: '1' },
];

const labels = [
  { title: 'Casa', slug: 'casa' },
  { title: 'Ufficio', slug: 'ufficio' },
  { title: 'Cellulare', slug: 'cellulare' },
];

const splitName = user => {
  if (user) {
    console.log({ user });
    return [user.nome, user.cognome];
  }

  return ['', ''];
};

const AddEditUser = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const currentCustomer = useSelector(({ customersReducer }) => customersReducer.currentCustomer);

  const [uid, setUid] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [profile_pic, setProfile_pic] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [company, setCompany] = useState('');
  const [ruolo, setRuolo] = useState('2');
  const [phones, setPhones] = useState([{ phone: '', label: 'cellulare' }]);

  const [ragSocError, setRagSocError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  //campi fornitore aggiuntivi
  const [ragsoc, setRagsoc] = useState('');
  const [partiva, setPartiva] = useState('');
  const [codfisc, setCodfisc] = useState('');
  const [indirizzo, setIndirizzo] = useState('');
  const [localita, setLocalita] = useState('');
  const [prov, setProv] = useState('');
  const [cap, setCap] = useState('');
  const [referente, setReferente] = useState('');
  const [telefono, setTelefono] = useState('');
  const [settore, setSettore] = useState('');

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setProfile_pic(URL.createObjectURL(acceptedFiles[0]));
    },
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentCustomer) {
      const [fName, lName] = splitName(currentCustomer);
      setFirstName(fName);
      setLastName(lName);
      setUid(currentCustomer.uid);
      setProfile_pic(currentCustomer.profile_pic);
      setEmail(currentCustomer.email);
      setUsername(currentCustomer.username);
      setCompany(currentCustomer.company);
      setRuolo(currentCustomer.livello);
      //setPhones(currentCustomer.phones);
      setTelefono(currentCustomer.telefono);
      setRagsoc(currentCustomer.ragsoc);
      setPartiva(currentCustomer.partiva);
      setCodfisc(currentCustomer.codfisc);
      setIndirizzo(currentCustomer.indirizzo);
      setLocalita(currentCustomer.localita);
      setProv(currentCustomer.prov);
      setCap(currentCustomer.cap);
      setReferente(currentCustomer.referente);
      setSettore(currentCustomer.settore);
    }
  }, [currentCustomer]);

  const onPhoneNoAdd = (number, index) => {
    const updatedList = [...phones];
    updatedList[index].phone = number;
    setPhones(updatedList);
    setPhoneError('');
  };

  const onPhoneRowRemove = index => {
    const updatedList = [...phones];
    updatedList.splice(index, 1);
    setPhones(updatedList);
  };

  const handleChange = valore => {
    const val = valore.target.value;
    setRuolo(val);
  };

  const onPhoneRowAdd = () => {
    setPhones(phones.concat({ phone: '', label: 'cellulare' }));
  };

  const onLabelChange = (value, index) => {
    const updatedList = [...phones];
    updatedList[index].label = value;
    setPhones(updatedList);
  };

  const onSubmitClick = () => {
    const phoneNumbers = phones.filter(item => item.phone.trim());
    if (!ragsoc) {
      setRagSocError(requiredMessage);
    } else {
      /* 
    else if (!email) {
      setEmailError(requiredMessage);
    } else if (!isValidEmail(email)) {
      setEmailError(emailNotValid);
    } else if (phoneNumbers.length === 0) {
      setPhoneError(requiredMessage);
    } 
    */
      onUserSave(phoneNumbers);
    }
  };

  const onUserSave = phoneNumbers => {
    const userDetail = {
      uid,
      profile_pic,
      nome: firstName,
      cognome: lastName,
      email,
      //phones: phoneNumbers,
      telefono: telefono,
      username,
      password,
      ruolo,
      company,
      //campi fornitore aggiuntivi
      ragsoc,
      partiva,
      codfisc,
      indirizzo,
      localita,
      prov,
      cap,
      referente,
      settore,
    };

    const updatedUser = { ...currentCustomer, ...userDetail };
    console.log('il contatto', updatedUser);

    if (currentCustomer) {
      dispatch(
        updateCustomer(updatedUser, () => {
          onCloseDialog();
        }),
      );
    } else {
      dispatch(
        addNewCustomer(userDetail, () => {
          onCloseDialog();
        }),
      );
    }
  };

  //const isPhonesMultiple = phones.length > 1;

  return (
    <Dialog disableBackdropClick open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>
        {currentCustomer ? 'Modifica contatto' : 'Crea un nuovo contatto'}
      </DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
          {/*
          <Box {...getRootProps()} mr={{ xs: 0, md: 5 }} mb={{ xs: 3, md: 0 }} className="pointer">
            <input {...getInputProps()} />
            <CmtAvatar size={70} src={profile_pic} />
          </Box>
        */}
          <GridContainer>
            <Grid item xs={12} sm={6}>
              <AppTextInput
                fullWidth
                variant="outlined"
                label="Ragione sociale"
                value={ragsoc}
                onChange={e => {
                  setRagsoc(e.target.value);
                  setRagSocError('');
                }}
                helperText={ragSocError}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AppTextInput
                fullWidth
                variant="outlined"
                label="Partita IVA"
                value={partiva}
                onChange={e => setPartiva(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <AppTextInput
                fullWidth
                variant="outlined"
                label="Codice fiscale"
                value={codfisc}
                onChange={e => setCodfisc(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <AppTextInput
                fullWidth
                variant="outlined"
                label="Settore merceologico"
                value={settore}
                onChange={e => setSettore(e.target.value)}
              />
            </Grid>
          </GridContainer>
        </Box>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            fullWidth
            variant="outlined"
            label="Email Address"
            value={email}
            onChange={e => {
              setEmail(e.target.value);
              setEmailError('');
            }}
            helperText={emailError}
          />
        </Box>
        <GridContainer style={{ marginBottom: 2 }}>
          <Grid item xs={12} sm={6}>
            <AppTextInput
              fullWidth
              variant="outlined"
              label="Telefono"
              value={telefono}
              onChange={e => setTelefono(e.target.value)}
              helperText={phoneError}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppTextInput
              fullWidth
              variant="outlined"
              label="Referente"
              value={referente}
              onChange={e => setReferente(e.target.value)}
            />
          </Grid>
        </GridContainer>
        <Box mb={{ xs: 6, md: 5 }} display="inline-flex" alignItems="center" className="pointer" />
        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={indirizzo} sm={7}>
            <AppTextInput
              fullWidth
              variant="outlined"
              label="Indirizzo"
              value={indirizzo}
              onChange={e => setIndirizzo(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <AppTextInput
              fullWidth
              variant="outlined"
              label="Località"
              value={localita}
              onChange={e => setLocalita(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={7}>
            <AppTextInput
              fullWidth
              variant="outlined"
              label="Provincia"
              value={prov}
              onChange={e => setProv(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <AppTextInput fullWidth variant="outlined" label="CAP" value={cap} onChange={e => setCap(e.target.value)} />
          </Grid>
        </GridContainer>
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={onCloseDialog}>Annulla</Button>
          <Box ml={2}>
            <Button variant="contained" color="primary" onClick={onSubmitClick}>
              Salva
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
