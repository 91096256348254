// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import fetch from 'cross-fetch';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';

function sleep(delay = 0) {
  return new Promise(resolve => {
    setTimeout(resolve, delay);
  });
}

export default function Asynchronous() {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  //const loading = open && options.length === 0;
  const loading = false;

  React.useEffect(() => {
    if (inputValue.length > 2)
      (async () => {
        const res = await axios.get('/clienti');
        const aziende = res.data;
        console.log('mappo le aziende', aziende);
        setOptions(
          aziende.map(key => {
            return { name: key.name, id: key.id };
          }),
        );
      })();
    if (inputValue.length <= 2) {
      setOptions([]);
    }
  }, [inputValue]);

  React.useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    (async () => {
      const res = await axios.get('/clienti');
      //await sleep(1e3); // For demo purposes.
      //const aziende = await response.json();
      const aziende = res.data;
      if (active) {
        console.log('mappo le aziende', aziende);
        //setOptions(Object.keys(aziende).map(key => aziende[key].item[0]));
        setOptions(
          aziende.map(key => {
            return { name: key.name, id: key.id };
          }),
        );
      }
    })();
    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  React.useEffect(() => {
    console.log('facciamo qualcosa con value', value);
  }, [value]);

  return (
    <Autocomplete
      autoComplete
      id="ricerca-cliente"
      style={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={option => option.name}
      options={options}
      loading={loading}
      //copmod se sono tante le filtriamo lato server in questo modo
      //filterOptions={x => x}
      renderInput={params => (
        <TextField
          {...params}
          label="Cerca un cliente"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
