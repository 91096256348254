import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-hook-form';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from 'styled-components';
import cogoToast from 'cogo-toast';
import { DefaultEditor } from 'react-simple-wysiwyg';
import axios from 'axios';
import { TextField, Input } from '@material-ui/core';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Box from '@material-ui/core/Box';
import EmailIcon from '@material-ui/icons/Email';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import DatePicker, { registerLocale } from 'react-datepicker';
import it from 'date-fns/locale/it'; // the locale you want
import 'react-datepicker/dist/react-datepicker.css';
import TodayIcon from '@material-ui/icons/Today';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import ArticoliForm from './articoliForm';
//import FornitoriTable from './fornitoriTable';
import moment from 'moment';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import VersioniOrdine from './versioniOrdine';
import DocumentoPopup from './docPopup';
import { useHistory } from 'react-router';
import { MdArrowBack, MdDownload, MdFileDownload, MdDeleteForever, MdSave, MdArchive, MdUnarchive } from 'react-icons/md';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import MyDropzone from './myDropZone';

registerLocale('it', it); // register it with the name you want

const breadcrumbs = [
  { label: <IntlMessages id={'sidebar.main'} />, link: '/' },
  { label: <IntlMessages id={'pages.ordini'} />, link: '/ordini' },
  { label: <IntlMessages id={'pages.ordine'} />, isActive: true },
];

const Errore = styled.p`
  color: red;
  fontweight: 'bold';
`;

const schema = yup.object().shape({
  name: yup.string().required('Il campo è obbligatorio'),
});

const tipologiaOpzioni = [
  { value: '0', label: 'Seleziona una tipologia' },
  { value: '1', label: 'Intervento' },
  { value: '2', label: 'Manutenzione' },
];

const statoOrdine = [
  { value: '0', label: 'Seleziona' },
  { value: '1', label: 'In fatturazione' },
  { value: '2', label: 'Chiuso' },
  { value: '5', label: 'Attesa foglio di lavoro' },
  { value: '6', label: 'Annullato' },
];

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    width: '100%',
  },
  paperCop: {
    padding: theme.spacing(2),
    color: 'red',
    backgroundColor: '#e6ffff',
  },
  editAsset: {
    flexGrow: 1,
  },
  editFormItem: {},
  myForm: {
    width: '100%',
  },
}));

export default function SimpleDialog(props) {
  const [errore, setErrore] = useState('');
  const [bloccoInvio, setBloccoInvio] = useState(true);

  const [state, setState] = useState({
    oid: 0,
    archiviato: '0',
    articoli: [],
    cap: '',
    codfisc: '',
    commissione: '',
    consegna: '',
    data: new Date(),
    data_inizio: new Date(),
    data_fine: new Date(),
    data_offerta: '',
    destinazione: '',
    email: '',
    figlio: '',
    finitura: '',
    fornitori: [],
    indir: '',
    local: '',
    note: '',
    num_commissione: '0',
    num_offerta: '0',
    ordine_da: '',
    pagamento: '',
    parent: '0',
    partiva: '',
    porto: '',
    posa_in_opera: '',
    prov: '',
    ragsoc: '',
    serie: '',
    telefono: '',
    tipo: '0',
    tipologia: '0',
    stato_ordine: '0',
    ist_vigilanza: '0',
  });

  const [currentDoc, setCurrentDoc] = useState(false);

  const [docVisible, setDocVisible] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [valueAuto, setValueAuto] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const history = useHistory();
  //const loading = open && options.length === 0;
  const loading = false;

  const user = useSelector(state => {
    //console.log('user', state.auth);
    return state.auth;
  });

  const classes = useStyles();

  const getArticles = articles => {
    console.log('getArticles', articles);
    //setArticoli(oldArray => [...oldArray, article]);
    setState({ ...state, articoli: articles });
  };

  const getFornitori = fornitori => {
    console.log('getFornitori', fornitori);
    setState({ ...state, fornitori: fornitori });
  };

  const handleTipologia = tipologia => {
    console.log('handleTipologia', tipologia);
    setState({
      ...state,
      tipologia: tipologia,
      //currentTipologia: state.tipologiaOpzioni.find(f => f.value === tipologia),
    });
  };

  const handleStatoOrdine = stato => {
    console.log('handleStatto', stato);
    setState({
      ...state,
      stato_ordine: stato,
    });
  };

  const manageDoc = doc => {
    console.log('manageDoc', doc);
    setDocVisible(true);
    setCurrentDoc(doc);
  };

  const handleClose = () => {};

  const onCloseDoc = () => {
    setDocVisible(false);
  };

  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(schema),
  });
  const dispatch = useDispatch();

  const salvaSegnal = data => {
    console.log('ecco i dati', data);
    if (!state.ragsoc) {
      cogoToast.error(
        <div>
          <b>ERRORE</b>
          <div>Il cliente è obbligatorio</div>
        </div>,
      );
      return;
    }
    if (data.tipo == 1) {
      let json = fetch('https://laforgiaws.netkom.it/ws/SalvaOrdineInterventi.php', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(risp => {
          console.log('ecco la risposta salvaDoc', risp);
          if (risp.success) {
            //TODO: qui il redirect a ordini
            history.push('/ordini-interventi');
            cogoToast.info(
              <div>
                <b>Salvataggio OK</b>
                <div>Ordine Salvato</div>
              </div>,
            );
          }
        });
    } else {
      let json = fetch('https://laforgiaws.netkom.it/ws/SalvaOrdineInterventi.php', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(risp => {
          console.log('ecco la risposta salvaDoc', risp);
          if (risp.success) {
            history.push('/ordini-interventi');
            cogoToast.info(
              <div>
                <b>Salvataggio OK</b>
                <div>Ordine Salvato</div>
              </div>,
            );
          }
        });
    }
  };

  const archiviaOrdine = data => {
    console.log('ecco i dati', data);
    let json = fetch('https://laforgiaws.netkom.it/ws/ArchiviaOrdineInterventi.php', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(risp => {
        console.log('ecco la risposta salvaDoc', risp);
        if (risp.success) {
          history.push('/ordini-interventi');
          cogoToast.info(
            <div>
              <b>Salvataggio OK</b>
              <div>Ordine archiviato</div>
            </div>,
          );
        }
      });
  };

  const ripristinaOrdine = data => {
    console.log('ecco i dati', data);
    let json = fetch('https://laforgiaws.netkom.it/ws/RipristinaOrdineInterventi.php', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(risp => {
        console.log('ecco la risposta salvaDoc', risp);
        if (risp.success) {
          //props.getDocs();
          history.push('/ordini-interventi');
          //props.getDocs();
          cogoToast.info(
            <div>
              <b>Ripristino OK</b>
              <div>Ordine Ripristinata</div>
            </div>,
          );
        }
      });
  };

  const versionaOrdine = data => {
    console.log('ecco i dati da versionare', data);
    let json = fetch('https://laforgiaws.netkom.it/ws/VersionaOrdineInterventi.php', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(risp => {
        console.log('ecco la risposta versiona ordine', risp);
        if (risp.success) {
          //props.getDocs();
          cogoToast.info(
            <div>
              <b>Versione OK</b>
              <div>Ordine attivata</div>
            </div>,
          );
        }
      });
  };

  const eliminaSegnal = data => {
    console.log('ecco i dati elimina', data);
    let json = fetch('https://laforgiaws.netkom.it/ws/eliminaOrdineInterventi.php?oid=' + data.oid, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(risp => {
        console.log('ecco la risposta eliminaDoc', risp);
        if (risp.success) {
          history.push('/ordini-interventi');
          cogoToast.info(
            <div>
              <b>Eliminazione</b>
              <div>Ordine eliminato</div>
            </div>,
          );
        }
      });
  };

  const onAddArticle = () => {
    console.log('onAddArticle');
  };

  const caricaOrdine = valori => {
    console.log(valori);
    setState({ ...state, ...valori });
  };

  const downloadPdf = () => {
    const doc = new jsPDF();
    var img = new Image();
    var width = doc.internal.pageSize.getWidth();
    img.src = '/images/logo-laf-trasp.png';
    doc.addImage(img, 'jpg', 0, 3, width / 2, width / 8);
    doc.setFont('helvetica');
    doc.setFontSize(9);
    doc.text('La Forgia S.R.L.', width / 2 + 50, 5);
    doc.text('P. IVA 09307651001', width / 2 + 50, 10);
    doc.text('Via Petronio Arbitro 4 - 00136 Roma', width / 2 + 50, 15);
    doc.text('06 - 39670207', width / 2 + 50, 20);
    doc.text('laforgia@pec.laforgia.eu', width / 2 + 50, 25);
    doc.setFontSize(20);
    doc.text("Conferma d'ordine", 10, 60);
    doc.setFontSize(12);
    doc.text('Ordine num: ' + state.oid, 10, 65);
    doc.text('Data: ' + moment(state.data_offerta).format('DD/MM/YYYY'), 10, 70);

    doc.text('Tipo: ' + tipologiaOpzioni.find(x => x.value === state.tipologia).label, 10, 75);
    doc.text('Stato: ' + statoOrdine.find(x => x.value === state.stato_ordine).label, 10, 80);

    doc.text('Data inizio intervento:  ' + moment(state.data_inizio).format('DD/MM/YYYY hh:mm'), 10, 85);
    doc.text(`Data fine intervento:  ${moment(state.data_fine).format('DD/MM/YYYY hh:mm')}`, 10, 90);

    //TODO:aggiungere gli altro campi che vengono visualizzati nel dettaglio ordine intervento

    doc.text(state.ragsoc, 10, 105);
    doc.text(state.indir, 10, 110);
    doc.text(state.local, 10, 115);
    doc.text(state.prov, 30, 115);
    doc.text(state.email, 10, 120);
    if (state.telefono) doc.text(state.telefono, 10, 125);

    const columns = [
      { title: 'Titolo', field: 'titolo' },
      { title: 'Descrizione', field: 'descrizione' },
      { title: 'Q.tà', field: 'quantita' },
      { title: 'Prezzo', field: 'prezzo' },
      { title: 'Totale', field: 'totale' },
    ];
    const columnTitles = columns.map(columnDef => columnDef.title);
    const pdfData = state.articoli.map(rowData => columns.map(columnDef => rowData[columnDef.field]));
    const totale = state.articoli.reduce((agg, row) => agg + row.totale, 0);
    pdfData.push(['', '', '', 'Totale', totale]);
    doc.autoTable({
      head: [columnTitles],
      body: pdfData,
      startY: 130,
    });

    doc.save(`ordine_intervento.pdf`);
  };

  useEffect(() => {
    try {
      //qui prendiamo i dati dalla ordine
      if (!props.match.params.oid || props.match.params.oid == 0) return;
      let json = fetch('https://laforgiaws.netkom.it/ws/getOrdineInterventi.php?oid=' + props.match.params.oid, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(risp => {
          console.log('ecco la risposta salvaDoc', risp);
          if (risp.success) {
            risp.ordine.data_inizio = moment(risp.ordine.data_inizio, 'YYYY-MM-DD hh:mm').toDate();
            risp.ordine.data_fine = moment(risp.ordine.data_fine, 'YYYY-MM-DD hh:mm').toDate();
            /*
            risp.ordine.data_inizio = new Date(risp.ordine.data_inizio);
            risp.ordine.data_inizio.setTime(
              risp.ordine.data_inizio.getTime() + risp.ordine.data_inizio.getTimezoneOffset() * 60 * 1000,
            );
            risp.ordine.data_fine = new Date(risp.ordine.data_fine);
            risp.ordine.data_fine.setTime(
              risp.ordine.data_fine.getTime() + risp.ordine.data_fine.getTimezoneOffset() * 60 * 1000,
            );
              */
            console.log('qui abbiamo i dati ordine', risp.ordine);
            setState(prevState => {
              return {
                ...prevState,
                ...risp.ordine,
              };
            });
          }
        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  React.useEffect(() => {
    if (inputValue.length > 2)
      (async () => {
        const res = await axios.get('https://laforgiaws.netkom.it/ws/getNomiAnagrafiche.php?searchTerm=' + inputValue);
        const aziende = res.data;
        console.log('compiliamo i campi azienda', aziende);
        setOptions(
          aziende.map(key => {
            return {
              name: key.RAGSOC,
              id: key.CODCF,
              partiva: key.PARTIVA,
              codfisc: key.CODFISC,
              indir: key.INDIR,
              cap: key.CAP,
              local: key.LOCAL,
              prov: key.PROV,
              telefono: key.TEL,
              email: key.EMAIL,
            };
          }),
        );
      })();
    if (inputValue.length <= 2) {
      setOptions([]);
    }
  }, [inputValue]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  React.useEffect(() => {
    if (valueAuto != null) {
      console.log('facciamo qualcosa con value', valueAuto);
      setState(prevState => {
        return {
          ...prevState,
          ragsoc: valueAuto.name,
          codfisc: valueAuto.codfisc,
          partiva: valueAuto.partiva,
          indir: valueAuto.indir,
          cap: valueAuto.cap,
          local: valueAuto.local,
          prov: valueAuto.prov,
          telefono: valueAuto.telefono,
          email: valueAuto.email,
        };
      });
    }
  }, [valueAuto]);

  return (
    <PageContainer heading={<IntlMessages id="pages.ordine" />} breadcrumbs={breadcrumbs}>
      {/*
      <Button
        variant="contained"
        color="primary"
        onClick={() =>
          ReactDOM.render(
            <PDFViewer>
              <TestPdf />
            </PDFViewer>,
            document.getElementById('root'),
          )
        }>
        primary
      </Button>
      */}
      <div className={classes.editAsset}>
        <div className={classes.root}>
          <Grid container spacing={10}>
            <Grid item xs={9}>
              <MdArrowBack size={30} onClick={() => history.push('/ordini-interventi')} />
            </Grid>
            <Grid item xs={3} alignItems="center" justifyContent="center">
              <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                <MdDownload size={30} onClick={() => downloadPdf()} />
                <h3>Scarica il pdf</h3>
              </span>
            </Grid>
            <Grid item xs={12}>
              <VersioniOrdine oid={state.oid} closeParent={() => handleClose()} caricaOrdine={caricaOrdine} />
            </Grid>
            <Grid item xs={12}>
              {state.oid && (
                <h2>
                  CONFERMA ORDINE {state.ragsoc} {state.figlio ? '- Ver. ' + state.figlio : ''}{' '}
                  {state.archiviato == 1 ? '- Archiviata' : ''}
                </h2>
              )}
              {!state.oid && (
                <Autocomplete
                  autoComplete
                  id="ricerca-cliente"
                  open={open}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  value={valueAuto}
                  onChange={(event, newValue) => {
                    setValueAuto(newValue);
                  }}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  getOptionSelected={(option, value) => option.name === value.name}
                  getOptionLabel={option => option.name}
                  options={options}
                  loading={loading}
                  //copmod se sono tante le filtriamo lato server in questo modo
                  //filterOptions={x => x}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Cerca un cliente"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <InputLabel id="stato-select-label">Stato</InputLabel>
              <Select
                style={{ width: '100%' }}
                labelId="simple-select-label-stato"
                id="simple-select-stato"
                value={state.stato_ordine}
                label="Stato"
                onChange={e => handleStatoOrdine(e.target.value)}>
                {statoOrdine &&
                  statoOrdine.map(key => {
                    return <MenuItem value={key.value}>{key.label}</MenuItem>;
                  })}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <InputLabel id="tipologia-select-label">Tipologia</InputLabel>
              <Select
                style={{ width: '100%' }}
                labelId="simple-select-label-tipo"
                id="simple-select-tipo"
                value={state.tipologia}
                label="Tipologia"
                onChange={e => handleTipologia(e.target.value)}>
                {tipologiaOpzioni &&
                  tipologiaOpzioni.map(key => {
                    return <MenuItem value={key.value}>{key.label}</MenuItem>;
                  })}
              </Select>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={state.partiva}
                variant="outlined"
                onChange={e => setState({ ...state, partiva: e.target.value })}
                id="partiva"
                name="partiva"
                label="Inserire la partita iva"
                className="segnalAnag"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={state.codfisc}
                variant="outlined"
                onChange={e => setState({ ...state, codfisc: e.target.value })}
                id="codfisc"
                name="codfisc"
                label="Inserire il codice fiscale"
                className="segnalAnag"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={state.oid}
                variant="outlined"
                //onChange={e => setState({ ...state, num_ordine: e.target.value })}
                id="numordine"
                name="numordine"
                label="Numero ordine"
                disabled={true}
                className="segnalAnag"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={state.indir}
                variant="outlined"
                onChange={e => setState({ ...state, indir: e.target.value })}
                id="indir"
                name="indir"
                label="Inserire indirizzo"
                className="segnalAnag"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={state.local}
                variant="outlined"
                onChange={e => setState({ ...state, local: e.target.value })}
                id="local"
                name="local"
                label="Inserire la località"
                className="segnalAnag"
              />
            </Grid>
            <Grid item xs={4}>
              Data/ora inizio intervento
              <DatePicker
                locale={'it'}
                name="startDate"
                selected={state.data_inizio}
                onChange={date => setState({ ...state, data_inizio: date })}
                renderInput={params => <TextField {...params} />}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={30}
                dateFormat="MMMM d, yyyy H:mm"
              />
              {/* <DatePicker dateFormat={'dd/MM/yyyy'} selected={dataOfferta} onChange={date => setDataOfferta(date)} /> */}
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={state.prov}
                variant="outlined"
                onChange={e => setState({ ...state, prov: e.target.value })}
                id="prov"
                name="prov"
                label="Inserire la provincia"
                className="segnalAnag"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={state.cap}
                variant="outlined"
                onChange={e => setState({ ...state, cap: e.target.value })}
                id="cap"
                name="cap"
                label="Inserire il cap"
                className="segnalAnag"
              />
            </Grid>
            <Grid item xs={4}>
              Data/ora fine intervento
              <DatePicker
                locale={'it'}
                name="endDate"
                selected={state.data_fine}
                onChange={date => setState({ ...state, data_fine: date })}
                renderInput={params => <TextField {...params} />}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={30}
                dateFormat="MMMM d, yyyy H:mm"
              />
              {/* <DatePicker dateFormat={'dd/MM/yyyy'} selected={dataOfferta} onChange={date => setDataOfferta(date)} /> */}
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={state.email}
                variant="outlined"
                onChange={e => setState({ ...state, email: e.target.value })}
                id="email"
                name="email"
                label="Inserire email"
                className="segnalAnag"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={state.telefono}
                variant="outlined"
                onChange={e => setState({ ...state, telefono: e.target.value })}
                id="telefono"
                name="telefono"
                label="Inserire telefono"
                className="segnalAnag"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={state.ordine_da}
                variant="outlined"
                onChange={e => setState({ ...state, ordine_da: e.target.value })}
                id="ordineDa"
                name="ordineDa"
                label="Ordine da"
                className="segnalAnag"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={state.consegna}
                variant="outlined"
                onChange={e => setState({ ...state, consegna: e.target.value })}
                id="consegna"
                name="consegna"
                label="Inserire la consegna"
                className="segnalFatturato"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={state.porto}
                variant="outlined"
                onChange={e => setState({ ...state, porto: e.target.value })}
                id="porto"
                name="Porto"
                label="Inserire porto"
                className="segnalFatturato"
              />
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={4}
                placeholder="Richiesta di intervento"
                style={{ width: '100%', fontSize: 17 }}
                value={state.ric_intervento}
                onChange={e => {
                  console.log(e.target.value);
                  setState({ ...state, ric_intervento: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={4}
                placeholder="Note intervento"
                style={{ width: '100%', fontSize: 17 }}
                value={state.note_intervento}
                onChange={e => {
                  console.log(e.target.value);
                  setState({ ...state, note_intervento: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              {state.allegato_intervento && (
                <a href={state.allegato_intervento} target="_blank" rel="noreferrer">
                  File caricato: {state.allegato_intervento.split('/').pop()}
                </a>
              )}
              <MyDropzone
                IDana={1}
                fileUploaded={url => {
                  setState(prevState => {
                    return { ...prevState, allegato_intervento: url };
                  });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={4}
                placeholder="Istituto vigilanza (eventuale)"
                style={{ width: '100%', fontSize: 17 }}
                value={state.ist_vigilanza}
                onChange={e => {
                  console.log(e.target.value);
                  setState({ ...state, ist_vigilanza: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={4}
                placeholder="Note vigilanza"
                style={{ width: '100%', fontSize: 17 }}
                value={state.note_vigilanza}
                onChange={e => {
                  console.log(e.target.value);
                  setState({ ...state, note_vigilanza: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={4}
                placeholder="Note"
                style={{ width: '100%' }}
                value={state.note}
                onChange={e => {
                  console.log(e.target.value);
                  setState({ ...state, note: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={4}
                placeholder="Destinazione merce"
                style={{ width: '100%' }}
                value={state.destinazione}
                onChange={e => {
                  console.log(e.target.value);
                  setState({ ...state, destinazione: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={state.serie}
                variant="outlined"
                onChange={e => setState({ ...state, serie: e.target.value })}
                id="serie"
                name="serie"
                label="Inserire la serie"
                className="segnalFatturato"
              />
            </Grid>
            <Grid item xs={12}>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={4}
                value={state.pagamento}
                placeholder="Pagamento"
                style={{ width: '100%' }}
                onChange={e => {
                  console.log(e.target.value);
                  setState({ ...state, pagamento: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ArticoliForm
                onAddArticle={onAddArticle}
                dati={state}
                articoli={state.articoli}
                getArticles={getArticles}
                ordine_id={1}
              />
            </Grid>

            <Grid item xs={2} align="right">
              {state.oid != 0 && (
                <IconButton
                  style={{ color: 'red' }}
                  variant="contain"
                  onClick={() => {
                    if (window.confirm('Cancellare?')) {
                      const data = {
                        oid: state.oid,
                        IDuser: localStorage.getItem('user_id'),
                      };
                      eliminaSegnal(data);
                    }
                  }}>
                  <MdDeleteForever />
                  Elimina
                </IconButton>
              )}
            </Grid>
            <Grid item xs={2} align="center">
              {state.archiviato == 0 && (
                <IconButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={() => {
                    salvaSegnal(state);
                  }}>
                  <MdSave />
                  Salva
                </IconButton>
              )}
            </Grid>
            <Grid item xs={2} align="center">
              {state.archiviato == 1 && (
                <IconButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={() => {
                    const data = {
                      oid: state.oid,
                      archiviato: 0,
                    };
                    ripristinaOrdine(data);
                  }}>
                  <MdUnarchive />
                  Ripristina
                </IconButton>
              )}
              {state.archiviato == 0 && state.oid != 0 && (
                <IconButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={() => {
                    const data = {
                      oid: state.oid,
                      archiviato: 1,
                    };
                    archiviaOrdine(data);
                  }}>
                  <MdArchive />
                  Archivia
                </IconButton>
              )}
            </Grid>
            <Grid item xs={4} align="center" />
          </Grid>
        </div>
      </div>
      <DocumentoPopup openPopup={docVisible} onClose={onCloseDoc} file={currentDoc} />
    </PageContainer>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  openPopup: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
