import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import CmtList from '../../../../@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import PeopleIcon from '@material-ui/icons/People';
import EuroIcon from '@material-ui/icons/Euro';
import MapIcon from '@material-ui/icons/LocationOn';
import CartaIcon from '@material-ui/icons/Payment';
import DescIcon from '@material-ui/icons/Description';
import useStyles from './index.style';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import Mappa from './mappa';
import Button from '@material-ui/core/Button';
import { getCustomerPartite, setCurrentCustomer } from 'redux/actions/Customers';
import CustomerPartiteView from 'routes/modules/Customers/PartiteDetailView';

const CustomerDetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const { currentCustomer } = useSelector(({ customersReducer }) => customersReducer);
  const [fullWidth, setFullWidth] = React.useState(false);
  const [maxWidth, setMaxWidth] = React.useState('lg');
  const [openPartiteDialog, setOpenPartiteDialog] = React.useState(false);

  const {
    ragsoc,
    email,
    status,
    phones,
    company,
    designation,
    profile_pic,
    starred,
    referente,
    ultimaFattura,
    dtUltimaVendita,
    partiva,
    codfisc,
    indirizzo,
    local,
    prov,
    cap,
    dataIns,
    dataUltMod,
    fatEle,
    codAge,
    pec,
    persoc,
    indicePA,
    telefono,
  } = currentCustomer;

  const ricercaPartite = () => {
    console.log('ricercaPartite ', currentCustomer.id);
    dispatch(getCustomerPartite({ id: currentCustomer.id }));
    setOpenPartiteDialog(true);
  };

  const handleClosePartiteDialog = () => {
    setOpenPartiteDialog(false);
    //dispatch(setCurrentCustomer(null));
  };

  const dispatch = useDispatch();

  React.useEffect(() => {
    console.log('CustomerDetailView useEffect', currentCustomer);
  }, [currentCustomer]);

  return (
    <Dialog maxWidth={maxWidth} fullWidth open={open} onClose={onCloseDialog} className={classes.dialogRoot + ' copClass'}>
      <Grid container justify="flex-end">
        <IconButton onClick={onCloseDialog}>
          <ClearIcon />
        </IconButton>
      </Grid>
      <Box className={classes.customerInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box className={classes.avatarView} mr={{ xs: 4, md: 6 }}>
            <CmtAvatar size={70} src={profile_pic} alt={ragsoc} />
          </Box>

          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot}>{ragsoc}</Typography>
              <Box ml={1}>
                <Checkbox
                  icon={<StarBorderIcon />}
                  checkedIcon={<StarIcon style={{ color: '#FF8C00' }} />}
                  checked={starred}
                />
              </Box>
            </Box>
            <Box display="flex" alignItems="center">
              <p>Tipologia: {persoc === 'S' ? 'Società' : 'Persona'}</p>
            </Box>
            <Box display="flex" alignItems="center">
              <p>Pubblica amministrazione: {indicePA ? indicePA : 'No'}</p>
            </Box>
            <Box display="flex" alignItems="center">
              <p>Settore: qui utilizzeremo i campi RAGCLI E ZONA che devono essere concordati col cliente </p>
            </Box>
            <Box display="flex" alignItems="center">
              Data inserimento
              <Box ml={3} color="primary.main" component="p" className="pointer">
                {moment(dataIns).format('DD/MM/YYYY')}
              </Box>
            </Box>
            <Box display="flex" alignItems="center">
              Data ult. modifica
              <Box ml={3} color="primary.main" component="p" className="pointer">
                {moment(dataUltMod).format('DD/MM/YYYY')}
              </Box>
            </Box>
            {(designation || company) && (
              <Box mt={-1}>
                {designation && <Typography className={classes.subTitleRoot}>{designation}</Typography>}
                {company && <Typography className={classes.subTitleRoot}>@{company}</Typography>}
              </Box>
            )}
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Button variant="contained" color="primary" onClick={() => ricercaPartite()}>
            Vai alle partite
          </Button>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}>
            <Tooltip title={status}>
              <IconButton aria-label="filter list">
                {status === 'Cliente' && <Block color="primary" />}
                {status === 'Contatto' && <CheckCircleOutline color="primary" />}
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Grid container>
        <Grid item xs={4}>
          <Box px={6} py={5}>
            <Box mb={5} component="p" color="common.dark">
              Contatti
            </Box>
            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
              <PeopleIcon />
              <Box ml={5} color="primary.main" component="p" className="pointer">
                {referente}
              </Box>
            </Box>
            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
              <EmailIcon />
              <Box ml={5} color="primary.main" component="p" className="pointer">
                {email}
              </Box>
            </Box>
            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
              <EmailIcon /> PEC
              <Box ml={5} color="primary.main" component="p" className="pointer">
                {pec}
              </Box>
            </Box>
            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
              <PhoneIcon />
              <Box ml={5}>{telefono}</Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box px={6} py={5}>
            <Box mb={5} component="p" color="common.dark">
              Aziendali
            </Box>
            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
              <MapIcon />
              <Box ml={5} color="primary.main" component="p" className="pointer">
                {indirizzo}
                <br />
                {local} <br />
                {prov}&nbsp;{cap}
              </Box>
            </Box>
            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
              <CartaIcon />
              Partita Iva
              <Box ml={5} color="primary.main" component="p" className="pointer">
                {partiva}
              </Box>
            </Box>
            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
              <DescIcon />
              Codice Fiscale
              <Box ml={5} color="primary.main" component="p" className="pointer">
                {codfisc}
              </Box>
            </Box>
            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
              <PeopleIcon />
              Agente
              <Box ml={5} color="primary.main" component="p" className="pointer">
                {codAge}
              </Box>
            </Box>
          </Box>
          {/*
          <Mappa />
          */}
        </Grid>
        <Grid item xs={4}>
          <Box px={6} py={5}>
            <Box mb={5} component="p" color="common.dark">
              Finanziari
            </Box>
            {currentCustomer.status === 'Cliente' && (
              <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
                <EuroIcon />
                Data ult. vendita
                <Box ml={5} color="primary.main" component="p" className="pointer">
                  {moment(dtUltimaVendita).format('DD/MM/YYYY')}
                </Box>
              </Box>
            )}
            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
              <EuroIcon />
              Codice fattura elettronica
              <Box ml={5} color="primary.main" component="p" className="pointer">
                {fatEle}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {openPartiteDialog && <CustomerPartiteView open={openPartiteDialog} onCloseDialog={handleClosePartiteDialog} />}
    </Dialog>
  );
};

export default CustomerDetailView;

CustomerDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
