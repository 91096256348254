import React, { forwardRef, useEffect } from 'react';
import MaterialTable, { MTableBody, MTableEditField } from 'material-table';
import { TableCell, TableFooter, TableRow } from '@material-ui/core';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Delete from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import axios from 'axios';
import { Button, Box } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import MyDropzone from './myDropZone';
import { MdPictureAsPdf, MdOutlineImage, MdInsertDriveFile, MdAttachFile } from 'react-icons/md';
import { pink } from '@mui/material/colors';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default props => {
  console.log('props di articoli form', props);
  const propsOfferta = props.dati;
  const ordine_id = props.ordine_id;
  //let articoli = props.articoli;
  const [oid, setOid] = React.useState(props.oid);
  const setDocVisible = doc => {
    //TODO: open new window
    if (oid > 0 || 1) window.open(doc, '_blank');
    else props.setDocVisible(doc);
  };
  const [isLoading, setIsLoading] = React.useState(false);
  const [state, setState] = React.useState({
    data: [],
    columns: [],
  });

  /*
  useEffect(() => {
    const cont = props.articoli;
    console.log('useEffect', cont);
    const data = [];
    cont.forEach(element => {
      data.push(element);
    });
    setState(prevState => {
      return { ...prevState, data };
    });
  }, [props.articoli]);
*/
  const aggiungiArticolo = data => {
    return new Promise((resolve, reject) => {
      console.log({ data });
      //props.getArticles(data);
      resolve(true);
      /*
      axios({
        method: 'post',
        url: 'https://laforgiaws.netkom.it/WS/ReactAddContact.php',
        data: data,
      }).then(response => {
        //console.log("Qui risposta  dentro then di axios", response.data);
        if (response.data.Result == 'OK') resolve('Stuff worked!');
        else reject('Stuff not worked!');
      });
    */
    });
  };

  useEffect(() => {
    console.log('qui vediamo la modifica articoli', props.articoli);
    setState(prevState => {
      return { ...prevState, data: props.articoli };
    });
  }, [props.articoli]);

  useEffect(() => {
    console.log('useEffect state.data', state.data);
    props.getArticles(state.data);
    let columns = [
      {
        title: 'Posiz.',
        width: '5%',
        render: rowData => rowData.tableData.id + 1,
      },
      {
        title: 'Titolo',
        field: 'titolo',
        width: '10%',
        editComponent: ({ value, onChange }) => (
          <TextField onChange={e => onChange(e.target.value)} value={value} multiline />
        ),
        render: rowData => <TextField value={rowData.titolo} multiline />,
        validate: rowData => (!rowData.titolo ? 'Titolo obbligatorio' : ''),
      },
      {
        title: 'Descrizione',
        field: 'descrizione',
        width: '30%',
        editComponent: ({ value, onChange }) => (
          <TextField onChange={e => onChange(e.target.value)} value={value} multiline />
        ),
        render: rowData => <TextField value={rowData.descrizione} multiline />,
      },
      {
        title: 'U.M.',
        field: 'um',
        width: '5%',
        initialEditValue: '',
        //validate: rowData => (!rowData.quantita ? 'La quant è obbl' : ''),
      },
      {
        title: 'Q.tà',
        field: 'quantita',
        type: 'numeric',
        width: '5%',
        initialEditValue: 0,
        //validate: rowData => (!rowData.quantita ? 'La quant è obbl' : ''),
      },
      {
        title: 'Prezzo unitario',
        field: 'prezzo',
        type: 'numeric',
        width: '10%',
        initialEditValue: 0,
        render: rowData => {
          return parseFloat(rowData.prezzo).toLocaleString('it-IT', {
            style: 'currency',
            currency: 'EUR',
          });
        },
      },
      {
        title: 'Totale articolo',
        type: 'numeric',
        width: '10%',
        render: rowData => {
          rowData.totale = rowData.quantita * rowData.prezzo;
          return parseFloat(rowData.quantita * rowData.prezzo).toLocaleString('it-IT', {
            style: 'currency',
            currency: 'EUR',
          });
        },
      },
      {
        title: 'Documento',
        field: 'documento',
        width: '15%',
        editComponent: props2 => {
          return (
            <React.Fragment>
              <MyDropzone
                IDana={props.oid}
                load={() => setIsLoading(true)}
                loaded={() => setState({ ...state, isLoading: false })}
                name={props2.rowData.documento && props2.rowData.documento.split('/').pop()}
                fileUploaded={url => {
                  console.log('props', props);
                  console.log('fileUploaded', url);
                  //console.log('onChange', onChange);
                  //we keep only the filename
                  //const filename = url.split('/').pop();
                  props2.onChange(url);
                  //setState({ ...state, isLoading: false });
                }}
              />
            </React.Fragment>
          );
        },
        render: rowData =>
          rowData.documento && (
            <React.Fragment>
              <Button variant="contained" color="primary" onClick={() => setDocVisible(rowData.documento)}>
                {renderDocIcon(rowData.documento)}
                {rowData.documento &&
                  rowData.documento
                    .split('/')
                    .pop()
                    .substr(0, 5)}
              </Button>
              <Button
                style={{ marginLeft: 10 }}
                variant="contained"
                color="secondary"
                onClick={() => {
                  //confirm
                  if (!window.confirm('Vuoi cancellare il documento?')) return;
                  //console.log('rowData', rowData);
                  //remove documento from state.data
                  let data = state.data;
                  //console.log('state', state);
                  data.forEach((item, index) => {
                    console.log('item', item);
                    if (item.aid === rowData.aid) {
                      item.documento = '';
                      deleteFile(item.aid, item.oid);
                    }
                  });
                  setState({ ...state, data });
                }}>
                <DeleteOutline color="primary" style={{ color: 'red' }} />
              </Button>
            </React.Fragment>
          ),
      },
      /*
        {
          title: 'Tot',
          field: 'totale_articoli',
          type: 'numeric',
          hidden: false,
          render: rowData => {
            rowData.totale_articoli = rowData.totale_articoli + rowData.quantita * rowData.prezzo;
            return (rowData.totale_articoli = rowData.totale_articoli + rowData.quantita * rowData.prezzo);
          },
        },
        */
    ];
    setState(prevState => {
      return { ...prevState, columns: columns };
    });
  }, [state.data]);

  return (
    <MaterialTable
      icons={tableIcons}
      title="Articoli"
      columns={state.columns}
      data={state.data}
      renderSummaryRow={({ column, data }) => {
        console.log('renderSummaryRow', data);
        return column.field === 'totale'
          ? {
              value: data.reduce((agg, row) => agg + row.totale, 0),
              style: { background: 'red' },
            }
          : undefined;
      }}
      options={{
        draggable: false,
        paging: false,
        pageSize: 20,
        emptyRowsWhenPaging: false,
        pageSizeOptions: [20, 40, 60], // rows selection options
        exportButton: {
          csv: true,
          pdf: false,
        },
        //exportPdf: (columns, data) => console.log(data, columns, '<== PDF'),
        exportPdf: (columns, data) => {
          console.log('exportPdf', data, columns);
          //calcolo il totale
          const totale = data.reduce((agg, row) => agg + row.totale, 0);
          //TODO:mettere uno stato per farlo solo la prima volta
          if (columns[0].title === 'Posizione') {
            columns.shift();
            data.push({
              aid: '',
              descrizione: '',
              oid: '',
              posizione: '',
              prezzo: '',
              um: '',
              quantita: '',
              titolo: '',
              totale: '',
            });
            data.push({
              aid: '',
              descrizione: '',
              oid: '',
              posizione: '',
              prezzo: 'Totale articoli',
              um: '',
              quantita: '',
              titolo: '',
              totale: totale,
            });
          }

          const doc = new jsPDF();
          const columnTitles = columns.map(columnDef => columnDef.title);
          const pdfData = data.map(rowData => columns.map(columnDef => rowData[columnDef.field]));
          var img = new Image();
          var width = doc.internal.pageSize.getWidth();
          img.src = '/images/logo-laf-trasp.png';
          doc.addImage(img, 'jpg', 0, 0, width, width / 4);
          doc.setTextColor(0, 0, 255);
          doc.text(props.dati.ragsoc, 10, 60);
          doc.text('Numero offerta: ' + props.dati.num_offerta, 10, 70);
          doc.moveTo(10, 500);
          doc.autoTable({
            head: [columnTitles],
            body: pdfData,
            startY: 100,
          });
          let finalY = doc.autoTable.previous.finalY;
          doc.text(20, finalY + 10, 'Cordiali saluti');

          doc.save(`articoli.pdf`);
        },
        tableLayout: 'fixed',
      }}
      components={{
        EditField: props => <MTableEditField {...props} multiline={props.columnDef.type === 'string' || undefined} />,
        Body: props => {
          let totalObj = {
            actualSum: 0,
          };
          props.renderData.forEach((rowData: any) => {
            //console.log('rowData', rowData);
            totalObj.actualSum += rowData.prezzo * rowData.quantita;
          });
          /*
          props.renderData.push({
            titolo: 'Totale',
            descrizione: '',
            prezzo: '',
            quantita: '',
            totale: totalObj.actualSum,
          });
          */
          return (
            <React.Fragment>
              <MTableBody {...props} />
              <TableFooter>
                {propsOfferta && propsOfferta.totale_escluso != 1 && (
                  <TableRow>
                    <TableCell colSpan={6} />
                    <TableCell style={{ textAlign: 'right' }} colSpan={3}>
                      <h2>
                        Totale imponibile:&nbsp;
                        {parseFloat(totalObj.actualSum).toLocaleString('it-IT', {
                          style: 'currency',
                          currency: 'EUR',
                        })}
                      </h2>
                    </TableCell>
                  </TableRow>
                )}

                {propsOfferta && propsOfferta.iva && propsOfferta.totale_escluso != 1 && (
                  <TableRow>
                    <TableCell colSpan={6} />
                    <TableCell style={{ textAlign: 'right' }} colSpan={3}>
                      <h2>
                        Totale imposta:&nbsp;
                        {parseFloat(totalObj.actualSum * (propsOfferta.iva / 100)).toLocaleString('it-IT', {
                          style: 'currency',
                          currency: 'EUR',
                        })}
                      </h2>
                    </TableCell>
                  </TableRow>
                )}

                {propsOfferta && propsOfferta.iva && propsOfferta.totale_escluso != 1 && (
                  <TableRow>
                    <TableCell colSpan={6} />
                    <TableCell style={{ textAlign: 'right' }} colSpan={3}>
                      <h2>
                        Totale offerta:&nbsp;
                        {parseFloat(totalObj.actualSum * (1 + propsOfferta.iva / 100)).toLocaleString('it-IT', {
                          style: 'currency',
                          currency: 'EUR',
                        })}
                      </h2>
                    </TableCell>
                  </TableRow>
                )}
              </TableFooter>
            </React.Fragment>
          );
        },
      }}
      editable={{
        onRowAdd: newData =>
          new Promise(resolve => {
            newData.IDordine = ordine_id;
            //console.log({newData});
            aggiungiArticolo(newData).then(roba => {
              //console.log("Qui siamo nel then di addContat e chiudiamo la promise", roba);
              resolve();
              setState(prevState => {
                const data = [...prevState.data];
                data.push(newData);
                return { ...prevState, data };
              });
            });
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise(resolve => {
            console.log({ newData, oldData });
            newData.IDordine = ordine_id;
            aggiornaArticolo(newData).then(roba => {
              //console.log("Qui siamo nel then di updateContat e chiudiamo la promise", roba);
              setState(prevState => {
                const data = [...prevState.data];
                data[data.indexOf(oldData)] = newData;
                return { ...prevState, data };
              });
              resolve();
            });
          }),
        onRowDelete: oldData =>
          new Promise(resolve => {
            oldData.IDordine = ordine_id;
            console.log('dentro onrowdelete', oldData);

            setState(prevState => {
              const data = [...prevState.data];
              data.splice(data.indexOf(oldData), 1);
              return { ...prevState, data };
            });
            resolve();
          }),
      }}
      localization={{
        pagination: {
          labelDisplayedRows: '{from}-{to} of {count}',
          labelRowsSelect: 'Articoli',
        },
        toolbar: {
          searchPlaceholder: 'Filtra gli articoli',
          nRowsSelected: '{0} row(s) selected',
        },
        header: {
          actions: 'Azioni',
        },
        body: {
          editRow: {
            deleteText: 'Sei sicuro di voler eliminare questo articolo?',
          },
          emptyDataSourceMessage: 'Non ci sono articoli',
          filterRow: {
            filterTooltip: 'Filtro',
          },
        },
      }}
    />
  );
};

const deleteFile = async (aid, oid) => {
  const response = await fetch('https://laforgiaws.netkom.it/ws/delFileArticolo.php', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      aid,
      oid,
    }),
  });
  const json = await response.json();
  if (!response.ok) throw Error(json.message);
  //update the state of the component

  return json;
};

const cancellaArticolo = data => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: 'https://laforgiaws.netkom.it/WS/ReactDeleteContact.php',
      data: data,
    }).then(response => {
      //console.log("Qui risposta  dentro then di axios", response.data);
      if (response.data.Result == 'OK') resolve('Stuff worked!');
      else reject('Stuff not worked!');
    });
  });
};

const aggiornaArticolo = data => {
  return new Promise((resolve, reject) => {
    resolve(data);
    /*
    axios({
      method: 'post',
      url: 'https://laforgiaws.netkom.it/WS/ReactUpdateContact.php',
      data: data,
    }).then(response => {
      //console.log("Qui risposta  dentro then di axios", response.data);
      if (response.data.Result == 'OK') resolve('Stuff worked!');
      else reject('Stuff not worked!');
    });
    */
  });
};

const renderDocIcon = (doc: string) => {
  if (!doc) {
    return '';
  }
  if (doc.includes('.pdf')) {
    return <MdPictureAsPdf size={25} />;
  } else if (doc.includes('.doc') || doc.includes('.docx')) {
    return <MdAttachFile size={25} />;
  } else if (doc.includes('.xls') || doc.includes('.xlsx') || doc.includes('.csv')) {
    return <MdInsertDriveFile size={25} />;
  } else if (doc.includes('.ppt') || doc.includes('.pptx')) {
    return <MdAttachFile size={25} />;
  } else if (doc.includes('.jpg') || doc.includes('.jpeg') || doc.includes('.png')) {
    return <MdOutlineImage size={25} />;
  } else if (doc.includes('.zip') || doc.includes('.rar')) {
    return <MdAttachFile size={25} />;
  } else if (doc.includes('.txt')) {
    return <MdAttachFile size={25} />;
  } else {
    return <MdAttachFile size={25} />;
  }
};
