import React from 'react';
import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Istituti from './istituti';
import { setSeconds } from 'date-fns';

const breadcrumbs = [
  { label: <IntlMessages id={'sidebar.main'} />, link: '/' },
  { label: <IntlMessages id={'pages.samplePage'} />, isActive: true },
];

const SamplePage = () => {
  const [instituti, setInstituti] = React.useState([]);

  React.useEffect(() => {
    try {
      //qui prendiamo i dati dalla offerta
      let json = fetch('https://laforgiaws.netkom.it/ws/getInstitutiVigilanza.php', {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(risp => {
          console.log('ecco la risposta vigil', risp);
          if (risp.success) {
            setInstituti(risp.instituti);
          }
        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <PageContainer heading={'Istituti di vigilanza'} breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <Istituti oid={1} instituti={instituti} setDocVisible={true} setAllegati={() => console.log('mi serve?')} />
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default SamplePage;
