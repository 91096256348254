import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from 'axios';
import {
  ADD_CUSTOMER,
  DELETE_BULK_CUSTOMERS,
  DELETE_CUSTOMER,
  EDIT_CUSTOMER,
  GET_CUSTOMERS,
  SET_CUSTOMER_DETAILS,
  SET_CUSTOMER_PARTITE,
} from '../../@jumbo/constants/ActionTypes';

export const getCustomers = (filterOptions = [], searchTerm = '', callbackFun) => {
  console.log({ filterOptions });
  console.log({ searchTerm });
  console.log({ callbackFun });
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('https://laforgiaws.netkom.it/ws/getAnagrafiche.php', {
        params: { filterOptions, searchTerm },
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_CUSTOMERS, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const getCustomerPartite = (anag, startDate, endDate) => {
  return dispatch => {
    dispatch(fetchStart());
    //console.log({ anag: anag, annoinizio: startDate, annofine: endDate });
    //TODO: forse meglio prima il dispatch ?
    dispatch({ type: SET_CUSTOMER_DETAILS, payload: anag });
    axios
      .get('https://laforgiaws.netkom.it/ws/matchPartiteWS.php', {
        params: { anag: anag.id, annoinizio: startDate, annofine: endDate },
      })
      .then(data => {
        console.log('arrivano i dati delle partite', data);
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_CUSTOMER_PARTITE, payload: data.data });
          //if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const setCurrentCustomer = customer => {
  console.log('setCurrentCustomer', customer);
  return dispatch => {
    dispatch({ type: SET_CUSTOMER_DETAILS, payload: customer });
  };
};

export const addNewCustomer = (customer, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('https://laforgiaws.netkom.it/ws/addNewContatto.php', customer)
      .then(data => {
        if (data.status === 200) {
          if (!data.data.success) {
            console.log('addNewCustomer message', data.data.descrizione);
            dispatch(fetchError(data.data.descrizione));
          } else {
            dispatch(fetchSuccess('Nuovo contatto aggiunto con successo.'));
            dispatch({ type: ADD_CUSTOMER, payload: data.data });
            if (callbackFun) callbackFun(data.data);
          }
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const sentMailToCustomer = () => {
  return dispatch => {
    //dispatch(fetchSuccess('Email has been sent to customer successfully'));
    dispatch(fetchError('Email non presente'));
  };
};

export const updateCustomer = (customer, callbackFun) => {
  console.log('passiam odi qua con ', customer);
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('https://laforgiaws.netkom.it/ws/updateContatto.php', customer)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Contatto aggiornato con successo.'));
          dispatch({ type: EDIT_CUSTOMER, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const updateCustomerStatus = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/customers/update-status', data)
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchSuccess('Customer status was updated successfully.'));
          dispatch({ type: EDIT_CUSTOMER, payload: response.data });
          if (callbackFun) callbackFun(response.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const deleteBulkCustomers = (customerIds, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/customers/bulk-delete', { customerIds })
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchSuccess('Selected customers were deleted successfully.'));
          dispatch({ type: DELETE_BULK_CUSTOMERS, payload: customerIds });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const deleteCustomer = (customerId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete('https://laforgiaws.netkom.it/ws/eliminaContatto.php', {
        params: { id: customerId },
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Utente cancellato con successo!'));
          dispatch({ type: DELETE_CUSTOMER, payload: customerId });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};
