import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import TableRow from '@material-ui/core/TableRow';
import { timeFromNow } from '../../../../@jumbo/utils/dateHelper';
import { Block, CheckCircleOutline, Delete, Edit, Mail, MoreHoriz, Visibility, Money } from '@material-ui/icons';
import CmtDropdownMenu from '../../../../@coremat/CmtDropdownMenu';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { sentMailToCustomer, updateCustomerStatus } from '../../../../redux/actions/Customers';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import { Star, Euro } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { fetchError, fetchStart, fetchSuccess } from '../../../../redux/actions/Common';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
  },
}));

const getCustomerActions = customer => {
  const actions = [
    { action: 'view', label: 'Vedi', icon: <Visibility /> },
    { action: 'partite', label: 'Partite', icon: <Money /> },
    { action: 'edit', label: 'Modifica', icon: <Edit /> },
    { action: 'email', label: 'Email', icon: <Mail /> },
  ];

  /*
  if (customer.status === 'active') {
    actions.push({ action: 'suspend', label: 'Sospendi', icon: <Block /> });
  } else {
    actions.push({
      action: 'activate',
      label: 'Riattiva',
      icon: <CheckCircleOutline />,
    });
  }
  */

  actions.push({ action: 'delete', label: 'Elimina', icon: <Delete /> });
  return actions;
};

const CustomerListRow = ({
  row,
  isSelected,
  onRowClick,
  onCustomerEdit,
  onCustomerDelete,
  onCustomerView,
  onCustomerPartite,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onCustomerMenuClick = menu => {
    if (menu.action === 'view') {
      onCustomerView(row);
    } else if (menu.action === 'partite') {
      onCustomerPartite(row);
    } else if (menu.action === 'edit') {
      onCustomerEdit(row);
    } else if (menu.action === 'email') {
      if (row.email) window.open('mailto:' + row.email);
      else dispatch(fetchError('Email non presente'));
    } else if (menu.action === 'suspend') {
      dispatch(fetchError('Funzione non ancora implementata su gestionale'));
      //dispatch(updateCustomerStatus({ id: row.id, status: 'suspended' }));
    } else if (menu.action === 'activate') {
      dispatch(updateCustomerStatus({ id: row.id, status: 'active' }));
    } else if (menu.action === 'delete') {
      onCustomerDelete(row);
    }
  };

  const labelId = `enhanced-table-checkbox-${row.id}`;
  const isItemSelected = isSelected(row.id);
  const customerActions = getCustomerActions(row);

  return (
    <TableRow
      hover
      onClick={event => onRowClick(event, row)}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}>
      <TableCell padding="checkbox">
        {/*
        <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
        */}
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Box display="flex" alignItems="center">
          <Box mr={{ xs: 4, md: 5 }}>
            <CmtAvatar size={40} src={row.profile_pic} alt={row.name} />
          </Box>
          <div>
            <Typography className={classes.titleRoot} component="div" variant="h4">
              {row.ragsoc}
            </Typography>
          </div>
        </Box>
      </TableCell>
      <TableCell>{row.status}</TableCell>
      <TableCell>{row.referente}</TableCell>
      <TableCell>{row.email}</TableCell>
      <TableCell>{row.telefono}</TableCell>
      <TableCell>{row.partiva}</TableCell>
      <TableCell>{moment(row.dataIns).format('DD/MM/YYYY')}</TableCell>
      <TableCell align="center" onClick={event => event.stopPropagation()}>
        <Grid container alignItems="center" justifyContent="center">
          {/*
          <Grid item xs={4}>
            <Tooltip title="Partite">
              <IconButton aria-label="Partite" onClick={() => onCustomerPartite(row)}>
                <Euro color="primary" />
              </IconButton>
            </Tooltip>
          </Grid>
        */}
          <Grid item xs={8}>
            <CmtDropdownMenu items={customerActions} onItemClick={onCustomerMenuClick} TriggerComponent={<MoreHoriz />} />
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default React.memo(CustomerListRow);
