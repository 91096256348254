import mock from '../mockConfig';
import { idGenerator } from '../../@jumbo/utils/commonHelper';
import { customersModule } from '../../@fake-db';
let customers = customersModule.customersList;

mock.onGet('/customers').reply(request => {
  console.log('passo da get customers', customers);
  const { filterOptions, searchTerm } = request.params;
  const filterLength = filterOptions.length;

  let filteredCustomers = customers;

  if (searchTerm || filterLength > 0) {
    if (searchTerm && filterLength > 0) {
      filteredCustomers = customers.filter(
        customer =>
          customer.ragsoc.toLowerCase().includes(searchTerm.toLowerCase()) && filterOptions.includes(customer.status),
      );
    } else if (searchTerm) {
      console.log('entro qui', customers);
      filteredCustomers = customers.filter(customer => customer.ragsoc.toLowerCase().includes(searchTerm.toLowerCase()));
    } else {
      filteredCustomers = customers.filter(customer => filterOptions.includes(customer.status));
    }
  }
  console.log({ filteredCustomers });
  return [200, filteredCustomers];
});

mock.onPost('/customers').reply(request => {
  const customer = JSON.parse(request.data);
  const newCustomer = {
    id: idGenerator(),
    starred: false,
    labels: [],
    emailUsage: 0.0, // GB
    status: 'active',
    suspendedBy: 'Admin',
    suspendedAt: new Date(),
    lastLoginAt: new Date(),
    createdAt: new Date(),
    updatedAt: new Date(),
    ...customer,
  };
  customers = [newCustomer, ...customers];
  return [200, newCustomer];
});

mock.onPut('/customers').reply(request => {
  const customer = JSON.parse(request.data);
  customers = customers.map(item => (item.id === customer.id ? customer : item));
  return [200, customer];
});

mock.onPut('/customers/update-status').reply(request => {
  const { status, id } = JSON.parse(request.data);
  let updatedCustomer = null;
  customers = customers.map(item => {
    if (item.id === id) {
      item.status = status;
      if (status === 'suspended') item.suspendedAt = new Date();
      updatedCustomer = item;
    }

    return item;
  });
  return [200, updatedCustomer];
});

mock.onPut('/customers/bulk-delete').reply(request => {
  const { customerIds } = JSON.parse(request.data);
  customers = customers.filter(customer => !customerIds.includes(customer.id));
  return [200];
});

mock.onDelete('/customers').reply(request => {
  const params = request.params;
  customers = customers.filter(item => item.id !== params.id);
  return [200];
});
