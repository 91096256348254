import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-hook-form';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from 'styled-components';
import cogoToast from 'cogo-toast';
import { DefaultEditor } from 'react-simple-wysiwyg';
import Moment from 'moment';
import axios from 'axios';
import { TextField, Input } from '@material-ui/core';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Box from '@material-ui/core/Box';
import EmailIcon from '@material-ui/icons/Email';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TodayIcon from '@material-ui/icons/Today';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import ArticoliForm from './articoliForm';
import FornitoriTable from './fornitoriTable';
import moment from 'moment';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import VersioniOfferta from './versioniOfferta';
import DocumentoPopup from './docPopup';

const Errore = styled.p`
  color: red;
  fontweight: 'bold';
`;

const schema = yup.object().shape({
  name: yup.string().required('Il campo è obbligatorio'),
});

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    width: '100%',
  },
  paperCop: {
    padding: theme.spacing(2),
    color: 'red',
    backgroundColor: '#e6ffff',
  },
  editAsset: {
    flexGrow: 1,
  },
  editFormItem: {},
  myForm: {
    width: '100%',
  },
}));

export default function SimpleDialog(props) {
  const { onClose, selectedValue, openPopup } = props;
  const [errore, setErrore] = useState('');
  const [bloccoInvio, setBloccoInvio] = useState(true);

  const [currentDoc, setCurrentDoc] = useState(false);
  const [oid, setOid] = useState(false);
  const [tipo, setTipo] = useState(0);
  const [ragSoc, setRagSoc] = useState('');
  const [partiva, setPartiva] = useState('');
  const [codFisc, setCodFisc] = useState('');
  const [indir, setIndir] = useState('');
  const [local, setLocal] = useState('');
  const [prov, setProv] = useState('');
  const [cap, setCap] = useState('');
  const [email, setEmail] = useState('');
  const [telefono, setTelefono] = useState('');
  const [commissione, setCommissione] = useState('');
  const [numCommissione, setNumCommissione] = useState('');
  const [dataOfferta, setDataOfferta] = useState(new Date());
  const [numOfferta, setNumOfferta] = useState('');
  const [ordineDa, setOrdineDa] = useState('');
  const [consegna, setConsegna] = useState('');
  const [porto, setPorto] = useState('');
  const [posaInOpera, setPosaInOpera] = useState('');
  const [note, setNote] = useState('');
  const [archiviato, setArchiviato] = useState(false);
  const [finitura, setFinitura] = useState('');
  const [destinazione, setDestinazione] = useState('');
  const [serie, setSerie] = useState('');
  const [pagamento, setPagamento] = useState('');
  const [parent, setParent] = useState('');
  const [figlio, setFiglio] = useState(0);

  const [articoli, setArticoli] = useState([]);
  const [fornitori, setFornitori] = useState([]);

  const [docVisible, setDocVisible] = useState(false);

  const [artDescr, setArtDescr] = useState('');
  const [artPos, setArtPos] = useState(1);
  const [artPrice, setArtPrice] = useState(0);
  const [artQty, setArtQty] = useState(1);

  const [art2Descr, setArt2Descr] = useState('');
  const [art2Pos, setArt2Pos] = useState(2);
  const [art2Price, setArt2Price] = useState(0);
  const [art2Qty, setArt2Qty] = useState(1);

  const [artTotal, setArtTotal] = useState(artPrice + art2Price);

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [valueAuto, setValueAuto] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  //const loading = open && options.length === 0;
  const loading = false;

  const user = useSelector(state => {
    //console.log('user', state.auth);
    return state.auth;
  });

  const classes = useStyles();

  const getArticles = articles => {
    console.log('getArticles', articles);
    //setArticoli(oldArray => [...oldArray, article]);
    setArticoli(articles);
  };

  const getFornitori = fornitori => {
    console.log('getFornitori', fornitori);
    setFornitori(fornitori);
  };

  const manageDoc = doc => {
    console.log('manageDoc', doc);
    setDocVisible(true);
    setCurrentDoc(doc);
  };

  const handleClose = () => {
    onClose(selectedValue);
  };

  const onCloseDoc = () => {
    setDocVisible(false);
  };

  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(schema),
  });
  const dispatch = useDispatch();

  const salvaSegnal = data => {
    console.log('ecco i dati', data);
    if (data.tipo == 1) {
      let json = fetch('https://laforgiaws.netkom.it/ws/SalvaOrdine.php', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(risp => {
          console.log('ecco la risposta salvaDoc', risp);
          if (risp.success) {
            props.getDocs();
            cogoToast.info(
              <div>
                <b>Salvataggio OK</b>
                <div>Ordine Salvato</div>
              </div>,
            );
          }
        });
    } else {
      let json = fetch('https://laforgiaws.netkom.it/ws/SalvaOfferta.php', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(risp => {
          console.log('ecco la risposta salvaDoc', risp);
          if (risp.success) {
            props.getDocs();
            cogoToast.info(
              <div>
                <b>Salvataggio OK</b>
                <div>Offerta Salvata</div>
              </div>,
            );
          }
        });
    }
  };

  const archiviaOfferta = data => {
    console.log('ecco i dati', data);
    let json = fetch('https://laforgiaws.netkom.it/ws/ArchiviaOfferta.php', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(risp => {
        console.log('ecco la risposta salvaDoc', risp);
        if (risp.success) {
          props.getDocs();
          cogoToast.info(
            <div>
              <b>Salvataggio OK</b>
              <div>Ordine Salvato</div>
            </div>,
          );
        }
      });
  };

  const ripristinaOfferta = data => {
    console.log('ecco i dati', data);
    let json = fetch('https://laforgiaws.netkom.it/ws/RipristinaOfferta.php', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(risp => {
        console.log('ecco la risposta salvaDoc', risp);
        if (risp.success) {
          props.getDocs();
          cogoToast.info(
            <div>
              <b>Ripristino OK</b>
              <div>Offerta Ripristinata</div>
            </div>,
          );
        }
      });
  };

  const versionaOfferta = data => {
    console.log('ecco i dati da versionare', data);
    let json = fetch('https://laforgiaws.netkom.it/ws/VersionaOfferta.php', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(risp => {
        console.log('ecco la risposta versiona offerta', risp);
        if (risp.success) {
          props.getDocs();
          onClose();
          cogoToast.info(
            <div>
              <b>Versione OK</b>
              <div>Offerta attivata</div>
            </div>,
          );
        }
      });
  };

  const eliminaSegnal = data => {
    console.log('ecco i dati elimina', data);
    let json = fetch('https://laforgiaws.netkom.it/ws/eliminaOrdine.php?oid=' + data.oid, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(risp => {
        console.log('ecco la risposta eliminaDoc', risp);
        if (risp.success) {
          //props.onBloccoDone();
          props.getDocs();
          cogoToast.info(
            <div>
              <b>Eliminazione</b>
              <div>Ordine eliminato</div>
            </div>,
          );
        }
      });
  };

  const onAddArticle = () => {
    console.log('onAddArticle');
  };

  const caricaOrdine = valori => {
    console.log(valori);
    setOid(valori.oid);
    setArticoli(valori.articoli);
    setFornitori(valori.fornitori);
    setTipo(valori.tipo);
    setRagSoc(valori.ragsoc);
    setArchiviato(valori.archiviato);
    setParent(valori.parent);
    setFiglio(valori.figlio);
    setPartiva(valori.partiva);
    setCodFisc(valori.codfisc);
    setIndir(valori.indir);
    setLocal(valori.local);
    setProv(valori.prov);
    setCap(valori.cap);
    setEmail(valori.email);
    setTelefono(valori.telefono);
    setCommissione(valori.commissione);
    setNumCommissione(valori.numcommissione);
    setDataOfferta(valori.data_offerta);
    setNumOfferta(valori.oid);
    setOrdineDa(valori.ordine_da);
    setConsegna(valori.consegna);
    setPorto(valori.porto);
    setPosaInOpera(valori.posa_in_opera);
    setNote(valori.note);
    setFinitura(valori.finitura);
    setDestinazione(valori.destinazione);
    setSerie(valori.serie);
    setPagamento(valori.pagamento);
  };

  useEffect(() => {
    if (props.valori) {
      console.log('abbiamo i valori', props.valori);
      setOid(props.valori.oid);
      setTipo(props.valori.tipo);
      setRagSoc(props.valori.ragsoc);
      setArchiviato(props.valori.archiviato);
      setParent(props.valori.parent);
      setFiglio(props.valori.figlio);
      if (articoli.length === 0) setArticoli(props.valori.articoli);
      if (fornitori && fornitori.length === 0) setFornitori(props.valori.fornitori);
      if (!partiva) setPartiva(props.valori.partiva);
      if (!codFisc) setCodFisc(props.valori.codfisc);
      if (!indir) setIndir(props.valori.indir);
      if (!local) setLocal(props.valori.local);
      if (!prov) setProv(props.valori.prov);
      if (!cap) setCap(props.valori.cap);
      if (!email) setEmail(props.valori.email);
      if (!telefono) setTelefono(props.valori.telefono);
      if (!commissione) setCommissione(props.valori.commissione);
      if (!numCommissione) setNumCommissione(props.valori.numcommissione);
      if (!dataOfferta) setDataOfferta(props.valori.data_offerta);
      if (!numOfferta) setNumOfferta(props.valori.oid);
      if (!ordineDa) setOrdineDa(props.valori.ordine_da);
      if (!consegna) setConsegna(props.valori.consegna);
      if (!porto) setPorto(props.valori.porto);
      if (!posaInOpera) setPosaInOpera(props.valori.posa_in_opera);
      if (!note) setNote(props.valori.note);
      if (!finitura) setFinitura(props.valori.finitura);
      if (!destinazione) setDestinazione(props.valori.destinazione);
      if (!serie) setSerie(props.valori.serie);
      if (!pagamento) setPagamento(props.valori.pagamento);
    } else {
      setOid(false);
      setTipo(0);
      setRagSoc('');
      setArticoli([]);
      setFornitori([]);
      setPartiva('');
      setCodFisc('');
      setIndir('');
      setLocal('');
      setProv('');
      setCap('');
      setEmail('');
      setTelefono('');
      setCommissione('');
      setNumCommissione('');
      setDataOfferta('');
      setNumOfferta('');
      setOrdineDa('');
      setConsegna('');
      setPorto('');
      setPosaInOpera('');
      setNote('');
      setArchiviato(false);
      setFinitura('');
      setDestinazione('');
      setSerie('');
      setPagamento('');
      setParent(0);
    }
  }, [props]);

  useEffect(() => {
    console.log('ecco i valori di articoli', articoli);
  }, [articoli]);

  useEffect(() => {
    try {
      //TODO prendere la lista ordini
    } catch (e) {
      console.log(e);
    }
  }, []);

  React.useEffect(() => {
    if (inputValue.length > 2)
      (async () => {
        const res = await axios.get('https://laforgiaws.netkom.it/ws/getNomiAnagrafiche.php?searchTerm=' + inputValue);
        const aziende = res.data;
        console.log('compiliamo i campi azienda', aziende);
        setOptions(
          aziende.map(key => {
            return {
              name: key.RAGSOC,
              id: key.CODCF,
              partiva: key.PARTIVA,
              codfisc: key.CODFISC,
              indir: key.INDIR,
              cap: key.CAP,
              local: key.LOCAL,
              prov: key.PROV,
              telefono: key.TEL,
              email: key.EMAIL,
            };
          }),
        );
      })();
    if (inputValue.length <= 2) {
      setOptions([]);
    }
  }, [inputValue]);

  /*
  React.useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    (async () => {
      const res = await axios.get('https://laforgiaws.netkom.it/ws/getNomiAnagrafiche.php');
      //await sleep(1e3); // For demo purposes.
      //const aziende = await response.json();
      const aziende = res.data;
      if (active) {
        console.log('mappo le aziende', aziende);
        //setOptions(Object.keys(aziende).map(key => aziende[key].item[0]));
        setOptions(
          aziende.map(key => {
            return { name: key.RAGSOC, id: key.CODCF, partiva: key.PARTIVA };
          }),
        );
      }
    })();
    return () => {
      active = false;
    };
  }, [loading]);
*/

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  React.useEffect(() => {
    if (valueAuto != null) {
      console.log('facciamo qualcosa con value', valueAuto);
      setRagSoc(valueAuto.name);
      setPartiva(valueAuto.partiva);
      setCodFisc(valueAuto.codfisc);
      setIndir(valueAuto.indir);
      setLocal(valueAuto.local);
      setProv(valueAuto.prov);
      setCap(valueAuto.cap);
      setEmail(valueAuto.email);
      setTelefono(valueAuto.telefono);
    }
  }, [valueAuto]);

  return (
    <Dialog
      maxWidth={'lg'}
      fullWidth={true}
      fullScreen={false}
      onClose={handleClose}
      aria-labelledby="edit-asset-dialog"
      open={openPopup}>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
      <DialogTitle id="simple-dialog-title">{props.dati.titolo}</DialogTitle>
      <div className={classes.editAsset}>
        <div className={classes.root}>
          <Grid container spacing={10}>
            <Grid item xs={12}>
              <VersioniOfferta oid={oid} closeParent={() => handleClose()} caricaOrdine={caricaOrdine} />
            </Grid>
            <Grid item xs={8}>
              {oid && (
                <h2>
                  OFFERTA {ragSoc} Ver. {figlio}
                </h2>
              )}
              {!oid && (
                <Autocomplete
                  autoComplete
                  id="ricerca-cliente"
                  open={open}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  value={valueAuto}
                  onChange={(event, newValue) => {
                    setValueAuto(newValue);
                  }}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  getOptionSelected={(option, value) => option.name === value.name}
                  getOptionLabel={option => option.name}
                  options={options}
                  loading={loading}
                  //copmod se sono tante le filtriamo lato server in questo modo
                  //filterOptions={x => x}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Cerca un cliente"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={partiva}
                variant="outlined"
                onChange={e => setPartiva(e.target.value)}
                id="partiva"
                name="partiva"
                label="Inserire la partita iva"
                className="segnalAnag"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={codFisc}
                variant="outlined"
                onChange={e => setCodFisc(e.target.value)}
                id="codfisc"
                name="codfisc"
                label="Inserire il codice fiscale"
                className="segnalAnag"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={numOfferta}
                variant="outlined"
                onChange={e => setNumOfferta(e.target.value)}
                id="numofferta"
                name="numofferta"
                label="Numero offerta"
                disabled={true}
                className="segnalAnag"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={indir}
                variant="outlined"
                onChange={e => setIndir(e.target.value)}
                id="indir"
                name="indir"
                label="Inserire indirizzo"
                className="segnalAnag"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={local}
                variant="outlined"
                onChange={e => setLocal(e.target.value)}
                id="local"
                name="local"
                label="Inserire la località"
                className="segnalAnag"
              />
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Data offerta"
                  inputFormat="dd/MM/yyyy"
                  value={dataOfferta}
                  onChange={date => setDataOfferta(date)}
                  renderInput={params => <TextField {...params} />}
                />
              </LocalizationProvider>
              {/* <DatePicker dateFormat={'dd/MM/yyyy'} selected={dataOfferta} onChange={date => setDataOfferta(date)} /> */}
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={prov}
                variant="outlined"
                onChange={e => setProv(e.target.value)}
                id="prov"
                name="prov"
                label="Inserire la provincia"
                className="segnalAnag"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={cap}
                variant="outlined"
                onChange={e => setCap(e.target.value)}
                id="cap"
                name="cap"
                label="Inserire il cap"
                className="segnalAnag"
              />
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={email}
                variant="outlined"
                onChange={e => setEmail(e.target.value)}
                id="email"
                name="email"
                label="Inserire email"
                className="segnalAnag"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={telefono}
                variant="outlined"
                onChange={e => setTelefono(e.target.value)}
                id="telefono"
                name="telefono"
                label="Inserire telefono"
                className="segnalAnag"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={ordineDa}
                variant="outlined"
                onChange={e => setOrdineDa(e.target.value)}
                id="ordineDa"
                name="ordineDa"
                label="Ordine da"
                className="segnalAnag"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={consegna}
                variant="outlined"
                onChange={e => setConsegna(e.target.value)}
                id="consegna"
                name="consegna"
                label="Inserire la consegna"
                className="segnalFatturato"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={porto}
                variant="outlined"
                onChange={e => setPorto(e.target.value)}
                id="porto"
                name="Porto"
                label="Inserire porto"
                className="segnalFatturato"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={posaInOpera}
                variant="outlined"
                onChange={e => setPosaInOpera(e.target.value)}
                id="posainopera"
                name="posainopera"
                label="Inserire la posa in opera"
                className="segnalFatturato"
              />
            </Grid>
            <Grid item xs={8}>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={4}
                placeholder="Note"
                style={{ width: '100%' }}
                value={note}
                onChange={e => {
                  console.log(e.target.value);
                  setNote(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={finitura}
                variant="outlined"
                onChange={e => setFinitura(e.target.value)}
                id="finitura"
                name="finitura"
                label="Inserire la finitura"
                className="segnalFatturato"
              />
            </Grid>
            <Grid item xs={8}>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={4}
                placeholder="Destinazione merce"
                style={{ width: '100%' }}
                value={destinazione}
                onChange={e => {
                  console.log(e.target.value);
                  setDestinazione(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={serie}
                variant="outlined"
                onChange={e => setSerie(e.target.value)}
                id="serie"
                name="serie"
                label="Inserire la serie"
                className="segnalFatturato"
              />
            </Grid>
            <Grid item xs={12}>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={4}
                value={pagamento}
                placeholder="Pagamento"
                style={{ width: '100%' }}
                onChange={e => {
                  console.log(e.target.value);
                  setPagamento(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ArticoliForm onAddArticle={onAddArticle} articoli={articoli} getArticles={getArticles} ordine_id={1} />
            </Grid>
            <Grid item xs={12}>
              <FornitoriTable
                fornitori={fornitori || []}
                setFornitori={getFornitori}
                setDocVisible={manageDoc}
                doc={currentDoc}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton variant="contained" color="primary" onClick={() => handleClose()}>
                <CloseIcon />
                ANNULLA
              </IconButton>
            </Grid>
            <Grid item xs={2} align="right">
              {parent == 0 && (
                <IconButton
                  style={{ color: 'red' }}
                  variant="contained"
                  onClick={() => {
                    if (window.confirm('Cancellare?')) {
                      onClose();
                      const data = {
                        oid: oid,
                        IDuser: localStorage.getItem('user_id'),
                      };
                      eliminaSegnal(data);
                    }
                  }}>
                  <CloseIcon />
                  ELIMINA
                </IconButton>
              )}
            </Grid>
            <Grid item xs={2} align="center">
              {archiviato == 0 && parent == 0 && (
                <IconButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={() => {
                    onClose();
                    const data = {
                      oid: oid,
                      tipo: 0,
                      ragsoc: ragSoc,
                      partiva: partiva,
                      codfisc: codFisc,
                      indir: indir,
                      local: local,
                      prov: prov,
                      cap: cap,
                      commissione: commissione,
                      num_commissione: numCommissione,
                      data_offerta: moment(dataOfferta).format('YYYY-MM-DD'),
                      IDuser: localStorage.getItem('user_id'),
                      email: email,
                      telefono: telefono,
                      articoli: articoli,
                      fornitori: fornitori,
                      num_offerta: numOfferta,
                      ordine_da: ordineDa,
                      consegna: consegna,
                      porto: porto,
                      posa_in_opera: posaInOpera,
                      note: note,
                      archiviato: 0,
                      finitura: finitura,
                      destinazione: destinazione,
                      serie: serie,
                      pagamento: pagamento,
                    };
                    salvaSegnal(data);
                  }}>
                  <EditIcon />
                  SALVA
                </IconButton>
              )}
              {archiviato == 1 && parent == 0 && (
                <IconButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={() => {
                    onClose();
                    const data = {
                      oid: oid,
                      archiviato: 1,
                    };
                    ripristinaOfferta(data);
                  }}>
                  <EditIcon />
                  RIPRISTINA
                </IconButton>
              )}
              {parent != 0 && (
                <IconButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={() => {
                    onClose();
                    const data = {
                      oid: oid,
                    };
                    versionaOfferta(data);
                  }}>
                  <EditIcon />
                  ATTIVA OFFERTA
                </IconButton>
              )}
            </Grid>
            <Grid item xs={4} align="center">
              {archiviato == 0 && parent == 0 && (
                <IconButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={() => {
                    onClose();
                    const data = {
                      oid: '',
                      parent: oid,
                      tipo: 1,
                      ragsoc: ragSoc,
                      partiva: partiva,
                      codfisc: codFisc,
                      indir: indir,
                      local: local,
                      prov: prov,
                      cap: cap,
                      commissione: commissione,
                      num_commissione: numCommissione,
                      data_offerta: moment(dataOfferta).format('YYYY-MM-DD'),
                      IDuser: localStorage.getItem('user_id'),
                      email: email,
                      telefono: telefono,
                      articoli: articoli,
                      fornitori: fornitori,
                      num_offerta: numOfferta,
                      ordine_da: ordineDa,
                      consegna: consegna,
                      porto: porto,
                      posa_in_opera: posaInOpera,
                      note: note,
                      finitura: finitura,
                      destinazione: destinazione,
                      serie: serie,
                      pagamento: pagamento,
                    };
                    salvaSegnal(data);
                  }}>
                  <EditIcon />
                  SALVA COME ORDINE
                </IconButton>
              )}
            </Grid>
            <Grid item xs={2} align="center">
              {archiviato == 0 && parent == 0 && (
                <IconButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={() => {
                    onClose();
                    const data = {
                      oid: oid,
                      archiviato: 1,
                    };
                    archiviaOfferta(data);
                  }}>
                  <EditIcon />
                  ARCHIVIA
                </IconButton>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
      <DocumentoPopup openPopup={docVisible} onClose={onCloseDoc} file={currentDoc} />
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  openPopup: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
