import React, { useState, useEffect, forwardRef } from 'react';
import { API_URL } from '../../../@jumbo/constants/AppConstants';
import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import MaterialTable, { MTableBody, MTableEditField } from 'material-table';
import { TableCell, TableFooter, TableRow } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import OrdinePopup from './ordinePopup';
import AddIcon from '@material-ui/icons/Add';
import { ArrowRight } from '@material-ui/icons/';
import moment from 'moment';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SearchBar from 'material-ui-search-bar';
import { makeStyles } from '@material-ui/core/styles';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { TextField } from '@material-ui/core';
import { useHistory } from 'react-router';

const breadcrumbs = [
  { label: <IntlMessages id={'sidebar.main'} />, link: '/' },
  { label: <IntlMessages id={'pages.ordini'} />, isActive: true },
];

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const Ordini2 = () => {
  const [ordini, setOrdini] = useState([]);
  const [ordiniOriginali, setOrdiniOriginali] = useState([]);
  const [bloccoVisible, setBloccoVisible] = useState(false);
  const [selected, setSelected] = useState(false);
  const [selectedTipo, setSelectedTipo] = useState(2);

  const history = useHistory();
  const [state, setState] = React.useState({
    columns: [
      {
        title: 'Data creazione',
        field: 'data',
        render: rowData => moment(rowData.data).format('DD/MM/YYYY'),
        width: '10%',
      },
      {
        title: 'N.Ordine',
        field: 'vid',
        render: rowData => (!rowData.vid ? rowData.oid + '/23' : rowData.vid),
        width: '10%',
        searchable: true,
      },
      {
        title: 'N.Ordine',
        field: 'oid',
        hidden: true,
        searchable: true,
      },
      {
        title: 'Cliente',
        field: 'ragsoc',
        width: '20%',
      },
      {
        title: 'Descrizione',
        field: 'note',
        width: '20%',
        render: rowData => (
          <TextField
            value={rowData.articoli[0] ? rowData.articoli[0].titolo : ''}
            multiline
            inputProps={{ readOnly: true, disabled: true }}
          />
        ),
      },
      {
        title: 'Articoli',
        width: '10%',
        hidden: true,
        searchable: true,
        render: rowData => rowData.articoli.map(articolo => articolo.titolo),
        //customFilterAndSearch: (term, rowData) => rowData.articoli[0].titolo.toLowerCase().indexOf(term.toLowerCase()) != -1,
        customFilterAndSearch: (term, rowData) =>
          rowData.articoli
            .map(articolo => articolo.titolo)
            .join(' ')
            .toLowerCase()
            .indexOf(term.toLowerCase()) != -1,
      },
      {
        title: 'N.Articoli',
        field: '',
        type: 'numeric',
        width: '10%',
        render: rowData => {
          return rowData.articoli.length;
        },
      },
      {
        title: 'Stato',
        field: 'archiviato',
        width: '10%',
        render: rowData => {
          return rowData.archiviato == 1 ? 'Archiviato' : 'Attivo';
        },
      },
      {
        title: 'Vedi',
        width: '10%',
        render: rowData => {
          return (
            <IconButton variant="contained" color="secondary" onClick={() => mostraPopup(rowData)}>
              <ArrowRight style={{ fontSize: '40px' }} />
            </IconButton>
          );
        },
      },
    ],
  });

  //search
  const [rows, setRows] = useState(ordini);
  const [searched, setSearched] = useState('');
  const classes = useStyles();

  useEffect(() => {
    console.log('ecco i docs', ordini);
  }, [ordini]);

  useEffect(() => {
    getDocs();
  }, []);

  const onClose = () => {
    setBloccoVisible(false);
    setSelected(false);
  };

  const mostraPopup = row => {
    //setSelected(row);
    //setBloccoVisible(true);
    history.push({
      pathname: '/ordine/' + row.oid,
      state: { altro: 'altro' },
    });
  };

  const handleChange = event => {
    setSelectedTipo(event.target.value);
    if (event.target.value != '2') {
      let _vals = event.target.value ? ordiniOriginali.filter(r => r.archiviato === event.target.value) : ordini;
      setOrdini(_vals);
    } else setOrdini(ordiniOriginali);
  };

  const getDocs = () => {
    const data = {};
    let json = fetch(API_URL + '/getOrdini.php', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(risp => {
        console.log('ecco la risposta', risp);
        if (risp.response) {
          setOrdini(risp.ordini);
          setOrdiniOriginali(risp.ordini);
        }
      });
  };

  return (
    <PageContainer heading={<h3 style={{ color: '#012060' }}>CONFERME ORDINI.</h3>} breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={6}>
          {/*
          <div style={{ marginBottom: 10 }}>
            <IconButton
              variant="contained"
              color="primary"
              className="ico-action"
              onClick={() => {
                //setSelected(false);
                //setBloccoVisible(true);
                history.push({
                  pathname: '/ordine/0',
                  state: { altro: 'altro' },
                });
              }}>
              <AddIcon fontSize="large" />
              Nuovo ordine
            </IconButton>
          </div>
            */}
        </Grid>
        <Grid item xs={6}>
          <label value="Select Country: ">Seleziona il tipo: </label>
          <Select style={{ width: '30%' }} value={selectedTipo} onChange={handleChange} name="country" displayEmpty>
            <MenuItem value="2">Tutti</MenuItem>
            <MenuItem value="0">Attivi</MenuItem>
            <MenuItem value="1">Archiviati</MenuItem>
          </Select>
        </Grid>
        <div style={{ marginTop: 24 }}>
          <Divider />
          <Grid container xs={12} className="cont-HeadImpegni">
            <Grid item xs={12} className="zoomInRight animation-delay-4 animated">
              <MaterialTable
                style={{
                  backgroundColor: '#cceef6',
                  borderLeft: '3px solid #00ccff',
                }}
                icons={tableIcons}
                title="Articoli"
                columns={state.columns}
                data={ordini}
                options={{
                  draggable: false,
                  tableLayout: 'fixed',
                  pageSize: 10,
                  headerStyle: {
                    backgroundColor: '#012060', // Set the header background color to blue
                    color: '#FFF', // Set the header text color to white
                  },
                }}
                components={{
                  EditField: props => (
                    <MTableEditField {...props} multiline={props.columnDef.type === 'string' || undefined} />
                  ),
                }}
                localization={{
                  pagination: {
                    labelDisplayedRows: '{from}-{to} of {count}',
                    labelRowsSelect: 'Clienti',
                  },
                  toolbar: {
                    searchPlaceholder: 'Filtra  gli ordini',
                    nRowsSelected: '{0} row(s) selected',
                  },
                  header: {
                    actions: 'Azioni',
                  },
                  body: {
                    editRow: {
                      deleteText: 'Sei sicuro di voler eliminare questo cliente?',
                    },
                    emptyDataSourceMessage: 'Non ci sono ordini',
                    filterRow: {
                      filterTooltip: 'Filtro',
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
          <OrdinePopup
            openPopup={bloccoVisible}
            onClose={onClose}
            valori={selected}
            getDocs={getDocs}
            dati={selected ? { titolo: 'Modifica documento' } : { titolo: 'Nuovo documento' }}
          />
        </div>
      </GridContainer>
    </PageContainer>
  );
};

export default Ordini2;
