import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import axios, { post } from 'axios';
import { MdCloudUpload } from 'react-icons/md';
import Placeholer from './placeholder';

let fileUpload = (file, IDana, fileUploaded) => {
  console.log('file', file);
  const url = 'https://laforgiaws.netkom.it/fileReceiver.php';
  const formData = new FormData();
  formData.append('IDana', IDana);
  formData.append('avatar', file);
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return post(url, formData, config).then(
    response => {
      console.log(response);
      fileUploaded(response.data.url);
    },
    error => {
      console.log(error);
    },
  );
};

export default function MyDropzone({ IDana, fileUploaded }) {
  const onDrop = files => {
    console.log('FU', fileUploaded);

    files.forEach(file => {
      fileUpload(file, IDana, fileUploaded);
    });
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Placeholer />
    </div>
  );
}
