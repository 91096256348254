import mock from '../mockConfig';
import { idGenerator } from '../../@jumbo/utils/commonHelper';
import { clientiModule } from '../../@fake-db';
let users = clientiModule.usersList;

mock.onGet('/clienti').reply(request => {
  console.log('passo di qua', request);
  //const user = JSON.parse(request.data);
  //users = users.map(item => (item.id === user.id ? user : item));
  return [200, users];
});

mock.onPost('/clienti').reply(request => {
  const user = JSON.parse(request.data);
  const newUser = {
    id: idGenerator(),
    starred: false,
    labels: [],
    emailUsage: 0.0, // GB
    status: 'active',
    suspendedBy: 'Admin',
    suspendedAt: new Date(),
    lastLoginAt: new Date(),
    createdAt: new Date(),
    updatedAt: new Date(),
    ...user,
  };
  users = [newUser, ...users];
  return [200, newUser];
});
