import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCustomerPartite, setCurrentCustomer } from '../../../redux/actions/Customers';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import cogoToast from 'cogo-toast';
import Moment from 'moment';
import axios from 'axios';
import { TextField, Input, Box } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CustomerPartiteView from 'routes/modules/Customers/PartiteDetailView';
import CustomerDetailView from 'routes/modules/Customers/UserDetailView';
import moment from 'moment';

const Errore = styled.p`
  color: red;
  fontweight: 'bold';
`;

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  paperCop: {
    padding: theme.spacing(2),
    color: 'red',
    backgroundColor: '#e6ffff',
  },
  editAsset: {
    flexGrow: 1,
  },
  editFormItem: {},
  myForm: {
    width: '100%',
  },
}));

export default function SimpleDialog(props) {
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openPartiteDialog, setOpenPartiteDialog] = useState(false);
  const [partite, setPartite] = useState([]);
  const { onClose, selectedValue, open } = props;

  const user = useSelector(state => {
    return state.auth;
  });

  const classes = useStyles();

  const handleClose = () => {
    onClose(selectedValue);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    console.log('ecco le props del popup', props);
    if (props.valori) {
      let json = fetch('https://laforgiaws.netkom.it/ws/getPartite.php?codcf=' + props.valori.CODCF, {
        method: 'get',
      })
        .then(response => response.json())
        .then(risp => {
          console.log('ecco la risposta', risp);
          if (risp.response) {
            setPartite(risp.partite);
          }
        });
    }
  }, [props]);

  useEffect(() => {}, []);

  const ricercaPartite = () => {
    console.log('ricercaPartite ', props.valori.CODCF);
    dispatch(getCustomerPartite({ id: props.valori.CODCF }));
    setOpenPartiteDialog(true);
  };

  const dettagliCliente = () => {
    console.log('dettagli cliente ', props.valori.CODCF);
    setOpenViewDialog(true);
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    //dispatch(setCurrentCustomer(null));
  };

  const handleClosePartiteDialog = () => {
    setOpenPartiteDialog(false);
    //dispatch(setCurrentCustomer(null));
  };

  return (
    <Dialog
      maxWidth={'md'}
      fullWidth={true}
      fullScreen={false}
      onClose={handleClose}
      aria-labelledby="edit-asset-dialog"
      open={open}>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
      <DialogTitle id="simple-dialog-title">{props.valori.RAGSOC}</DialogTitle>
      <div className={classes.editAsset}>
        <Box m={5}>
          <Button variant="contained" color="primary" onClick={() => dettagliCliente()}>
            Dettagli cliente
          </Button>
        </Box>
        <Box m={5}>
          <Button variant="contained" color="primary" onClick={() => ricercaPartite()}>
            Cerca tutte le partite
          </Button>
        </Box>
        <br />
        <div className={classes.root}>
          <Grid container xs={12} className="cont-HeadImpegni">
            <Grid item xs={12} className="zoomInRight animation-delay-4 animated">
              {partite.length > 0 && (
                <TableContainer component={Paper}>
                  <Table className="tabella" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Registrazione</TableCell>
                        <TableCell align="left">Scadenza</TableCell>
                        <TableCell align="left">Anno/Numero</TableCell>
                        <TableCell align="left">Descrizione</TableCell>
                        <TableCell align="left">Aperto</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {partite.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell align="left">{moment(row.data_reg).format('DD/MM/YYYY')}</TableCell>
                          <TableCell align="left">{moment(row.data_sca).format('DD/MM/YYYY')}</TableCell>
                          <TableCell align="left">
                            {row.ANNPAR}/{row.NUMPAR}
                          </TableCell>
                          <TableCell align="left">{row.descr}</TableCell>
                          <TableCell align="left">{row.aperto}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Grid>
          </Grid>
        </div>
        {openViewDialog && <CustomerDetailView open={openViewDialog} onCloseDialog={handleCloseViewDialog} />}
        {openPartiteDialog && <CustomerPartiteView open={openPartiteDialog} onCloseDialog={handleClosePartiteDialog} />}
      </div>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
