import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import axios, { post } from 'axios';
import { MdCloudUpload } from 'react-icons/md';
import Placeholer from './placeholder';

let fileUpload = (file, IDana, fileUploaded, unloaded, setLoading) => {
  const url = 'https://laforgiaws.netkom.it/fileReceiver.php';
  const formData = new FormData();
  formData.append('IDana', IDana);
  formData.append('avatar', file);
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return post(url, formData, config, unloaded).then(
    response => {
      console.log(response);
      //unloaded(response.data.url);
      setLoading(false);
      fileUploaded(response.data.url);
    },
    error => {
      console.log(error);
    },
  );
};

export default function MyDropzone({ IDana, fileUploaded, load, unloaded, name }) {
  const [loading, setLoading] = React.useState(false);

  const onDrop = files => {
    setLoading(true);
    files.forEach(file => {
      fileUpload(file, IDana, fileUploaded, unloaded, setLoading);
    });
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Placeholer />
      {loading && <p style={{ color: 'red' }}>Attendere...</p>}
      {name && <p>{name}</p>}
    </div>
  );
}
