const today = new Date();
const currentYear = today.getFullYear();
const loginYear = 2021;

export const clientiModule = {
  labelsList: [
    { id: 1, name: 'Banking', slug: 'banking', color: '#FF8C00' },
    { id: 2, name: 'Company', slug: 'company', color: '#00C4B4' },
    { id: 3, name: 'Payments', slug: 'payments', color: '#0F9AF7' },
  ],
  usersList: [
    {
      id: 1457690400,
      name: 'Google',
      profile_pic: 'https://via.placeholder.com/150',
      email: 'alex-dolgove@example.com',
      emailUsage: 1.11, // GB
      phones: [{ phone: '(215)-659-7529', label: 'Home' }],
      designation: 'CEO',
      starred: false,
      company: 'TCS',
      labels: [2],
      status: 'active',
      suspendedBy: 'Admin',
      suspendedAt: new Date(),
      lastLoginAt: new Date(loginYear, 1, 1),
      createdAt: new Date(currentYear, 1, 1),
      updatedAt: new Date(currentYear, 1, 1),
    },
    {
      id: 1457690401,
      name: 'Amazon',
      profile_pic: 'https://via.placeholder.com/150',
      email: 'chelsea-johnss@example.com',
      emailUsage: 1.6, // GB
      phones: [{ phone: '(215)-659-7529', label: 'home' }],
      designation: 'CFO',
      starred: false,
      company: 'Infosys',
      labels: [2, 1],
      status: 'suspended',
      suspendedBy: 'Admin',
      suspendedAt: new Date(currentYear, 1, 1),
      lastLoginAt: new Date(loginYear, 2, 17),
      createdAt: new Date(currentYear, 1, 1),
      updatedAt: new Date(currentYear, 1, 1),
    },
    {
      id: 1457690402,
      name: 'Microsoft',
      profile_pic: 'https://via.placeholder.com/150',
      email: 'domnic-brown@example.com',
      emailUsage: 1.8, // GB
      phones: [{ phone: '(215)-748-7855', label: 'home' }],
      designation: 'Designer',
      starred: false,
      company: 'Accenture',
      labels: [3],
      status: 'active',
      suspendedBy: 'Admin',
      suspendedAt: new Date(),
      lastLoginAt: new Date(loginYear, 6, 6),
      createdAt: new Date(currentYear, 1, 1),
      updatedAt: new Date(currentYear, 1, 1),
    },
    {
      id: 1457690403,
      name: 'Facebook',
      profile_pic: 'https://via.placeholder.com/150',
      email: 'domnic-brown@example.com',
      emailUsage: 1.7, // GB
      phones: [{ phone: '(215)-659-7529', label: 'home' }],
      designation: 'PHP Developer',
      starred: false,
      company: 'Pizza Hut',
      labels: [],
      status: 'active',
      suspendedBy: 'Admin',
      suspendedAt: new Date(),
      lastLoginAt: new Date(loginYear, 5, 20),
      createdAt: new Date(currentYear, 1, 1),
      updatedAt: new Date(currentYear, 1, 1),
    },
  ],
};
