import React, { forwardRef, useEffect } from 'react';
import MaterialTable, { MTableBody, MTableEditField } from 'material-table';
import { TableCell, TableFooter, TableRow } from '@material-ui/core';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import axios from 'axios';
import { TextField } from '@material-ui/core';
import { Button, Box } from '@material-ui/core';
import MyDropzone from './myDropZone';
import { Icon } from '@material-ui/core';
import { MdPictureAsPdf, MdOutlineImage, MdInsertDriveFile, MdAttachFile } from 'react-icons/md';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default props => {
  const [state, setState] = React.useState({
    columns: [],
    instituti: props.instituti,
  });

  useEffect(() => {
    console.log('arrivano le props', props);
    if (props.instituti) {
      setState({
        isLoading: false,
        columns: [
          { title: 'ID', field: 'id', width: '20%', editable: 'never' },
          {
            title: 'Nome',
            field: 'nome',
            width: '80%',
            validate: rowData => (!rowData.nome ? 'Il nome è obbligatorio' : ''),
          },
        ],
        data: props.instituti,
      });
    }
  }, [props]);

  const addInstituto = nome => {
    try {
      let json = fetch('https://laforgiaws.netkom.it/ws/addInstituto.php', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nome: nome,
        }),
      })
        .then(response => response.json())
        .then(risp => {
          console.log('ecco la risposta add', risp);
          if (risp.success) {
            setState(prevState => {
              const data = risp.instituti;
              return { ...prevState, data };
            });
          } else {
            alert(risp.message);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  const editInstituto = dato => {
    try {
      let json = fetch('https://laforgiaws.netkom.it/ws/editInstituto.php', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: dato.id,
          nome: dato.nome,
        }),
      })
        .then(response => response.json())
        .then(risp => {
          console.log('ecco la risposta edit', risp);
          if (risp.success) {
            setState(prevState => {
              const data = risp.instituti;
              return { ...prevState, data };
            });
          } else {
            alert(risp.message);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  const deleteInstituto = dato => {
    try {
      let json = fetch('https://laforgiaws.netkom.it/ws/deleteInstituto.php', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: dato.id,
          nome: dato.nome,
        }),
      })
        .then(response => response.json())
        .then(risp => {
          console.log('ecco la risposta delete', risp);
          if (risp.success) {
            setState(prevState => {
              const data = risp.instituti;
              return { ...prevState, data };
            });
          } else {
            alert(risp.message);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <MaterialTable
        isLoading={state.isLoading}
        icons={tableIcons}
        title="Instituti"
        columns={state.columns}
        data={state.data}
        options={{
          draggable: false,
          paging: true,
          pageSize: 10, // make initial page size
          exportButton: {
            csv: true,
            pdf: true,
          },
          tableLayout: 'fixed',
          exportPdf: (columns, data) => {
            const doc = new jsPDF();
            const columnTitles = columns.map(columnDef => columnDef.title);
            const pdfData = data.map(rowData => columns.map(columnDef => rowData[columnDef.field]));
            doc.autoTable({
              head: [columnTitles],
              body: pdfData,
              margin: { horizontal: 5, top: 20 },
              //startY: 0.1 * doc.internal.pageSize.height,
              styles: { overflow: 'linebreak' },
              columnStyles: {
                0: { columnWidth: 150 },
                1: { columnWidth: 50 },
              },
            });

            doc.save(`instituti.pdf`);
          },
        }}
        editable={{
          onRowAdd: newData =>
            new Promise(resolve => {
              console.log('newData', newData);
              /*
              setState(prevState => {
                // setState is async  so you need to wait for it to finish
                const data = [...prevState.data];
                data.push(newData);
                return { ...prevState, data };
              });
              //console.log({newData});
              */
              addInstituto(newData.nome);
              resolve(true);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              /*
              setState(prevState => {
                const data = [...prevState.data];
                data[data.indexOf(oldData)] = newData;
                return { ...prevState, data };
              });
              */
              editInstituto(newData);
              resolve(true);
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              console.log('dentro onrowdelete', oldData);
              /*
              setState(prevState => {
                const data = [...prevState.data];
                data.splice(data.indexOf(oldData), 1);
                return { ...prevState, data };
              });
              */
              deleteInstituto(oldData);
              resolve();
            }),
        }}
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to} of {count}',
            labelRowsSelect: 'Allegati',
          },
          toolbar: {
            searchPlaceholder: 'Filtra i instituti',
            nRowsSelected: '{0} row(s) selected',
          },
          header: {
            actions: 'Azioni',
          },
          body: {
            editRow: {
              deleteText: 'Sei sicuro di voler eliminare questo istituto di vigilanza?',
            },
            emptyDataSourceMessage: 'Non ci sono istituti di vigilanza da visualizzare',
            filterRow: {
              filterTooltip: 'Filtro',
            },
          },
        }}
      />
    </div>
  );
};

const renderDocIcon = (doc: string) => {
  if (!doc) {
    return <MdAttachFile size={25} />;
  }
  if (doc.includes('.pdf')) {
    return <MdPictureAsPdf size={25} />;
  } else if (doc.includes('.doc') || doc.includes('.docx')) {
    return <MdAttachFile size={25} />;
  } else if (doc.includes('.xls') || doc.includes('.xlsx') || doc.includes('.csv')) {
    return <MdInsertDriveFile size={25} />;
  } else if (doc.includes('.ppt') || doc.includes('.pptx')) {
    return <MdAttachFile size={25} />;
  } else if (doc.includes('.jpg') || doc.includes('.jpeg') || doc.includes('.png')) {
    return <MdOutlineImage size={25} />;
  } else if (doc.includes('.zip') || doc.includes('.rar')) {
    return <MdAttachFile size={25} />;
  } else if (doc.includes('.txt')) {
    return <MdAttachFile size={25} />;
  } else {
    return <MdAttachFile size={25} />;
  }
};
