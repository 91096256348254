import React from 'react';
import PropTypes from 'prop-types';
import { MdCloudUpload } from 'react-icons/md';

const Placeholder = ({ error, touched }) => (
  <div className={`placeholder-preview ${error && touched ? 'has-error' : ''}`}>
    <MdCloudUpload style={{ fontSize: 80 }} />
  </div>
);

Placeholder.propTypes = {
  error: PropTypes.string,
  touched: PropTypes.bool,
};

export default Placeholder;
