import {
  ADD_CUSTOMER,
  DELETE_BULK_CUSTOMERS,
  DELETE_CUSTOMER,
  EDIT_CUSTOMER,
  GET_CUSTOMERS,
  SET_CUSTOMER_DETAILS,
  SET_CUSTOMER_PARTITE,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  customers: [],
  currentCustomer: null,
  currentPartite: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CUSTOMERS: {
      return {
        ...state,
        customers: action.payload,
      };
    }
    case SET_CUSTOMER_PARTITE: {
      return {
        ...state,
        currentPartite: action.payload,
      };
    }
    case SET_CUSTOMER_DETAILS: {
      return {
        ...state,
        currentCustomer: action.payload,
      };
    }
    case ADD_CUSTOMER: {
      console.log('ADD_CUSTOMER', action.payload);
      return {
        ...state,
        customers: [action.payload, ...state.customers],
      };
    }
    case EDIT_CUSTOMER: {
      console.log('EDIT_CUSTOMER', action.payload);
      return {
        ...state,
        customers: state.customers.map(customer => (customer.id === action.payload.id ? action.payload : customer)),
      };
    }
    case DELETE_CUSTOMER: {
      return {
        ...state,
        customers: state.customers.filter(customer => customer.id !== action.payload),
      };
    }
    case DELETE_BULK_CUSTOMERS: {
      return {
        ...state,
        customers: state.customers.filter(customer => !action.payload.includes(customer.id)),
      };
    }
    default:
      return state;
  }
};
